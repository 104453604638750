import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config';
import AppointmentForm from './doctorappointment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';

const ConsultantForm = () => {
  const getCurrentDay = () => {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const today = new Date();
    const dayIndex = today.getDay();
    return daysOfWeek[dayIndex];
  };
  const navigate = useNavigate();

  const [uhid, setUHID] = useState('');
  const [patientName, setPatientName] = useState('');
  const [confirmed, setConfirmed] = useState('yes');
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [timingCount, setTimingCount] = useState({});
  const [allTimings, setAllTimings] = useState([]);
  const [selectedMorningTiming, setSelectedMorningTiming] = useState('');
  const [consultationFees, setConsultationFees] = useState(0);
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [selectedDay, setSelectedDay] = useState(getCurrentDay());
  const [selectedAllTiming, setSelectedAllTiming] = useState('');
  const [errors, setErrors] = useState({ selectedDoctor: false });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAppointmentForm, setShowAppointmentForm] = useState(false);
  const [isBlur, setIsBlur] = useState(false);
  const [showConsultationForm, setShowConsultationForm] = useState(false);
  const [areDoctorsAvailable, setAreDoctorsAvailable] = useState(true);
  const [uhidTimer, setUHIDTimer] = useState(null);
  const [patientAlert, setPatientAlert] = useState(null);

  const handleConfirmationChange = (event) => {
    const newConfirmation = event.target.value;
    setConfirmed(newConfirmation);

    // If confirmation is 'no', clear the fields
    if (newConfirmation === 'no') {
      clearFormFields();
    }
  };

  useEffect(() => {
    axios.get(`${config.apiUrl}/consultation/departments`)
      .then(response => setDepartments(response.data))
      .catch(error => console.error('Error fetching departments:', error));
  }, []);
  useEffect(() => {
    if (confirmed === 'yes' && selectedDepartment && selectedDay) {
      fetchAvailableDoctors();
      console.log("values", selectedDoctor);
    }

    if (confirmed === 'yes' && selectedDoctor) {
      fetchConsultationFees(selectedDoctor);

    }
  }, [confirmed, selectedDepartment, selectedDay, selectedDoctor]);

  const handleDepartmentChange = async (event) => {
    const selectedDepartment = event.target.value;
    setSelectedDepartment(selectedDepartment);
    setSelectedDoctor(''); // Reset selected doctor when department changes
    setErrors({ selectedDoctor: false });
    setSelectedAllTiming('');
    setConsultationFees(0);

  };

  const fetchAvailableDoctors = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/consultation/availableDoctorsAndTimings/${selectedDepartment}/${selectedDay}`
      );
      const { doctors } = response.data;
      setDoctors(doctors);
      setAreDoctorsAvailable(doctors.length > 0);
    } catch (error) {
      console.error('Error fetching available doctors:', error);
      setAreDoctorsAvailable(false);
    }
  };





  const fetchConsultationFees = async (name) => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/consultation/doctorsAndFees/${selectedDepartment}/${name}`
      );

      const doctorData = response.data.data.doctor;
      console.log("sssss", doctorData)

      if (doctorData === null) {
        // Handle the case where no doctor is found
        setConsultationFees(null); // or setConsultationFees([]);
      } else {
        setConsultationFees(doctorData.consultant_fees);
      }

      console.log("res", response);
    } catch (error) {
      console.error('Error fetching consultation fees:', error);
    }
  };



  const fetchAvailableTimings = async (department, day, doctorName) => {
    try {


      // Fetch available doctors and timings
      const responseAvailableDoctors = await axios.get(
        `${config.apiUrl}/consultation/availableDoctorsAndTimings/${department}/${day}`
      );

      const { doctors } = responseAvailableDoctors.data;
      const selectedDoctorData = doctors.find((doctor) => doctor.doctor_name === doctorName);

      if (selectedDoctorData) {
        const { morning_timings, evening_timings } = selectedDoctorData;
        const splitallTimings = [...morning_timings, ...evening_timings];
        const joinedtimings = splitallTimings.map((timing) => {
          return `${timing.start} - ${timing.end}`;
        });

        // Fetch timing count from another backend API
        const responseTimingCount = await axios.get(
          `${config.apiUrl}/consultation/timing-count?department_name=${department}&consultation_doctor=${doctorName}&date=${currentDate}`
        );

        const timingCountData = responseTimingCount.data;
        console.log(timingCountData);

        const filteredTimings = joinedtimings.filter((timing) => {
          const matchingTimingCount = timingCountData.find(
            (data) => data.time === timing
          );

          return matchingTimingCount ? matchingTimingCount.timing_count < 3 : true;
        });

        setAllTimings(filteredTimings);
        setSelectedMorningTiming(filteredTimings[0]);
      } else {
        setAllTimings([]);
      }
    } catch (error) {
      console.error('Error fetching available timings:', error);
    } finally {

    }
  };
  const handleDoctorChange = async (event) => {
    const selectedDoctorName = event.target.value;
    setSelectedDoctor(selectedDoctorName);
    console.log(selectedDoctorName, "selectedDoctorName")

    if (
      confirmed === 'yes' &&
      selectedDepartment &&
      selectedDay &&
      selectedDoctorName
    ) {
      fetchAvailableTimings(
        selectedDepartment,
        selectedDay,
        selectedDoctorName
      );
    }
    // fetchConsultationFees();

  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const day = now.getDate().toString().padStart(2, '0');
      const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
      const year = now.getFullYear();
      const formattedDate = `${year}/${month}/${day}`;
      setCurrentDate(formattedDate);
    }, 1000)

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Initialize timing count when the selected doctor changes
    setTimingCount({});
  }, [selectedDoctor, selectedDepartment]);


  const handleTimingChange = (e) => {
    const selectedAllTiming = e.target.value;
    setSelectedAllTiming(selectedAllTiming);


  };




  useEffect(() => {
    // Initialize timing count when the component mounts
    setTimingCount({});
  }, []);

  const clearFormFields = () => {
    setConfirmed(null);
    setSelectedDepartment('');
    setDoctors([]);
    setSelectedDoctor('');
    setConsultationFees(0);
    setUHID('');
    setPatientName('');
    setSelectedDay(getCurrentDay());
    setSelectedAllTiming('');
    setErrors({});


  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    const newErrors = {};
    if (!uhid) {
      newErrors.uhid = true;
    }

    if (!selectedDepartment) {
      newErrors.selectedDepartment = true;
    }
    if (!selectedDoctor) {
      newErrors.selectedDoctor = true;
    }
    if (!selectedAllTiming) {
      newErrors.selectedAllTiming = true;
    }
    // Add similar checks for other input fields
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);

      setTimeout(() => {
        setErrors({});
      }, 1000);

      setIsSubmitting(false);

      return;
    }

    try {
      const payload = {
        uhid,
        patient_name: patientName,
        department_name: selectedDepartment,
        consultation_doctor: selectedDoctor,
        time: selectedAllTiming,
        date: currentDate,
        consultation_fees: consultationFees,

      };


      // Make a POST request to the backend API
      const response = await axios.post(`${config.apiUrl}/consultation/addConsultation`, payload);

      console.log('Consultation data added successfully:', response.data);
      // Optionally, you can show a success message or redirect to another page

      setBookingSuccess(true);
      // Hide the success message after 1ms
      setTimeout(() => {
        setBookingSuccess(false);
      }, 1000);

      clearFormFields();
    } catch (error) {
      // console.error('Error adding consultation data:', error);

      // Check if the error contains a specific message
      if (error.response && error.response.data && error.response.data.error) {
        // Show the specific error message in an alert
        alert(error.response.data.error);
      } else {
        // If no specific message, show a generic error message
        alert('An error occurred while adding consultation data.');
      }
    } finally {
      // Re-enable the submit button in the 'finally' block
      setIsSubmitting(false);
    }
  };

  const handleUHIDChange = (e) => {
    const newUHID = e.target.value.toUpperCase();
    setUHID(newUHID);
    console.log(newUHID);

    // Clear any existing timer
    if (uhidTimer) {
      clearTimeout(uhidTimer);
    }
    // const isValidFormat = /^[A-Z]{2}\d{3}$/.test(newUHID);
    // Set a new timer to make the API call after a delay (e.g., 500 milliseconds)
    setUHIDTimer(setTimeout(() => fetchPatientData(newUHID), 2000));

    // setErrors({ uhid: !isValidFormat });
  };

  const fetchPatientData = async (uhid) => {
    try {
      const response = await fetch(`${config.apiUrl}/consultation/patient/searchname?uhid=${uhid}`);
      const data = await response.json();

      if (response.ok) {
        setPatientName(data.patient_name);
      } else {
        setPatientName('');
        setPatientAlert(
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
            <div className="bg-red-500 text-white p-4 rounded-md">
              Patient not available
            </div>
          </div>
        );

        // Clear the alert after a certain duration (e.g., 5000 milliseconds)
        setTimeout(() => {
          setPatientAlert(null);
        }, 1000);
      }

    } catch (error) {
      console.error('Error fetching patient data:', error);
      // Handle the error as needed
    }
  };

  const handleAppointmentButtonClick = () => {
    setShowAppointmentForm(!showAppointmentForm);
    setShowConsultationForm(false);

  };
  useEffect(() => {
    // Update the blur effect based on the appointment form visibility
    setIsBlur(showAppointmentForm);
    if (showAppointmentForm) {
      window.scrollTo(0, 0);
    }
  }, [showAppointmentForm]);

  const handleCrossButtonClick = () => {
    setShowAppointmentForm(false);
    setShowConsultationForm(true);
  };


  return (
    <div >

      <div className="flex items-center justify-between w-full">
        {/* Button on the left */}
        <button
          className="flex items-center gap-2 p-2 bg-blue-800 text-white rounded hover:bg-blue-600"
          onClick={() => navigate('/front-office')}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>

        {/* Heading centered */}
        <h1 className="text-2xl font-bold text-green-400 flex-grow text-center">
          Patient Consultation Form
        </h1>
      </div>

      <div className={`container mx-auto mt-2 p-4 border border-gray-800 bg-white rounded-md w-4/5 h-full shadow-white`} style={{ filter: isBlur ? 'blur(10px)' : 'none' }}>
        <div className="flex items-center justify-end m-1 p-2 text-black">
          {getCurrentDay() === selectedDay}
          {currentDate && <p className="mr-2"> 📅 {getCurrentDay()}</p>}
          {currentDate && <p>{currentDate}</p>}
        </div>
        {/* {patientAlert} */}
        {patientAlert && (
          <div className="fixed top-5 left-1/2 transform -translate-x-1/2 bg-red-100 text-red-800 px-6 py-3 rounded-lg shadow-md z-50 max-w-xl w-full text-center">
            {patientAlert}
          </div>
        )}


        <div className="flex space-y-0.3 space-x-10 mt-0.3">
          <div className="flex-shrink-0 w-2/5">
            <label className="block text-sm font-semibold text-gray-700 mx-14 ">UHID:</label>
            <input
              type="text"
              value={uhid}
              onChange={handleUHIDChange}
              className={`border p-2 w-full rounded-md focus:outline-none ml-12 transition duration-100 ${errors.uhid ? 'border-red-500' : 'border-gray-300'}`}
            />
            {/* {errors.uhid && <p className="text-red-500">Invalid UHID format</p>} */}
          </div>
          <div className="flex-shrink-0 w-2/5">
            <label className="block text-sm font-semibold text-gray-700 mx-20">PATIENT NAME:</label>
            <input
              type="text"
              value={patientName}
              readOnly
              // onChange={(e) => setPatientName(e.target.value)}
              className={`border p-2 w-full rounded-md focus:outline-none ml-16`}
            />

          </div>
        </div>

        <div className="p-2 mt-5 ">
          <p className="mb-4 font-semibold text-lg ml-11">Are you sure you want to consult a doctor?</p>
          <div className="flex items-center mb-4 ml-11">
            <label className="flex items-center mr-4 font-medium text-base">
              <input
                type="radio"
                name="confirmation"
                value="yes"
                checked={confirmed === 'yes'}
                onChange={handleConfirmationChange}
                className="mr-2 text-green-500"
              />
              <span>Yes</span>
            </label>

            <label className="flex items-center font-medium text-base">
              <input
                type="radio"
                name="confirmation"
                value="no"
                checked={confirmed === 'no'}


                onChange={handleConfirmationChange}
                className="mr-2 text-red-500"
              />
              <span>No</span>
            </label>

          </div>
          <br />


          {confirmed === 'yes' && (
            <div>
              <div className="flex space-x-4 ml-6">
                <div className="flex-shrink-0 w-1/3">
                  <label className="block text-lg font-semibold text-gray-700 mb-1">Select Department:</label>
                  <select
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    className={`border p-2 w-48 rounded-md focus:outline-none transition duration-100 ${errors.selectedDepartment ? 'border-red-500' : 'border-gray-300'}`}
                  >
                    <option value="">Select Department</option>
                    {departments.map((department) => (
                      <option key={department.name} value={department.name}>
                        {department.name}
                      </option>
                    ))}
                  </select>

                </div>

                <div className="flex-shrink-0 w-1/3">
                  <label className="block text-lg font-semibold bg-white text-gray-700 mb-1">Select Doctor:</label>

                  {areDoctorsAvailable ? (
                    <select
                      value={selectedDoctor}
                      onChange={handleDoctorChange}
                      className={`border p-2 w-48 rounded-md focus:outline-none transition duration-100 ${errors.selectedDoctor ? 'border-red-500' : 'border-black-300'
                        }`}
                    >
                      <option value="">Select Doctor</option>
                      {doctors.map((doctor) => (
                        <option key={doctor.doctor_id} value={doctor.doctor_name}>
                          {doctor.doctor_name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <div className="border p-2 w-48 rounded-md focus:outline-none transition duration-100 bg-gray-200">
                      No doctors available
                    </div>
                  )}
                </div>

                <div className="flex-shrink-0 w-1/3">
                  <label className="block text-lg font-semibold text-gray-700 mb-1">Select Timing:</label>
                  <select
                    value={selectedAllTiming}
                    onChange={handleTimingChange}
                    className={`border p-2 w-48 rounded-md focus:outline-none transition duration-75 ${errors.selectedAllTiming ? 'border-red-500' : 'border-gray-300'}`}
                  >
                    <option value="">Select Timing</option>
                    {allTimings.map((timing, index) => (
                      <option key={index} value={timing}>
                        {timing}
                      </option>
                    ))}
                  </select>

                </div>
              </div>
              <br />

              <label className="block text-lg font-semibold text-gray-700 mb-1 ml-7">Consultation Fees: {consultationFees}</label>

            </div>
          )}

          <br />
          <div className='mb-10'>
            <button className={`bg-${isSubmitting ? 'gray' : 'blue'}-500 text-white text-lg p-1.5 mr-1 rounded float-right`}
              onClick={handleSubmit}
              disabled={isSubmitting}

            >
              Submit
            </button>
            <button
              className="bg-blue-500 text-white text-lg p-1.5 ml-7 rounded float-left"
              onClick={handleAppointmentButtonClick}
            >
              Appointment
            </button>
          </div>
          {bookingSuccess && (
            <div className="fixed top-5 left-1/2 transform -translate-x-1/2 bg-green-400 text-white-800 px-6 py-3 rounded-lg shadow-md z-50 max-w-xl w-full text-center">

              <span className="animate-fadeIn duration-1000">✅Appointment Successsful</span>

            </div>
          )}


        </div>

      </div>
      {showAppointmentForm && (

        <div
          className={`fixed inset-0 overflow-y-auto ${showAppointmentForm ? 'backdrop-filter backdrop-blur-sm' : ''} transition-all duration-300 ${showAppointmentForm ? 'opacity-100' : 'opacity-0 pointer-events-none'
            }`}
        >
          <div className="flex items-center justify-center h-screen">
            <div
              className={`bg-white p-4 w-1/2 md:w-1/2 lg:w-3/4 rounded shadow-lg transition-transform duration-300 transform ${showAppointmentForm ? 'scale-100' : 'scale-0'
                } border-2 border-blue-500 max-h-full overflow-auto`}
              style={{
                scrollbarWidth: 'thin',
                scrollbarColor: 'rgba(0, 0, 0, 0.5) transparent',

              }}
            >
              <AppointmentForm />
              <div
                onClick={handleCrossButtonClick}
                className="fixed top-2 right-2 text-4xl p-1 rounded-xl cursor-pointer hover:text-black-800 focus:outline-none"
              >
                &times;
              </div>

            </div>

          </div>
        </div>

      )}
    </div>
  );

};


export default ConsultantForm;