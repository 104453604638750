import React, { useState, useEffect } from 'react';
import config from '../../config';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSync, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import PrintScreen from './printscreen';

const RegistrationBilling = () => {
  const [patientData, setPatientData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [showPrintScreen, setShowPrintScreen] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState('');
  const [isOnlineSelected, setIsOnlineSelected] = useState(false);
  const [isCashSelected, setIsCashSelected] = useState(false);
  const [gcrNo, setGcrNo] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const paymentGateways = ['GPay', 'PayPal', 'PhonePe'];
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchInitialData();
  }, []);

  const handleCashCheckboxChange = () => {
    setPaymentMethod('Cash');
    fetchGCRNumber('Cash');
    setIsCashSelected(!isCashSelected);
    setIsOnlineSelected(false);
  };

  const handleOnlineCheckboxChange = () => {
    setPaymentMethod('Online');
    fetchGCRNumber('Online');
    setIsOnlineSelected(!isOnlineSelected);
    setIsCashSelected(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchGCRNumber = (paymentType) => {
    fetch(`${config.apiUrl}/bill/get-gcr-number/${paymentType}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setGcrNo(data.gcrNumber);
      })
      .catch((error) => {
        console.error('Error fetching GCR number:', error);
      });
  };

  const fetchInitialData = () => {
    fetch(`${config.apiUrl}/bill/unpaid-patients`)
      .then((response) => {

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setPatientData(data.data);
        setErrorMessage('');
      })
      .catch((error) => {
        console.error('Error fetching initial data:', error);
        setErrorMessage('Error fetching initial data');
      });
  };

  const handleGatewaySelect = (gateway) => {
    setSelectedGateway(gateway);
  };

  const handleViewClick = (patient) => {
    setSelectedPatient(patient);

    openModal()
  };

  const handleResetForm = () => {
    setGcrNo('');
    setIsCashSelected(false);
    setIsOnlineSelected(false);
  }

  const handlePaymentUpdate = async () => {

    if (!isCashSelected && !isOnlineSelected) {
      alert('Please select a payment method.');
      return;
    }
    const dataToSend = {
      patientname: selectedPatient.Title + '' + selectedPatient.first_Name + '' + selectedPatient.last_Name,
      uhid: selectedPatient.uhid,
      Mobile_number: selectedPatient.Mobile_number,
      paymentmethod: paymentMethod,
      gcrno: gcrNo,
    };

    if (selectedPatient.registration_fees && !selectedPatient.consultation_fees) {
      dataToSend.department = 'Registration';
      dataToSend.total = selectedPatient.registration_fees;

      fetch(`${config.apiUrl}/bill/billing-data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setInvoiceNumber(data.invoiceNumber);
        })
        .catch((error) => {
          console.error('Error posting registration fee data:', error);
        });
    }
    else if (selectedPatient.registration_payment_status === 1 && selectedPatient.consultation_fees) {
      dataToSend.department = 'Consultation';
      dataToSend.total = selectedPatient.consultation_fees;

      fetch(`${config.apiUrl}/bill/billing-data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setInvoiceNumber(data.invoiceNumber);
        })
        .catch((error) => {
          console.error('Error posting consultation fee data:', error);
        });
    }
    else if (selectedPatient.registration_payment_status === 0 && selectedPatient.registration_fees && selectedPatient.consultation_fees) {
      const registrationResponse = await fetch(`${config.apiUrl}/bill/billing-data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...dataToSend, department: 'Registration', total: selectedPatient.registration_fees }),
      });

      const consultationResponse = await fetch(`${config.apiUrl}/bill/billing-data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...dataToSend, department: 'Consultation', total: selectedPatient.consultation_fees }),
      });

      const registrationData = await registrationResponse.json();
      const consultationData = await consultationResponse.json();
    }

    if (selectedPatient.consultation_fees) {
      const utcDate = new Date(selectedPatient.date);
      const istDate = utcDate.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
      const dateOnly = istDate.split(',')[0];
      const [day, month, year] = dateOnly.split('/');
      const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      Promise.all([
        fetch(`${config.apiUrl}/bill/update-registration_payment/${selectedPatient.uhid}`, {
          method: 'PUT',
        }),
        fetch(`${config.apiUrl}/bill/update-consultation_payment/${selectedPatient.uhid}/${formattedDate}/${selectedPatient.department_name}/${selectedPatient.consultation_doctor}`, {
          method: 'PUT',
        }),

      ])

        .then((responses) => {
          const isError = responses.some((response) => !response.ok);
          if (isError) {
            throw new Error('Error updating payment status');
          }
          return Promise.all(responses.map((response) => response.json()));
        })
        .then((data) => {
          setPaymentSuccess(true);
          setShowPrintScreen(true);
          setTimeout(() => {
            setPaymentSuccess(false);
          }, 2000);

          if (patientData) {
            const updatedPatientData = patientData.filter(
              (patient) => patient.uhid !== selectedPatient.uhid || selectedPatient.consultpayment_Status === 1
            );
            setPatientData(updatedPatientData);
          }
        })
        .catch((error) => {
          console.error('Error updating payment status:', error);
          setErrorMessage('Error updating payment status');
        });

    } else {
      fetch(`${config.apiUrl}/bill/update-registration_payment/${selectedPatient.uhid}`, {
        method: 'PUT',
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setPaymentSuccess(true);
          setShowPrintScreen(true);

          setTimeout(() => {
            setPaymentSuccess(false);
          }, 1000);

          if (patientData) {
            const updatedPatientData = patientData.filter(
              (patient) => patient.uhid !== selectedPatient.uhid
            );
            setPatientData(updatedPatientData);
          }

          // fetchInitialData();
        })
        .catch((error) => {
          console.error('Error updating payment status:', error);
          setErrorMessage('Error updating payment status');
        });
      handleResetForm();

    }
  };

  return (

    <div className=" p-8 flex flex-col w-100">
      {!selectedPatient && (

        <div className={`flex flex-col ${isModalOpen ? 'filter blur-sm' : ''}`}>
          <div className="flex items-center justify-between w-full pb-4">
            {/* Button on the left */}
            <button
              className="flex items-center gap-2 p-2 bg-blue-800 text-white rounded hover:bg-blue-600"
              onClick={() => navigate('/billing')}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>

            {/* Heading centered */}
            <h1 className="text-2xl font-bold text-green-400 flex-grow text-center">
              Patient Registration & Consultation Bill
            </h1>
          </div>
          <div className="flex items-center">
            <h2 className="text-xl mb-2 mr-2">PATIENT LIST</h2>
            <FontAwesomeIcon
              icon={faSync}
              className="w-6 h-4 text-black-600 cursor-pointer ml-2 mb-2"
              onClick={fetchInitialData}
            />
          </div>

          {paymentSuccess && (
            <div className="fixed bottom-4 right-4 bg-green-500 text-white py-2 px-4 rounded shadow">
              Payment done successfully
            </div>
          )}

          <div className="flex items-center mb-2">
            <FontAwesomeIcon
              icon={faSearch}
              className="text-black-500 h-4 w-4 mr-2 mt-5"
            />
            <input
              type="text"
              placeholder="Search by UHID or Patient Name"
              className="p-2 border rounded w-2/5 mt-5"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <table >
            <thead className="text-white">
              <tr>
                <th className="bg-blue-800 px-4 py-2 text-center">S.NO</th>
                <th className="bg-blue-800 px-4 py-2 text-center">Date</th>

                <th className="bg-blue-800 px-4 py-2 text-center">UHID</th>
                <th className="bg-blue-800 px-4 py-2 text-center">Patient Name</th>
                <th className="bg-blue-800 px-4 py-2 text-center">Registration Fees</th>
                <th className="bg-blue-800 px-4 py-2 text-center">Consultation Fees</th>
                <th className="bg-blue-800 px-4 py-2 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {patientData &&
                patientData
                  .filter((patient) => {
                    const searchTerm = searchQuery.toLowerCase();
                    return (
                      patient.uhid.toLowerCase().includes(searchTerm) ||
                      patient.first_Name.toLowerCase().includes(searchTerm)
                    );
                  })
                  .map((patient, index) => (

                    <tr key={patient.uhid}>
                      <td className=" px-4 py-2 text-center">{index + 1}</td>
                      <td className="px-4 py-2 text-center">
                        {new Date(patient.created_at).toLocaleString()}
                      </td>
                      <td className=" px-4 py-2 text-center">{patient.uhid}</td>
                      <td className=" px-4 py-2 text-center">{`${patient.Title} ${patient.first_Name} ${patient.last_Name}`}</td>
                      <td className=" px-4 py-2 text-center">
                        {patient.registration_payment_status === 1 ? 'PAID' : patient.registration_fees}
                      </td>
                      <td className=" px-4 py-2 text-center">{patient.consultation_fees || 0}</td>
                      <td className=" px-4 py-2 text-center">
                        <button disabled={selectedPatient !== null} onClick={() => handleViewClick(patient)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      )}
      {selectedPatient && !showPrintScreen && (
        <div
          className={`fixed inset-0 ${isModalOpen ? 'backdrop-filter backdrop-blur-sm' : ''
            } overflow-y-auto transition-all duration-300 ${isModalOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
        >
          <div className="flex items-center justify-center h-screen">
            <div className={`bg-white p-4 w-1/2 md:w-1/2 lg:w-3/4 rounded shadow-lg transition-transform duration-300 transform ${isModalOpen ? 'scale-100' : 'scale-0'} border-2 border-blue-500`}>
              <div className=" p-4 flex flex-col">

                <div className="flex justify-end items-center">
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => {
                        closeModal();
                        setSelectedPatient(null);
                      }}
                      className="text-red-500 cursor-pointer"
                      size="lg"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <h2 className="font-bold text-xl mb-2">Patient Details</h2>

                    <div className="mb-2">
                      <label className="block text-sm font-medium text-gray-700">Patient Name</label>
                      <input type="text" placeholder="Patient name" className="mt-1 p-2 border rounded w-full" value={selectedPatient.Title + ' ' + selectedPatient.first_Name + ' ' + selectedPatient.last_Name || ''} readOnly />
                    </div>

                    <div className="mb-2">
                      <label className="block text-sm font-medium text-gray-700">Registration Fees</label>
                      <input
                        type="text" placeholder="Registration fees" className="mt-1 p-2 border rounded w-full"
                        value={
                          selectedPatient.registration_payment_status === 1
                            ? 'PAID'
                            : selectedPatient.registration_fees || ''
                        }
                        readOnly
                      />
                    </div>

                    {selectedPatient.consultation_fees && (
                      <>
                        <div className="mb-2">
                          <label className="block text-sm font-medium text-gray-700">Consultation Fees</label>
                          <input type="text" placeholder="Consultation fees" className="mt-1 p-2 border rounded w-full" value={selectedPatient.consultation_fees || ''} readOnly />
                        </div>

                        <div className="mb-2">
                          <label className="block text-sm font-medium text-gray-700">Total</label>
                          <input
                            type="text" placeholder="Total" className="mt-1 p-2 border rounded w-full"
                            value={
                              selectedPatient.registration_payment_status === 1
                                ? selectedPatient.consultation_fees
                                : selectedPatient.consultation_fees + selectedPatient.registration_fees || ''
                            }
                            readOnly
                          />
                        </div>
                      </>
                    )}
                  </div>

                  <div>

                    <div className="mb-2 mt-8">
                      <label className="block text-sm font-medium text-gray-700">Date</label>
                      <input
                        type="text"
                        placeholder="Date"
                        className="mt-1 p-2 border rounded w-full"
                        value={(() => {
                          const utcDate = new Date(selectedPatient.date || selectedPatient.registration_date);
                          const istDate = utcDate.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
                          const dateOnly = istDate.split(',')[0];
                          const [day, month, year] = dateOnly.split('/');
                          const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
                          return formattedDate;
                        })()}
                        readOnly
                      />
                    </div>

                    <div className="mb-2">
                      <label className="block text-sm font-medium text-gray-700">UHID</label>
                      <input type="text" placeholder="UHID" className="mt-1 p-2 border rounded w-full" value={selectedPatient.uhid || ''} readOnly />
                    </div>

                    <div className="mb-2">
                      <label className="block text-sm font-medium text-gray-700">Age</label>
                      <input type="text" placeholder="Age" className="mt-1 p-2 border rounded w-full" value={selectedPatient.age || ''} readOnly />
                    </div>

                    <div className="mb-2">
                      <label className="block text-sm font-medium text-gray-700">Mobile Number</label>
                      <input type="text" placeholder="Mobile Number" className="mt-1 p-2 border rounded w-full" value={selectedPatient.Mobile_number || ''} readOnly />
                    </div>

                  </div>
                </div>

                <div className="mb-6 grid grid-cols-2 gap-4">
                  <div>
                    <h2 className="font-bold text-xl mb-2">Consultation Doctor</h2>
                    <p className="text-lg">{selectedPatient.consultation_doctor}</p>
                  </div>
                  <div>
                    <h2 className="font-bold text-xl mb-2">Consultation Time</h2>
                    <p className="text-lg">{selectedPatient.time}</p>
                  </div>
                </div>

                <div className="flex justify-between">
                  <div className="flex items-center">
                    <label className="block text-sm font-medium text-gray-700 mr-2">GCR No</label>
                    <input
                      type="text"
                      placeholder="GCR Number"
                      className={`mt-1 p-2 `}
                      pattern="[0-9]+([.][0-9]+)?"
                      onKeyPress={(e) => {
                        const charCode = e.which ? e.which : e.keyCode;
                        if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
                          e.preventDefault();
                        }
                      }}
                      value={gcrNo}

                    />

                  </div>

                  <label className="flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="form-checkbox h-5 w-5 text-blue-500"
                      checked={isCashSelected}
                      onChange={() => {
                        setIsCashSelected(true);
                        handleCashCheckboxChange();
                      }}
                    />
                    <span className="ml-2">Cash</span>
                  </label>

                  <label className="flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="form-checkbox h-5 w-5 text-blue-500"
                      checked={isOnlineSelected}
                      onChange={() => {
                        setIsOnlineSelected(true);
                        handleOnlineCheckboxChange();
                      }} />
                    <span className="ml-2">Online</span>
                  </label>

                  {isOnlineSelected && (
                    <div className="flex flex-row items-center mt-4">
                      {paymentGateways.map((gateway) => (
                        <div key={gateway} className="flex items-center mr-4">
                          <input
                            type="radio"
                            id={gateway}
                            name="gateway"
                            value={gateway}
                            checked={selectedGateway === gateway}
                            onChange={() => handleGatewaySelect(gateway)}
                          />
                          <label htmlFor={gateway} className="ml-2">
                            {gateway}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                  <div>
                    <button
                      onClick={handlePaymentUpdate}
                      className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-4"
                    >
                      Submit
                    </button>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      )}

      {showPrintScreen && (
        <div
          className={`fixed inset-0 overflow-y-auto ${isModalOpen ? 'backdrop-filter backdrop-blur-sm' : ''} transition-all duration-300 ${isModalOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
            }`}
        >
          <div className="flex items-center justify-center h-screen">
            <div
              className={`bg-white p-4 w-1/2 md:w-1/2 lg:w-3/4 rounded shadow-lg transition-transform duration-300 transform ${isModalOpen ? 'scale-100' : 'scale-0'
                } border-2 border-blue-500 max-h-full overflow-auto`}
              style={{
                scrollbarWidth: 'thin',
                scrollbarColor: 'rgba(0, 0, 0, 0.5) transparent',
              }}
            >
              <PrintScreen
                selectedPatient={selectedPatient}
                onClose={() => {
                  setShowPrintScreen(false);
                  setSelectedPatient(null);
                  closeModal();
                }}
              />
            </div>
          </div>
        </div>
      )}

      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
    </div>
  );
};

export default RegistrationBilling;
