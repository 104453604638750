import React, { useState, useMemo } from 'react';
import Select from 'react-select'; // Import react-select component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import config from "../../config";

function CardiologyForm({ selectedPatient, labTests }) {
 
  const [procedure, setProcedure] = useState(null); // Updated to use React-Select's object
  const [description, setDescription] = useState('');
  const [records, setRecords] = useState([]);
  const [error, setError] = useState(false);
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);

  // Convert labTests into options for the dropdown
  const procedureOptions = useMemo(() => 
    labTests.map((test) => ({
      value: test.testName,
      label: test.testName,
      price: test.price, // Include price in the option
      category: test.categoryCode,
    })), [labTests]);

    const addRecord = () => {
      if (procedure && description) {
        setRecords([...records, { 
          procedure: procedure.label, 
          description, 
          price: procedure.price,
          category: procedure.category // Add price to record
        }]);
        setProcedure(null);
        setDescription('');
        setError(false);
      } else {
        setError(true);
      }
    };

  const removeRecord = (index) => {
    const updatedRecords = [...records];
    updatedRecords.splice(index, 1);
    setRecords(updatedRecords);
  };

  const handleSubmit = async () => {
    if (records.length === 0) {
      setError(true);
      return;
    }

    try {
      const categoryCode = labTests[0]?.categoryCode;
      let apiUrl = '';

      switch (categoryCode) {
        case 'HT':
          apiUrl = `${config.apiUrl}/doctor/Hematologylablist`;
          break;
        case 'CT':
          apiUrl = `${config.apiUrl}/doctor/submitCardiologyTestData`;
          break;
        case 'RI':
          apiUrl = `${config.apiUrl}/doctor/submitRadiologyData`;
          break;
        default:
          console.error('Unknown category code');
          return;
      }

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          records: records.map(record => ({
            ...record,
            price: record.price // Include price in the payload
          })),
          uhid: selectedPatient.uhid,
          date: selectedPatient.date,
          patient_name: selectedPatient.patient_name,
          age: selectedPatient.age,
          doctor_name: selectedPatient.doctor_name,
          department: selectedPatient.department,
          Mobile_number: selectedPatient.Mobile_number,
        }),
      });

      if (response.ok) {
        console.log('Data successfully submitted');
        setShowSuccessPopUp(true);
        setTimeout(() => setShowSuccessPopUp(false), 2500);
        setRecords([]);
      } else {
        console.error('Error submitting data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  return (
    <div className='ml-32'>
      {showSuccessPopUp && (
        <div className="fixed bottom-4 right-4 bg-green-600 text-white py-2 px-4 rounded shadow">
          {labTests[0]?.category} Form submitted ✅
        </div>
      )}

      <div style={{ display: 'flex', alignItems: 'center', marginLeft: '60px' }}>
        <Select
          options={procedureOptions}
          value={procedure}
          onChange={(selectedOption) => setProcedure(selectedOption)}
          placeholder="Select Procedure"
          isClearable 
          styles={{
            control: (base) => ({
              ...base,
              border: error && !procedure ? '2px solid red' : '1px solid #ccc',
              width: '300px',
              marginRight: '20px',
            }),
          }}
        />
        <input
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter Description"
          style={{
            border: error && !description ? '2px solid red' : '1px solid #ccc',
            borderRadius: '4px',
            padding: '8px',
            marginRight: '20px',
            maxWidth: '200px',
          }}
        />
        <button
          onClick={addRecord}
          style={{
            border: 'none',
            borderRadius: '4px',
            padding: '8px 16px',
            backgroundColor: '#007bff',
            color: '#fff',
            cursor: 'pointer',
          }}
        >
          Add
        </button>
      </div>

      <table className="w-3/4 border-collapse border">
        <thead>
          <tr>
            <th className="border p-2 text-center">S.No</th>
            <th className="border p-2 text-center">Procedure</th>
            <th className="border p-2 text-center">Description</th>
            <th className="border p-2 text-center">Action</th>
          </tr>
        </thead>
        <tbody className="border">
          {records.length === 0 ? (
            <tr>
              <td colSpan="4" className="text-center">
                Give Your Suggestion...
              </td>
            </tr>
          ) : (
            records.map((record, index) => (
              <tr key={index}>
                <td className="border p-2 text-center">{index + 1}</td>
                <td>{record.procedure}</td>
                <td style={{ maxWidth: '200px', wordWrap: 'break-word' }}>
                  {record.description}
                </td>
                <td className="border p-2 text-center">
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="text-red-500 cursor-pointer"
                    size="lg"
                    onClick={() => removeRecord(index)}
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      <button
        onClick={handleSubmit}
        style={{
          border: 'none',
          borderRadius: '4px',
          padding: '8px 16px',
          backgroundColor: '#007bff',
          color: '#fff',
          cursor: 'pointer',
          marginTop: '20px',
        }}
      >
        Submit
      </button>
    </div>
  );
}

export default CardiologyForm;
