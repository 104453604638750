import React from 'react';
import { useNavigate } from 'react-router-dom';
import registration from '../../Assets/registration.jpg';
import consultationImage from '../../Assets/staff.jpg';
import admissionImage from '../../Assets/regi.jfif';
import '../../Styles/card.css';
import { Button } from '../HomeComponent/button';

const HRManagement = () => {
  const navigate = useNavigate();
  const [hovered, setHovered] = React.useState(null);

  const handleMouseEnter = (index) => setHovered(index);
  const handleMouseLeave = () => setHovered(null);

  const cardData = [
    {
      title: 'User Registration',
      // content: 'Register new users by providing personal and contact details.',
      path: '/hr/registrationForm',
      image: registration,
    },
    {
      title: 'Staff Registration Form',
      // content: 'Enter staff details, qualifications, and job roles.',
      path: '/hr/staffregister',
      image: consultationImage,
    },
    {
      title: 'Doctor Registration Form',
      // content: 'Register doctors by entering their qualifications and specialties.',
      path: '/hr/doctorregister',
      image: admissionImage,
    },
  ];
  
  

  const handleCardClick = (path) => {
    navigate(path);
  };

  return (
    <div className="container mx-auto px-4">
    {/* Page Title */}
    <div className="flex items-center justify-between w-full pb-4">
        <h1 className="text-2xl font-bold text-green-400 flex-grow text-center">
         HR Management
        </h1>
      </div>
    <div className='flex justify-center items-center '>
      
 <div className="flex flex-wrap gap-6 mt-6 ">
      {cardData.map((card, index) => (
        <div
          key={index}
          className={`relative w-80 h-[380px] bg-base-100 border rounded-lg shadow-xl overflow-hidden cursor-pointer  
          transition-transform duration-200 ${hovered === index ? 'scale-105' : ''}`}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          <figure className="h-2/3">
            <img
              src={card.image}
              alt={card.title}
              className="w-full h-full object-cover"
            />
          </figure>
          <div className="card-body h-1/3 flex flex-col items-center justify-center bg-gray-200">
            <div className="mt-4">
              <Button onClick={() => handleCardClick(card.path)} title={card.title} />
            </div>
            <p className="text-gray-600 p-2">{card.content}</p>

          </div>
        </div>
      ))}
    </div>
    </div>
    </div>
   
  );
};

export default HRManagement;
