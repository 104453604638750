import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, getYear, getMonth } from 'date-fns';

const OpfilterDayBarchart = ({ data, userDepartment }) => {
  console.log('userdepartment', userDepartment);
  const currentDate = new Date();
  const currentYear = getYear(currentDate);
  const currentMonth = getMonth(currentDate) + 1;

  const [selectedYear, setSelectedYear] = useState(currentYear.toString());
  const [selectedMonth, setSelectedMonth] = useState(currentMonth.toString());
  const [selectedDepartment, setSelectedDepartment] = useState(userDepartment !== 'Head Office' ? userDepartment : '');
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [dailyChartData, setDailyChartData] = useState([]);

  // Extract unique years from data
  const uniqueYears = Array.from(new Set(data.map(item => getYear(new Date(item.date)))));
  // Extract unique departments from data
  const uniqueDepartments = Array.from(new Set(data.map(item => item.department)));

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
    // Reset selected doctor when department changes
    setSelectedDoctor('');
  };

  const handleDoctorChange = (event) => {
    setSelectedDoctor(event.target.value);
  };

  useEffect(() => {
    // Filter data when selectedYear, selectedMonth, selectedDepartment, or selectedDoctor changes
    if (selectedYear && selectedMonth) {
      let filteredData = data.filter(item => {
        const itemDate = new Date(item.date);
        const selectedStartDate = startOfMonth(new Date(selectedYear, selectedMonth - 1));
        const selectedEndDate = endOfMonth(new Date(selectedYear, selectedMonth - 1));
        return itemDate >= selectedStartDate && itemDate <= selectedEndDate;
      });

      // Apply department filter if selectedDepartment is not empty
      if (selectedDepartment) {
        filteredData = filteredData.filter(item => item.department === selectedDepartment);
      }

      // Apply doctor filter if selectedDoctor is not empty
      if (selectedDoctor) {
        filteredData = filteredData.filter(item => item.doctor_name === selectedDoctor);
      }

      // Calculate counts for each day of the selected month
      const labels = eachDayOfInterval({ start: startOfMonth(new Date(selectedYear, selectedMonth - 1)), end: endOfMonth(new Date(selectedYear, selectedMonth - 1)) });
      const counts = labels.map(date => filteredData.filter(item => new Date(item.date).getDate() === date.getDate()).length);

      // Prepare data for the bar chart
      const dailyChartData = labels.map((date, index) => ({
        name: format(date, 'dd/MM/yyyy'),
        count: counts[index],
      }));

      setDailyChartData(dailyChartData);
    }
  }, [selectedYear, selectedMonth, selectedDepartment, selectedDoctor, data]);

  // Filter doctor names based on the selected department and remove duplicates
  const filteredDoctors = selectedDepartment
    ? Array.from(new Set(data.filter(item => item.department === selectedDepartment).map(item => item.doctor_name)))
    : [];

  return (
    <div className="container mx-auto">
      <div className="flex justify-start space-x-4">
        <div>
          <label htmlFor="year" className="mr-2">Year:</label>
          <select id="year" value={selectedYear} onChange={handleYearChange} className="p-2 border border-gray-300 rounded">
            <option value="">--Select Year--</option>
            {uniqueYears.map(year => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="month" className="mr-2">Month:</label>
          <select id="month" value={selectedMonth} onChange={handleMonthChange} className="p-2 border border-gray-300 rounded">
            <option value="">--Select Month--</option>
            {[...Array(12).keys()].map(month => (
              <option key={month + 1} value={month + 1}>{format(new Date(2000, month), 'MMMM')}</option>
            ))}
          </select>
        </div>
        {userDepartment !== 'Head Office' ? (
          <div>
            <label htmlFor="department" className="mr-2">Department:</label>
            <select id="department" value={selectedDepartment} onChange={handleDepartmentChange} className="p-2 border border-gray-300 rounded">
              <option value={userDepartment}>{userDepartment}</option>
            </select>
          </div>
        ) : (
          <div>
            <label htmlFor="department" className="mr-2">Department:</label>
            <select id="department" value={selectedDepartment} onChange={handleDepartmentChange} className="p-2 border border-gray-300 rounded">
              <option value="">--Select Department--</option>
              {uniqueDepartments.map(department => (
                <option key={department} value={department}>{department}</option>
              ))}
            </select>
          </div>
        )}

        {filteredDoctors.length > 0 && (
          <div>
            <label htmlFor="doctor" className="mr-2">Doctor:</label>
            <select id="doctor" value={selectedDoctor} onChange={handleDoctorChange} className="p-2 border border-gray-300 rounded">
              <option value="">--Select Doctor--</option>
              {filteredDoctors.map(doctor => (
                <option key={doctor} value={doctor}>{doctor}</option>
              ))}
            </select>
          </div>
        )}
      </div>

      <div>
        <h3 className="mb-2 text-xl font-semibold">Daily Count</h3>
        <ResponsiveContainer width="100%" height={250}>
          <BarChart data={dailyChartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tickFormatter={(value) => parseInt(value.split('/')[0], 10)} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="count" fill="rgba(245,158,11,0.6)" barSize={25} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default OpfilterDayBarchart;