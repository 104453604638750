import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Header from "./header";

function HomePage({ children }) {
  const navigate = useNavigate();
  const [sidebarWidth, setSidebarWidth] = useState("230px");
  const [theme, setTheme] = useState("light"); // Default to light theme

  const handleToggleSidebar = () => {
    setSidebarWidth((prev) => (prev === "50px" ? "230px" : "50px"));
  };

  const toggleTheme = (selectedTheme) => {
    setTheme(selectedTheme);
  };

  const boxShadowStyle = {
    light: '0px 8px 10px 4px grey',
    dark: '0px 7px 25px 0px black',
  };

  return (
    <div className={`h-screen font-serif ${theme === 'dark' ? 'bg-gray-800 text-black' : 'bg-white text-black'}`}>
      {/* Header */}
      <Header onThemeToggle={toggleTheme} theme={theme} />

      <div className="flex h-[calc(100vh-56px)] p-4">
        {/* Sidebar */}
        <Sidebar
          handleNavigation={navigate}
          style={{ boxShadow: theme === 'light' ? boxShadowStyle.light : boxShadowStyle.dark }}
          width={sidebarWidth}
          setSidebarWidth={setSidebarWidth}
          theme={theme}
        />

        {/* Sidebar Toggle Button */}
        <div className="flex items-center justify-center relative" style={{ width: "5px" }}>
          <div
            className={`flex items-center justify-center cursor-pointer rounded-full shadow-lg p-3 ${theme === 'dark' ? 'bg-gray-800 border border-white ' : 'bg-white border border-gray-800 '}`}
            onClick={handleToggleSidebar}
          >
            <FontAwesomeIcon
              icon={sidebarWidth === "50px" ? faChevronRight : faChevronLeft}
              className={`text-md ${theme === 'dark' ? 'text-white' : 'text-black'}`}
            />
          </div>
        </div>

        {/* Main Content */}
        <div
          style={{ boxShadow: theme === 'light' ? boxShadowStyle.light : boxShadowStyle.dark, flex: 1 }}
          className={`h-full rounded shadow-lg overflow-auto transition-all duration-300 text-gray-500 p-4`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default HomePage;
