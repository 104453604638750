import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const CategoryForm = () => {
  const [category, setCategory] = useState('');
  const [categoryCode, setCategoryCode] = useState('');
  const [categories, setCategories] = useState([]);
  const navigate =useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${config.apiUrl}/lab/categories`, { category, categoryCode });
      alert('Category added successfully!');
      fetchCategories();
      setCategory('');
      setCategoryCode('');
    } catch (error) {
      console.error('Error adding category:', error);
      alert('Failed to add category');
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/lab/categories`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className="container mx-auto px-4 mt-10">
       <div className="flex items-center justify-between w-full pb-4">
        <button
          className="flex items-center gap-2 p-2 bg-blue-800 text-white rounded hover:bg-blue-600"
          onClick={() => navigate('/lab')}
        >
          <FontAwesomeIcon icon={faArrowCircleLeft} />
        </button>

        <h1 className="text-2xl font-bold text-green-400 flex-grow text-center">
        Category Module
        </h1>
      </div>
      <h1 className="text-3xl font-bold text-center text-blue-800 mb-8">Add New Category</h1>

      <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-white p-6 rounded-lg shadow-lg">
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Category</label>
          <input
            type="text"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Category Code</label>
          <input
            type="text"
            value={categoryCode}
            onChange={(e) => setCategoryCode(e.target.value)}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition"
        >
          Add Category
        </button>
      </form>

      <h2 className="text-2xl font-bold text-center text-blue-800 mt-8">Available Categories</h2>
      <table className="min-w-full mt-4 bg-white border rounded-lg shadow-lg">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">S.No</th>
            <th className="py-2 px-4 border-b">Category</th>
            <th className="py-2 px-4 border-b">Category Code</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((item, index) => (
            <tr key={item.id}>
              <td className="py-2 px-4 border-b text-center">{index + 1}</td>
              <td className="py-2 px-4 border-b">{item.category}</td>
              <td className="py-2 px-4 border-b">{item.categoryCode}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CategoryForm;
