import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Typography, Spin, Input, Button, message, Modal } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import config from '../../config';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const { Title } = Typography;

const DoctorSuggestions = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [reason, setReason] = useState('');
  const [currentRecord, setCurrentRecord] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchDoctorSuggestions();
  }, []);

  const fetchDoctorSuggestions = () => {
    setLoading(true);
    axios
      .get(`${config.apiUrl}/doctor/suggestions/not-willing`)
      .then((response) => {
        const { labTests, cardiology, radiology } = response.data;

        const mergedData = [
          ...labTests.map((item) => ({
            ...item,
            category: 'Lab Test',
            timestamp: formatDate(item.timestamp),
            willing: false,
          })),
          ...radiology.map((item) => ({
            ...item,
            category: 'Radiology',
            timestamp: formatDate(item.timestamp),
            willing: false,
          })),
          ...cardiology.map((item) => ({
            ...item,
            category: 'Cardiology',
            timestamp: formatDate(item.timestamp),
            willing: false,
          })),
        ];

        setData(mergedData);
        setFilteredData(mergedData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  };

  const formatDate = (isoDate) => new Date(isoDate).toLocaleString();

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filtered = data.filter((item) =>
      Object.values(item).some((val) =>
        val && val.toString().toLowerCase().includes(value)
      )
    );
    setFilteredData(filtered);
  };

  const handleWillingness = (record, willing) => {
    if (!willing) {
      setCurrentRecord(record);
      setIsModalVisible(true);
    } else {
      updateWillingness(record, true);
    }
  };

  const updateWillingness = (record, willing, reason = '') => {
    axios
      .post(`${config.apiUrl}/doctor/suggestions/willing`, {
        uhid: record.uhid,
        willing,
        category: record.category,
        procedure_name: record.procedure_name,
        timestamp: record.timestamp,
        reason,
      })
      .then(() => {
        message.success(
          `Patient marked as ${willing ? 'Willing' : 'Not Willing'} for the test.`
        );

        const updatedData = data.map((item) =>
          item.uhid === record.uhid &&
          item.procedure_name === record.procedure_name &&
          item.timestamp === record.timestamp
            ? { ...item, willing, reason }
            : item
        );

        setData(updatedData);
        setFilteredData(updatedData.filter((item) => !item.willing));
      })
      .catch((error) => {
        console.error('Error updating willingness:', error);
        message.error('Failed to update willingness status.');
      });
  };

  const handleModalOk = () => {
    if (!reason) {
      message.warning('Please enter a reason.');
      return;
    }

    updateWillingness(currentRecord, false, reason);
    setIsModalVisible(false);
    setReason('');
    setCurrentRecord(null);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setReason('');
    setCurrentRecord(null);
  };

  const columns = [
    { title: 'UHID', dataIndex: 'uhid', key: 'uhid' },
    { title: 'Date', dataIndex: 'timestamp', key: 'timestamp' },
    { title: 'Patient Name', dataIndex: 'patient_name', key: 'patient_name' },
    { title: 'Age', dataIndex: 'age', key: 'age' },
    {
      title: 'Doctor & Department',
      key: 'doctor_info',
      render: (record) => (
        <div>
          <strong>{record.doctor_name}</strong>
          <br />
          <span style={{ color: 'gray' }}>{record.department}</span>
        </div>
      ),
    },
    { title: 'Category', dataIndex: 'category', key: 'category' },
    { title: 'Procedure Name', dataIndex: 'procedure_name', key: 'procedure_name' },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    { title: 'Price', dataIndex: 'price', key: 'price' },
    {
      title: 'Patient Willing for the Test',
      key: 'willing',
      render: (record) => {
        const hasReason = !!record.reason;

        return !hasReason ? (
          <div style={{ display: 'flex', gap: '10px' }}>
            <Button
              type="primary"
              onClick={() => handleWillingness(record, true)}
            >
              Yes
            </Button>
            <Button
              type="default"
              danger
              onClick={() => handleWillingness(record, false)}
            >
              No
            </Button>
          </div>
        ) : (
          <>Patient Not Willing</>
        );
      },
    },
    { title: 'Reason for Cancel', dataIndex: 'reason', key: 'reason' },
  ];

  return (
    <div style={{ padding: '20px' }}>
           <div className="flex items-center justify-between w-full pb-4">
        <button
          className="flex items-center gap-2 p-2 bg-blue-800 text-white rounded hover:bg-blue-600"
          onClick={() => navigate('/nurse-station')}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>

        <h1 className="text-2xl font-bold text-green-400 flex-grow text-center">
          Doctor Suggestions List's
        </h1>
      </div>

      <Input
        placeholder="Search in all fields"
        prefix={<SearchOutlined />}
        value={searchText}
        onChange={handleSearch}
        style={{ marginBottom: 20, width: '300px' }}
        allowClear
      />

      {loading ? (
        <Spin
          tip="Loading..."
          size="large"
          style={{ display: 'block', textAlign: 'center', marginTop: '50px' }}
        />
      ) : (
        <Table
          columns={columns}
          dataSource={filteredData}
          rowKey={(record) => `${record.uhid}-${record.timestamp}`}
          pagination={{ pageSize: 10 }}
          components={{
            header: {
              cell: (props) => (
                <th {...props} className="custom-header">
                  {props.children}
                </th>
              ),
            },
          }}
        />
      )}

      <Modal
        title="Enter Reason"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Input.TextArea
          rows={4}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder="Enter the reason for not willing"
        />
      </Modal>
    </div>
  );
};

export default DoctorSuggestions;
