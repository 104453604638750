import React from 'react';
import { useNavigate } from 'react-router-dom';
import op from '../../Assets/op.jpg';
import ip from '../../Assets/ip.jpg';
import operation from '../../Assets/operationtheater.jpg';
import appointment from '../../Assets/appointment.jpg';

const Button = ({ onClick, title }) => {
  return (
    <button className="animated-button" onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" className="arr-2" viewBox="0 0 24 24">
        <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
      </svg>
      <span className="text">{title}</span>
      <span className="circle"></span>
      <svg xmlns="http://www.w3.org/2000/svg" className="arr-1" viewBox="0 0 24 24">
        <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
      </svg>
    </button>
  );
};

const DoctorModule = () => {
  const navigate = useNavigate();
  const [hovered, setHovered] = React.useState(null);

  const handleMouseEnter = (index) => setHovered(index);
  const handleMouseLeave = () => setHovered(null);

  const cardData = [
    { 
      title: 'Outpatient Services', 
      content: 'Register and manage walk-in patients efficiently.', 
      path: '/doctor/oplist', 
      image: op 
    },
    { 
      title: 'Inpatient Management', 
      content: 'Monitor admitted patients and their treatment plans.', 
      path: '/doctor', 
      image: ip 
    },
    { 
      title: 'Appointments', 
      content: 'View and schedule patient appointments.', 
      path: '/doctor', 
      image: appointment 
    },
    { 
      title: 'Scheduled Operations', 
      content: 'Review today’s surgery schedule and details.', 
      path: '/doctor', 
      image: operation 
    }
  ];

  const handleCardClick = (path) => navigate(path);

  return (

    <div className="container mx-auto px-4">
      {/* Page Title */}
      <h1 className="text-2xl font-bold text-center mt-8 mb-6 text-green-500">Doctor's Module</h1>

    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-10 p-4 ">
      {cardData.map((card, index) => (
        <div
          key={index}
          className={`card bg-gray-200 border rounded-lg overflow-hidden shadow-lg cursor-pointer transition-transform duration-200 ${
            hovered === index ? 'scale-105' : ''
          }`}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          <figure>
            <img
              src={card.image}
              alt={card.title}
              className="w-full h-48 object-cover"
            />
          </figure>
          <div className="card-body text-center p-4">
            <h2 className="card-title font-bold text-xl mb-2">{card.title}</h2>
            <p className="mb-4 text-gray-600">{card.content}</p>
            <div className="card-actions">
              <Button onClick={() => handleCardClick(card.path)} title="View Details" />
            </div>
          </div>
        </div>
      ))}
    </div>
    </div>
  );
};

export default DoctorModule;
