import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFilePdf, faChartBar, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { useNavigate } from 'react-router-dom';

const BillingHistory = () => {
  const [billingData, setBillingData] = useState([]);
  const [datebillingData, setDateBillingData] = useState([]);
  const ITEMS_PER_PAGE = 10;
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDateBillingData, setCurrentDateBillingData] = useState([]);
  const [showStatisticsModal, setShowStatisticsModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const currentDate = new Date().toISOString().split('T')[0];

    fetch(`${config.apiUrl}/bill/billing_history?currentDate=${currentDate}`)
      .then(response => response.json())
      .then(data => {
        setCurrentDateBillingData(data); 
        setBillingData(data);

      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const filteredBillingData = billingData.filter(item => {
    const searchLowerCase = searchQuery.toLowerCase();
    const departmentMatch = selectedDepartment === '' || item.department.toLowerCase() === selectedDepartment.toLowerCase();
    const uhidMatch = item.uhid.toLowerCase().includes(searchLowerCase);
    const invoiceNumberMatch = item.invoice_number.toLowerCase().includes(searchLowerCase);
    const contactNumberMatch = item.Mobile_number.toString().includes(searchLowerCase);
    const dateMatch = (!startDate || new Date(item.currentdate) >= startDate) &&
      (!endDate || new Date(item.currentdate) <= endDate);

    return departmentMatch && (uhidMatch || invoiceNumberMatch || contactNumberMatch) && dateMatch;
  });


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleFilterChange = () => {
    setCurrentPage(1);
  };

  const handleStatisticsClick = () => {
    setShowStatisticsModal(true);
  };

  const handleCloseStatisticsModal = () => {
    setShowStatisticsModal(false);
  };

  const totalPages = Math.ceil(filteredBillingData.length / ITEMS_PER_PAGE);

  const paginatedData = filteredBillingData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  
  const handleButtonClick = async (invoiceNumber) => {
    try {
      const response = await axios.get(`${config.apiUrl}/bill/billing_pdf?invoicenumber=${invoiceNumber}`, {
        responseType: 'blob', 
      });

      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(pdfBlob);
      link.download = 'retrievedpdf.pdf';
      link.click();
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };
  
  const calculateDepartmentCounts = (data) => {
    const registrationCount = data.filter(item => item.department.toLowerCase() === 'registration').length;
    const consultationCount = data.filter(item => item.department.toLowerCase() === 'consultation').length;

    return { registrationCount, consultationCount };
  };

  const [departmentCounts, setDepartmentCounts] = useState({ registrationCount: 0, consultationCount: 0 });

  useEffect(() => {
    const totalCounts = calculateDepartmentCounts(billingData);
    setDepartmentCounts(totalCounts);
  }, [billingData]);

  const chartData = [
    {
      name: 'Registration',
      count: departmentCounts.registrationCount,
    },
    {
      name: 'Consultation',
      count: departmentCounts.consultationCount,
    },
  ];


  return (
    <div className="p-8 flex flex-col  overflow-hidden">
       <div className="flex items-center justify-between w-full pb-4">
        {/* Button on the left */}
        <button
          className="flex items-center gap-2 p-2 bg-blue-800 text-white rounded hover:bg-blue-600"
          onClick={() => navigate('/billing')}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>

        {/* Heading centered */}
        <h1 className="text-2xl font-bold text-green-400 flex-grow text-center">
          Patient Billing History's
        </h1>
      </div>

      <div className="flex items-center">
        <FontAwesomeIcon
          icon={faSearch}
          className="text-black-500 h-4 w-4 mr-2"

        />
        <input
          type="text"
          placeholder="Search by UHID or Invoice or Contact"
          className="p-2 border rounded w-1/5  mr-2"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            handleFilterChange();
          }}

        />

        <div className="ml-20">
          <label className=" text-sm font-medium text-black-700"> Department : </label>
          <input
            type="text"
            value={selectedDepartment}
            onChange={(e) => setSelectedDepartment(e.target.value)}
            list="departmentList"
            className="p-2 border rounded "
          />
          <datalist id="departmentList">
            <option value="Registration" />
            <option value="Consultation" />
          </datalist>
        </div>

        <div className="ml-28 ">
          <label className=" text-sm font-medium text-black-700 "> From : </label>
          {/* <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="From"
            className="p-2 border rounded ml-2 "
          /> */}
        </div>

        <div className="ml-4">
          <label className=" text-sm font-medium text-black-700"> To : </label>
          {/* <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            placeholderText="To"
            className="p-2 border rounded ml-2"
          /> */}
        </div>

        <FontAwesomeIcon
          icon={faChartBar}
          className="cursor-pointer text-black-500 ml-2 mt-5"
          onClick={handleStatisticsClick}

        />

      </div>


      <div className="flex-grow overflow-hidden ">
        <table className="w-full">
          <thead className="bg-gray-200">
            <tr >
              <th className=" px-4 py-2 text-center">S.NO</th>
              <th className=" px-4 py-2 text-center">Invoice Number</th>
              <th className=" px-4 py-2 text-center">Patient Name</th>
              <th className=" px-4 py-2 text-center">UHID</th>
              <th className=" px-4 py-2 text-center">Contact Number</th>
              <th className=" px-4 py-2 text-center">Department</th>
              <th className=" px-4 py-2 text-center">Payment Method</th>
              <th className=" px-4 py-2 text-center">GCR No</th>
              <th className=" px-4 py-2 text-center">Total</th>
              <th className=" px-4 py-2 text-center">Bill</th>
              <th className=" px-4 py-2 text-center">Date</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-500">

            {paginatedData.map((item, index) => (

              <tr key={item.id} className="text-center">
                <td className=" px-4 py-2 text-center">{(currentPage - 1) * ITEMS_PER_PAGE + index + 1}</td>
                <td className=" px-4 py-2 text-center">{item.invoice_number}</td>
                <td className=" px-4 py-2 text-center">{item.patientname}</td>
                <td className=" px-4 py-2 text-center">{item.uhid}</td>
                <td className=" px-4 py-2 text-center">{item.Mobile_number}</td>
                <td className=" px-4 py-2 text-center">{item.department}</td>
                <td className=" px-4 py-2 text-center">{item.paymentmethod}</td>
                <td className=" px-4 py-2 text-center">{item.gcrno}</td>
                <td className=" px-4 py-2 text-center">{item.total}</td>
                <td className=" px-4 py-2 text-center">
                  <FontAwesomeIcon
                    icon={faFilePdf}
                    className="cursor-pointer text-black-500"
                    onClick={() => handleButtonClick(item.invoice_number)}
                  />          </td>
                <td className=" px-4 py-2 text-center">
                  {(() => {
                    const utcDate = new Date(item.currentdate);
                    const istDate = utcDate.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
                    const dateOnly = istDate.split(',')[0];
                    const [day, month, year] = dateOnly.split('/');
                    const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
                    return formattedDate;
                  })()}
                </td>
              </tr>
            ))
            }
          </tbody>
        </table>
      </div>

      <div className="flex justify-center mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          className={`mx-2 p-2 bg-gray-300 ${currentPage === 1 ? 'cursor-not-allowed' : ''}`}
          disabled={currentPage === 1}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>

        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={`mx-2 p-2 bg-gray-300 ${currentPage === index + 1 ? 'font-bold' : ''}`}
          >
            {index + 1}
          </button>
        ))}

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          className={`mx-2 p-2 bg-gray-300 ${currentPage === totalPages ? 'cursor-not-allowed' : ''}`}
          disabled={currentPage === totalPages}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      {showStatisticsModal && (
        <div
          className={`fixed inset-0 overflow-y-auto ${showStatisticsModal ? 'backdrop-filter backdrop-blur-sm' : ''} transition-all duration-300 ${showStatisticsModal ? 'opacity-100' : 'opacity-0 pointer-events-none'
            }`}
        >
          <div className="flex items-center justify-center h-screen">
            <div
              className={`bg-white p-4 w-1/2 md:w-1/2 lg:w-3/4 rounded shadow-lg transition-transform duration-300 transform ${showStatisticsModal ? 'scale-100' : 'scale-0'
                } border-2 border-blue-500 max-h-full overflow-auto`}
              style={{
                scrollbarWidth: 'thin',
                scrollbarColor: 'rgba(0, 0, 0, 0.5) transparent',
              }}
            >
              <h2 className="text-xl mb-4">Statistics</h2>
              <BarChart
                width={400}
                height={300}
                data={chartData}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#8884d8" />
              </BarChart>
              <button onClick={handleCloseStatisticsModal} className="bg-blue-500 text-white p-2 rounded">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BillingHistory;
