import React, { useState, useEffect } from 'react';
import axios from 'axios';

import config from '../../config';

const AppointmentForm = () => {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getCurrentDay = () => {
    const today = new Date();
    const dayIndex = today.getDay();
    return daysOfWeek[dayIndex];
  };

  const [uhid, setUHID] = useState('');
  const [patientName, setPatientName] = useState('');
  const [selectedDate, setSelectedDate] = useState(getCurrentDate());
  const [selectedDay, setSelectedDay] = useState(getCurrentDay());
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [doctors, setDoctors] = useState([]);

  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [selectedAllTiming, setSelectedAllTiming] = useState('');
  const [selectedMorningTiming, setSelectedMorningTiming] = useState('');
  const [consultationFees, setConsultationFees] = useState(0);
  const [allTimings, setAllTimings] = useState([]);
  const [timingCount, setTimingCount] = useState({});
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({ selectedDoctor: false });
  const [areDoctorsAvailable, setAreDoctorsAvailable] = useState(true);
  const [uhidTimer, setUHIDTimer] = useState(null);


  const handleUHIDChange = (e) => {
    const newUHID = e.target.value.toUpperCase();
    setUHID(newUHID);

    // Clear any existing timer
    if (uhidTimer) {
      clearTimeout(uhidTimer);
    }

    // Set a new timer to make the API call after a delay (e.g., 500 milliseconds)
    setUHIDTimer(setTimeout(() => fetchPatientData(newUHID), 2000));
  };
  const fetchPatientData = async (uhid) => {
    try {
      const response = await fetch(`${config.apiUrl}/consultation/patient/searchname?uhid=${uhid}`);
      const data = await response.json();

      if (response.ok) {
        setPatientName(data.patient_name);
      } else {
        setPatientName('');
      }
    } catch (error) {
      console.error('Error fetching patient data:', error);
      // Handle the error as needed
    }
  };


  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    const currentDate = getCurrentDate();

    // Check if the selected date is greater than or equal to the current date
    if (selectedDate >= currentDate) {
      setSelectedDate(selectedDate);

      // Get the corresponding day for the selected date
      const selectedDay = getDayFromDate(selectedDate);
      setSelectedDay(selectedDay);
    } else {
      // You can display an error message or take any other action here
      console.error('Selected date must be equal to or after the current date');
    }
  };


  const getDayFromDate = (date) => {
    const selectedDate = new Date(date);
    const dayIndex = selectedDate.getDay();
    return daysOfWeek[dayIndex];
  };

  useEffect(() => {
    axios.get(`${config.apiUrl}/consultation/departments`)
      .then(response => setDepartments(response.data))
      .catch(error => console.error('Error fetching departments:', error));
  }, []);

  useEffect(() => {
    if (selectedDepartment && selectedDay) {
      fetchAvailableDoctors();
    }

    if (selectedDoctor) {
      fetchConsultationFees();
    }
  }, [selectedDepartment, selectedDay, selectedDoctor]);

  const handleDepartmentChange = async (event) => {
    const selectedDepartment = event.target.value;
    setSelectedDepartment(selectedDepartment);
    setSelectedDoctor(''); // Reset selected doctor when department changes
    setErrors({ selectedDoctor: false });
    setSelectedAllTiming('');
    setConsultationFees(0);
  };
  const fetchAvailableDoctors = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/consultation/availableDoctorsAndTimings/${selectedDepartment}/${selectedDay}`
      );
      const { doctors } = response.data;
      setDoctors(doctors);
      setAreDoctorsAvailable(doctors.length > 0);
    } catch (error) {
      console.error('Error fetching available doctors:', error);
      setAreDoctorsAvailable(false);
    }
  }
  const fetchAvailableDoctorsAndTimings = async (department, day, doctorName) => {
    try {
      const responseAvailableDoctors = await axios.get(
        `${config.apiUrl}/consultation/availableDoctorsAndTimings/${department}/${day}`
      );

      const { doctors } = responseAvailableDoctors.data;
      const selectedDoctorData = doctors.find((doctor) => doctor.doctor_name === doctorName);

      if (selectedDoctorData) {
        const { morning_timings, evening_timings } = selectedDoctorData;
        const splitallTimings = [...morning_timings, ...evening_timings];
        const joinedtimings = splitallTimings.map((timing) => {
          return `${timing.start} - ${timing.end}`;
        });

        // Fetch timing count from another backend API
        const responseTimingCount = await axios.get(
          `${config.apiUrl}/consultation/timing-count?department_name=${department}&consultation_doctor=${doctorName}`
        );

        const timingCountData = responseTimingCount.data;
        console.log(timingCountData);

        const filteredTimings = joinedtimings.filter((timing) => {
          const matchingTimingCount = timingCountData.find(
            (data) => data.time === timing
          );

          return matchingTimingCount ? matchingTimingCount.timing_count < 3 : true;
        });

        setAllTimings(filteredTimings);
        setSelectedMorningTiming(filteredTimings[0]);
      } else {
        setAllTimings([]);
      }
    } catch (error) {
      console.error('Error fetching available timings:', error);
    } finally {

    }
  }

  const handleDoctorChange = async (event) => {
    const selectedDoctorName = event.target.value;
    setSelectedDoctor(selectedDoctorName);

    if (

      selectedDepartment &&
      selectedDay &&
      selectedDoctorName
    ) {
      fetchAvailableDoctorsAndTimings(
        selectedDepartment,
        selectedDay,
        selectedDoctorName
      );
    }
    fetchConsultationFees(selectedDoctorName);

  };

  useEffect(() => {
    // Initialize timing count when the selected doctor changes
    setTimingCount({});
  }, [selectedDoctor, selectedDepartment]);

  const handleTimingChange = (e) => {
    const selectedAllTiming = e.target.value;
    setSelectedAllTiming(selectedAllTiming);
    console.log("setSelectedAllTiming", selectedAllTiming);

  };
  const fetchConsultationFees = async (name) => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/consultation/doctorsAndFees/${selectedDepartment}/${name}`
      );
      setConsultationFees(response.data.data.doctor.consultant_fees);
    } catch (error) {
      console.error('Error fetching consultation fees:', error);

    }
  };

  const clearFormFields = () => {
    setSelectedDepartment('');
    setDoctors([]);
    setSelectedDoctor('');
    setConsultationFees(0);
    setUHID('');
    setPatientName('');
    setSelectedDay('');
    setSelectedDate('');
    setSelectedAllTiming('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    const newErrors = {};
    if (!uhid) {
      newErrors.uhid = true;
    }

    if (!selectedDepartment) {
      newErrors.selectedDepartment = true;
    }
    if (!selectedDoctor) {
      newErrors.selectedDoctor = true;
    }
    if (!selectedAllTiming) {
      newErrors.selectedAllTiming = true;
    }
    // Add similar checks for other input fields
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);

      setTimeout(() => {
        setErrors({});
      }, 1000);

      setIsSubmitting(false);

      return;
    }

    try {
      const payload = {
        uhid,
        patient_name: patientName,
        department_name: selectedDepartment,
        consultation_doctor: selectedDoctor,
        time: selectedAllTiming,
        date: selectedDate,
        consultation_fees: consultationFees,
      };

      // Make a POST request to the backend API
      const response = await axios.post(`${config.apiUrl}/consultation/addConsultation`, payload);

      console.log('Consultation data added successfully:', response.data);
      // Optionally, you can show a success message or redirect to another page

      setBookingSuccess(true);
      // Hide the success message after 1ms
      setTimeout(() => {
        setBookingSuccess(false);
      }, 1000);

      clearFormFields();
    } catch (error) {
      // console.error('Error adding consultation data:', error);

      // Check if the error contains a specific message
      if (error.response && error.response.data && error.response.data.error) {
        // Show the specific error message in an alert
        alert(error.response.data.error);
      } else {
        // If no specific message, show a generic error message
        alert('An error occurred while adding consultation data.');
      }


    } finally {
      // Re-enable the submit button in the 'finally' block
      setIsSubmitting(false);
    }


  };

  return (
    <div className="">
    <h2 className="text-lg text-center bg-sky-500 text-white text-xl mt-9">Appointment Form</h2>
  
    {/* Date Selection */}
    <div className="flex space-x-16 mx-48 ml-8 mt-9">
      <div>
        <label htmlFor="selectedDate" className="block text-lg font-semibold text-gray-700 mb-1">
          Select Date:
        </label>
        <input
          type="date"
          id="selectedDate"
          value={selectedDate}
          onChange={handleDateChange}
          className="border p-2 w-80 rounded-md focus:outline-none"
        />
      </div>
      <div>
        <label className="block text-lg font-semibold text-gray-700 mb-1">Selected Day:</label>
        <input
          type="text"
          value={selectedDay}
          readOnly
          className="border p-2 w-80 rounded-md focus:outline-none"
        />
      </div>
    </div>
  
    {/* Patient Information */}
    <div className="flex space-x-10 mt-4 mx-36 ml-9">
      <div className="flex-shrink-0 w-2/5">
        <label className="block text-sm font-semibold text-gray-700 mb-1">UHID:</label>
        <input
          type="text"
          value={uhid}
          onChange={handleUHIDChange}
          className={`border p-2 w-full rounded-md focus:outline-none transition duration-100 ${errors.uhid ? 'border-red-500' : 'border-gray-300'}`}
        />
      </div>
      <div className="flex-shrink-0 w-2/5">
        <label className="block text-sm font-semibold text-gray-700 mb-1">PATIENT NAME:</label>
        <input
          type="text"
          value={patientName}
          readOnly
          className="border p-2 w-full rounded-md focus:outline-none"
        />
      </div>
    </div>
  
    {/* Appointment Details */}
    <div className="flex space-x-1 mt-4 ml-10">
      <div className="flex-shrink-0 w-1/3">
        <label className="block text-lg font-semibold text-gray-700 mb-1">Select Department:</label>
        <select
          value={selectedDepartment}
          onChange={handleDepartmentChange}
          className={`border p-2 w-64 rounded-md focus:outline-none transition duration-100 ${errors.selectedDepartment ? 'border-red-500' : 'border-gray-300'}`}
        >
          <option value="">Select Department</option>
          {departments.map((department) => (
            <option key={department.name} value={department.name}>
              {department.name}
            </option>
          ))}
        </select>
      </div>
  
      <div className="flex-shrink-0 w-1/3">
        <label className="block text-lg font-semibold text-gray-700 mb-1">Select Doctor:</label>
        {areDoctorsAvailable ? (
          <select
            value={selectedDoctor}
            onChange={handleDoctorChange}
            className={`border p-2 w-64 rounded-md focus:outline-none transition duration-100 ${errors.selectedDoctor ? 'border-red-500' : 'border-gray-300'}`}
          >
            <option value="">Select Doctor</option>
            {doctors.map((doctor) => (
              <option key={doctor.doctor_id} value={doctor.doctor_name}>
                {doctor.doctor_name}
              </option>
            ))}
          </select>
        ) : (
          <div className="border p-2 w-64 rounded-md bg-gray-200">
            No doctors available
          </div>
        )}
      </div>
  
      <div className="flex-shrink-0 w-1/3">
        <label className="block text-lg font-semibold text-gray-700 mb-1">Select Timing:</label>
        <select
          value={selectedAllTiming}
          onChange={handleTimingChange}
          className={`border p-2 w-64 rounded-md focus:outline-none transition duration-75 ${errors.selectedAllTiming ? 'border-red-500' : 'border-gray-300'}`}
        >
          <option value="">Select Timing</option>
          {allTimings.map((timing, index) => (
            <option key={index} value={timing}>
              {timing}
            </option>
          ))}
        </select>
      </div>
    </div>
  
    {/* Consultation Fees and Submit Button */}
    <label className="block text-lg font-semibold text-gray-700 mt-12 ml-11">Consultation Fees: {consultationFees}</label>
    <div className="mb-4">
      <button
        className={`bg-${isSubmitting ? 'gray' : 'blue'}-500 text-white text-lg p-1.5 rounded float-right`}
        onClick={handleSubmit}
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Submitting...' : 'Submit'}
      </button>
    </div>
  
    {/* Booking Success Message */}
    {bookingSuccess && (
      <div className="fixed inset-0 flex items-center justify-center mt-6">
        <div className="bg-blue-600 p-3 rounded shadow-md text-lg text-white mt-6 mr-4">
          <span className="mr-4">✅</span> Booking successful!
        </div>
      </div>
    )}
  </div>
  
  )
};

export default AppointmentForm;
