import React, { useState, useEffect } from 'react';
import config from "../../config";

function AdmissionForm({ selectedPatient }) {
    const [formData, setFormData] = useState({
        uhid: '',
        patient_name: '',
        age: '',
        doctor_name: '',
        department: '',
        Mobile_number: '',
        date: '',
        diagnosis: '',
        treatment_procedure: '' // Change procedure to treatment_procedure
    });

    // Initialize formData with selectedPatient details
    useEffect(() => {
        if (selectedPatient) {
            setFormData({
                ...selectedPatient, // Spread existing selectedPatient details
                diagnosis: '',
                treatment_procedure: '' // Reset treatment_procedure
            });
        }
    }, [selectedPatient]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Ensure 'diagnosis' and 'treatment_procedure' fields are filled
        if (!formData.diagnosis.trim() || !formData.treatment_procedure.trim()) {
            alert("Please fill in both Diagnosis and Treatment Procedure fields.");
            return;
        }

        fetch(`${config.apiUrl}/doctor/api/admission`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...formData,
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                alert(data.message);

                setFormData(prevState => ({
                    ...prevState,
                    diagnosis: '',
                    treatment_procedure: ''
                }));
            })
            .catch(error => {
                console.error('Error submitting form:', error);
                alert('An error occurred, please try again');
            });
    };

    return (
        <div className="container mx-auto mt-5">
            <div className="flex justify-center">
                <div className="w-full max-w-lg">
                    <form onSubmit={handleSubmit} className='p-4 bg-white shadow-md rounded'>
                        <div className="mb-4">
                            <label htmlFor="diagnosis" className="block text-sm font-medium text-gray-700 text-right mb-2">Diagnosis</label>
                            <textarea
                                rows={4}
                                name="diagnosis"
                                value={formData.diagnosis}
                                onChange={handleChange}
                                placeholder="Enter Diagnosis"
                                className='w-full border border-gray-300 rounded-md p-2'
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="treatment_procedure" className="block text-sm font-medium text-gray-700 text-right mb-2">Treatment Procedure</label>
                            <textarea
                                rows={4}
                                name="treatment_procedure"
                                value={formData.treatment_procedure}
                                onChange={handleChange}
                                placeholder="Enter Treatment Procedure"
                                className='w-full border border-gray-300 rounded-md p-2'
                                required
                            />
                        </div>
                        <div className="flex justify-center">
                            <button
                                type="submit"
                                className='bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 transition duration-200'
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AdmissionForm;
