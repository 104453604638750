import { React, useState } from "react";
import axios from "axios";
import config from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function PurchaseForm() {
  const [formData, setFormData] = useState({
    medicinename: "",
    brandname: "",
    otherdetails: "",
    purchaseprice: "",
    totalqty: "",
    purchaseamount: 0,
    dosage: "",
    dosageUnit: "",
    expirydate: "",
    mrp: "",
  });
  const [popupType, setPopupType] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [dosageUnitPopupShown, setDosageUnitPopupShown] = useState(false);
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { id, value } = event.target;

    const numericValue = parseFloat(value);

    const inputElement = document.getElementById(id);
    if (inputElement) {
      inputElement.style.border = "";
      const errorMessageContainer = inputElement.parentNode.querySelector(
        ".error-message-container"
      );
      if (errorMessageContainer) {
        errorMessageContainer.remove();
      }
    }

    setFormData((prevData) => {
      let updatedData = {
        ...prevData,
        [id]:
          id === "medicinename" || id === "brandname"
            ? isNaN(numericValue)
              ? value.toUpperCase()
              : numericValue
            : prevData[id],
      };

      if (id === "purchaseprice" || id === "totalqty") {
        if (id === "totalqty") {
          const intValue = parseInt(value, 10);
          if (!isNaN(intValue)) {
            updatedData.totalqty = intValue;
            updatedData.purchaseamount =
              !isNaN(prevData.purchaseprice) && intValue
                ? prevData.purchaseprice * intValue
                : 0;
          }
        } else {
          const numericValue = parseFloat(value);
          if (!isNaN(numericValue)) {
            updatedData[id] = numericValue;
            updatedData.purchaseamount =
              id === "purchaseprice" && !isNaN(prevData.totalqty)
                ? numericValue * prevData.totalqty
                : id === "totalqty" && !isNaN(prevData.purchaseprice)
                  ? prevData.purchaseprice * numericValue
                  : 0;
          }
        }
      } else if (id === "mrp") {
        const numericValue = parseFloat(value);
        if (!isNaN(numericValue)) {
          updatedData.mrp = numericValue;
        }
      } else if (id === "otherdetails") {
        updatedData.otherdetails = value;
      }

      if (id === "expirydate" && !isNaN(new Date(value).getTime())) {
        const selectedDate = new Date(value);
        const currentDate = new Date();

        if (selectedDate < currentDate) {
          setPopupType("error");
          setPopupMessage("Please enter a valid expiry date.");
          setShowPopup(true);

          setTimeout(() => {
            setShowPopup(false);
          }, 2000);

          return prevData;
        }
        updatedData.expirydate = new Date(value).toISOString().split("T")[0];
      }
      return updatedData;
    });
  };


  const handleDosageUnitChange = (event) => {
    const { value } = event.target;
    const enteredValue = value.replace(/[^a-zA-Z]/g, '');

    setFormData((prevData) => {
      const currentDosage = String(prevData.dosage);
      const dosageWithoutUnit = currentDosage.replace(/[^\d.]/g, "");
      const newDosage = dosageWithoutUnit + enteredValue;
      return {
        ...prevData,
        dosage: newDosage.toUpperCase(),
        dosageUnit: enteredValue.toUpperCase(),
      };
    });
    setDosageUnitPopupShown(true);
  };


  const handleDosageUnitKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleCancel = (event) => {
    event.preventDefault();
    setFormData({
      medicinename: "",
      brandname: "",
      otherdetails: "",
      purchaseprice: "",
      totalqty: "",
      purchaseamount: 0,
      dosage: "",
      dosageUnit: "",
      expirydate: "",
      mrp: "",
    });
    setShowPopup(false);
  };
  console.log("formData", formData);

  const handleSubmit = async (event) => {
    console.log("hi");
    event.preventDefault();

    const emptyFields = Object.entries(formData).filter(([key, value]) => {
      if (key === 'dosage' || key === 'dosageUnit') {
        return false;
      }
      return typeof value === 'string' && !value.trim();
    });


    if (emptyFields.length > 0) {
      emptyFields.forEach(([key, _]) => {
        const element = document.getElementById(key);
        if (element) {
          element.classList.add("highlight-input");
        }
      });

      setTimeout(() => {
        emptyFields.forEach(([key, _]) => {
          const element = document.getElementById(key);
          if (element) {
            element.classList.remove("highlight-input");
          }
        });

      }, 2000);
      return;
    }

    try {
      await axios.post(`${config.apiUrl}/pharmacy/purchase`, formData);


      setFormData({
        medicinename: "",
        brandname: "",
        otherdetails: "",
        purchaseprice: "",
        totalqty: "",
        purchaseamount: 0,
        dosage: "",
        dosageUnit: "",
        expirydate: "",
        mrp: "",
      });
      showPopupMessage("success", "Medicine added successfully!");

      setTimeout(() => {
        setShowPopup(false);
      }, 2000);
    } catch (error) {
      console.error("Error submitting data: " + error);
    }
  };

  const showPopupMessage = (type, message) => {
    setPopupType(type);
    setPopupMessage(message);
    setShowPopup(true);

    setTimeout(() => {
      setShowPopup(false);
    }, 5000); // Timeout set to 1 minute (60,000 milliseconds)
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const currentInputId = event.target.id;
      const inputOrder = [
        "medicinename",
        "dosage",
        "brandname",
        "otherdetails",
        "purchaseprice",
        "totalqty",
        "expirydate",
        "mrp",
      ];

      const currentIndex = inputOrder.indexOf(currentInputId);

      if (currentIndex !== -1 && currentIndex < inputOrder.length - 1) {
        const nextInputId = inputOrder[currentIndex + 1];
        document.getElementById(nextInputId).focus();
      }
    }
  };

  return (
    <>
      <style>
        {`
           .highlight-input {
           border: 1px solid red;
          } 
        `}
      </style>

      <div className="container font-serif w-full">
        <div className="flex items-center justify-between w-full pb-4">
          <button
            className="flex items-center gap-2 p-2 bg-blue-800 text-white rounded hover:bg-blue-600"
            onClick={() => navigate('/purchase')}
          >
            <FontAwesomeIcon icon={faArrowCircleLeft} />
          </button>

          <h1 className="text-2xl font-bold text-green-400 flex-grow text-center">
            Purchase Form
          </h1>
        </div>
        <div className="mt-4 ">
          <form onSubmit={handleSubmit} className="bg-white border border-gray-300 p-4 rounded-md shadow-md">
            <h2 className="text-xl font-bold mb-4">Medicine Information</h2>

            <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
              {/* Medicine Name */}
              <div className="col-span-12 md:col-span-6">
                <label htmlFor="medicinename" className="font-semibold">
                  Medicine Name
                </label>
                <input
                  type="text"
                  className="mt-1 p-2 border rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                  id="medicinename"
                  value={formData.medicinename}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
              </div>

              {/* Dosage */}
              <div className="col-span-12 md:col-span-6">
                <label htmlFor="dosage" className="font-semibold">
                  Dosage
                </label>
                <div className="flex gap-4">
                  <input
                    type="text"
                    className="mt-1 p-2 border rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                    id="dosage"
                    value={formData.dosage.toUpperCase()}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onInput={(e) => {
                      const numericValue = e.target.value.replace(/[^0-9.]/g, "");
                      setFormData((prevData) => ({ ...prevData, dosage: numericValue }));
                    }}
                    onBlur={(e) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        dosage: String(prevData.dosage).endsWith(formData.dosageUnit)
                          ? prevData.dosage
                          : prevData.dosage + prevData.dosageUnit,
                      }));
                    }}
                  />
                  <input
                    type="text"
                    id="dosageUnit"
                    list="unitOptions"
                    className="mt-1 p-2 border rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                    placeholder="Dosage unit"
                    value={formData.dosageUnit.toUpperCase()}
                    onChange={(e) => {
                      handleDosageUnitChange(e);
                      document.getElementById("dosage").dispatchEvent(new Event("blur"));
                    }}
                    onKeyDown={handleDosageUnitKeyDown}
                  />
                </div>
                <datalist id="unitOptions">
                  <option value="MG" />
                  <option value="ML" />
                  <option value="GM" />
                  {formData.dosageUnit && !["MG", "ML", "GM"].includes(formData.dosageUnit.toUpperCase()) && (
                    <option value={formData.dosageUnit.toUpperCase()} />
                  )}
                </datalist>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-12 gap-4 my-4">
              {/* Brand Name */}
              <div className="col-span-12 md:col-span-6">
                <label htmlFor="brandname" className="font-semibold">Brand Name</label>
                <input
                  type="text"
                  className="mt-1 p-2 border rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                  id="brandname"
                  value={formData.brandname}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
              </div>

              {/* Other Details */}
              <div className="col-span-12 md:col-span-6">
                <label htmlFor="otherdetails" className="font-semibold">Other Details</label>
                <input
                  type="text"
                  className="mt-1 p-2 border rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                  id="otherdetails"
                  value={formData.otherdetails}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
              {/* Purchase Price */}
              <div className="col-span-12 sm:col-span-6 md:col-span-4">
                <label htmlFor="purchaseprice" className="font-semibold">Purchase Price</label>
                <input
                  type="number"
                  className="mt-1 p-2 border rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                  id="purchaseprice"
                  value={formData.purchaseprice}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  onInput={(e) => {
                    const numericValue = e.target.value.replace(/[^0-9]/g, "");
                    setFormData((prevData) => ({ ...prevData, purchaseprice: numericValue }));
                  }}
                />
              </div>

              {/* Total Qty */}
              <div className="col-span-12 sm:col-span-6 md:col-span-4">
                <label htmlFor="totalqty" className="font-semibold">Total Qty</label>
                <input
                  type="number"
                  className="mt-1 p-2 border rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                  id="totalqty"
                  value={formData.totalqty}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  onInput={(e) => {
                    const numericValue = e.target.value.replace(/[^0-9]/g, "");
                    setFormData((prevData) => ({ ...prevData, totalqty: numericValue }));
                  }}
                />
              </div>

              {/* Purchase Amount */}
              <div className="col-span-12 sm:col-span-6 md:col-span-4">
                <label htmlFor="purchaseamount" className="font-semibold">Purchase Amount</label>
                <input
                  type="number"
                  className="mt-1 p-2 border rounded w-full bg-gray-200 cursor-not-allowed"
                  id="purchaseamount"
                  value={formData.purchaseamount}
                  readOnly
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
              {/* Expiry Date */}
              <div className="col-span-12 sm:col-span-6">
                <label htmlFor="expirydate" className="font-semibold">Expiry Date</label>
                <input
                  type="date"
                  className="mt-1 p-2 border rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                  id="expirydate"
                  value={formData.expirydate}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
              </div>

              {/* MRP */}
              <div className="col-span-12 md:col-span-6">
                <label htmlFor="mrp" className="font-semibold">MRP</label>
                <input
                  type="number"
                  className="mt-1 p-2 border rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                  id="mrp"
                  value={formData.mrp}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  onInput={(e) => {
                    const numericValue = e.target.value.replace(/[^0-9]/g, "");
                    setFormData((prevData) => ({ ...prevData, mrp: numericValue }));
                  }}
                />
              </div>
            </div>

            <div className="flex justify-end mt-4">
              <button
                type="button"
                className="bg-blue-500 text-white rounded px-4 py-2 mr-2 hover:bg-blue-600 transition"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-blue-600 text-white rounded px-4 py-2 hover:bg-blue-700 transition"
              >
                Submit
              </button>
            </div>

            {showPopup && (
              <div className={`popup ${popupType === 'error' ? 'bg-red-500' : 'bg-green-500'} text-white p-4 rounded mt-4`}>
                {popupMessage}
              </div>
            )}
          </form>

        </div>
      </div>

    </>
  );
}


export default PurchaseForm;