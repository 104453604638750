import React from 'react';
import { useNavigate } from 'react-router-dom';
import labTestImage from '../../Assets/labtest.jfif';
import inventoryImage from '../../Assets/lab1.jpg';
import reportsImage from '../../Assets/labreport.jpg';
import '../../Styles/card.css';
import { Button } from '../HomeComponent/button';

const LabManagement = () => {
  const navigate = useNavigate();
  const [hovered, setHovered] = React.useState(null);

  const handleMouseEnter = (index) => setHovered(index);
  const handleMouseLeave = () => setHovered(null);

  const cardData = [
    {
      title: 'Lab Test Management',
      content: '',
      path: '/lab/labtestlist',
      image: labTestImage,
    },
    {
      title: 'Inventory Management',
      content: '',
      path: '/lab/availabletests',
      image: inventoryImage,
    },
    {
      title: `Lab Category's`,
      content: '',
      path: '/lab/category',
      image: reportsImage,
    },
    // {
    //   title: 'Lab Reports',
    //   content: '',
    //   path: '/lab',
    //   image: reportsImage,
    // },
    
  ];

  const handleCardClick = (path) => {
    navigate(path);
  };

  return (
    <div className="container mx-auto px-4">
      {/* Page Title */}
      <h1 className="text-2xl font-bold text-center mt-6  text-green-400">Lab Management Module</h1>

      <div className="flex justify-center items-center">
        <div className="flex flex-wrap gap-6 mt-14">
          {cardData.map((card, index) => (
            <div
              key={index}
              className={`relative w-80 h-[300px] bg-base-100 border rounded-lg shadow-xl overflow-hidden cursor-pointer 
              transition-transform duration-200 ${hovered === index ? 'scale-105' : ''}`}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <figure className="h-2/3">
                <img
                  src={card.image}
                  alt={card.title}
                  className="w-full h-full object-cover"
                />
              </figure>
              <div className="card-body h-1/3 flex flex-col items-center justify-center bg-gray-200">
                <div className="mt-4">
                  <Button onClick={() => handleCardClick(card.path)} title={card.title} />
                </div>
                <p className="text-gray-600 p-2">{card.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LabManagement;
