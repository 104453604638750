import React, { useEffect, useState } from 'react';
import config from "../../config"

const TestResults = ({ patient, onClose }) => {
    const [testResults, setTestResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTestResults = async () => {
            if (!patient || !patient.uhid) return; // Check if patient and uhid exist

            setLoading(true);
            setError(null); // Reset error state
            try {
                const response = await fetch(`${config.apiUrl}/lab/cbc/report/${patient.uhid}`);

                // Handle 404 error explicitly
                if (response.status === 404) {
                    setError(`No test results available for ${patient.patient_name}.`);
                    setTestResults([]); // Clear test results in case of 404
                    return;
                }

                if (!response.ok) {
                    throw new Error('Failed to fetch test results');
                }

                const data = await response.json();
                setTestResults(data);
            } catch (err) {
                setError('Unable to retrieve test results. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchTestResults();
    }, [patient]);

    if (loading) return <div className="text-center">Loading...</div>;

    // If there's an error, but we still want to display no data
    if (error) {
        return (
            <div className=" text-center">
                <p>{error}</p>
                <button onClick={onClose} className="mt-4 bg-red-500 text-white py-2 px-6 rounded hover:bg-red-600 transition duration-200">
                    Close
                </button>
            </div>
        );
    }

    // If no results, show no data message
    if (testResults.length === 0) {
        return (
            <div className="text-center">
                <p>No test results available for {patient?.patient_name}.</p>
                <button onClick={onClose} className="mt-4 bg-red-500 text-white py-2 px-6 rounded hover:bg-red-600 transition duration-200">
                    Close
                </button>
            </div>
        );
    }

    return (
        <div className="p-6 bg-gray-800 rounded-lg shadow-md text-gray-300 ">
            <h2 className="text-2xl font-bold mb-4 text-center">Test Results for {patient?.patient_name}</h2>
            <div className="h-[400px] overflow-auto">
                {testResults.map(result => (
                    <div key={result.id} className="bg-gray-700 rounded-lg p-4 mb-4">
                        <p><strong>Created Date:</strong> {new Date(result.created_at).toLocaleString()}</p>

                        <div className="grid grid-cols-2 gap- p-10">

                            <div>
                                <h3 className="text-xl font-semibold">Report ID: {result.id}</h3>
                                <p><strong>Hemoglobin:</strong> {result.hemoglobin ?? 'N/A'}</p>
                                <p><strong>RBC Count:</strong> {result.rbc_count ?? 'N/A'}</p>
                                <p><strong>WBC Count:</strong> {result.wbc_count ?? 'N/A'}</p>
                                <p><strong>Platelet Count:</strong> {result.platelet_count ?? 'N/A'}</p>
                            </div>
                            <div>
                                <h3 className="text-xl font-semibold">Detailed Metrics</h3>
                                <p><strong>Hematocrit:</strong> {result.hematocrit ?? 'N/A'}</p>
                                <p><strong>MCV:</strong> {result.mcv ?? 'N/A'}</p>
                                <p><strong>MCH:</strong> {result.mch ?? 'N/A'}</p>
                                <p><strong>MCHC:</strong> {result.mchc ?? 'N/A'}</p>
                                <p><strong>Neutrophils:</strong> {result.neutrophils ?? 'N/A'}</p>
                                <p><strong>Lymphocytes:</strong> {result.lymphocytes ?? 'N/A'}</p>
                                <p><strong>Monocytes:</strong> {result.monocytes ?? 'N/A'}</p>
                                <p><strong>Eosinophils:</strong> {result.eosinophils ?? 'N/A'}</p>
                                <p><strong>Basophils:</strong> {result.basophils ?? 'N/A'}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="mt-4 text-center">
                <button onClick={onClose} className="bg-red-500 text-white py-2 px-6 rounded hover:bg-red-600 transition duration-200">
                    Close
                </button>
            </div>
        </div>
    );
};

export default TestResults;
