// BillingModal.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const BillingModal = ({ isOpen, onClose, selectedPatient, paymentMethod, setPaymentMethod, onSave }) => {
  if (!isOpen) return null;

  return (
    <div className={`fixed inset-0 backdrop-filter backdrop-blur-sm overflow-y-auto transition-all duration-300 ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
      <div className="flex items-center justify-center h-screen">
        <div className="bg-white p-4 w-1/2 md:w-1/2 lg:w-3/4 rounded shadow-lg border-2 border-blue-500">
          <div className="p-4 flex flex-col">
            <div className="flex justify-end items-center">
              <FontAwesomeIcon
                icon={faTimes}
                onClick={onClose}
                className="text-red-500 cursor-pointer"
              />
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <h2 className="font-bold text-xl mb-2">Patient Details</h2>
                {[
                  { label: 'Patient Name', value: selectedPatient?.patient_name },
                  { label: 'Age', value: selectedPatient?.age },
                  { label: 'UHID', value: selectedPatient?.uhid },
                  { label: 'Mobile Number', value: selectedPatient?.Mobile_number },
                ].map(({ label, value }, index) => (
                  <div className="mb-2" key={index}>
                    <label className="block text-sm font-medium text-gray-700">{label}</label>
                    <input
                      value={value || ''}
                      className="border p-2 mb-4 w-full"
                      readOnly
                    />
                  </div>
                ))}
              </div>

              <div>
                <h2 className="font-bold text-xl mb-2">Consultation Details</h2>
                {[
                  { label: 'Consultation Doctor', value: selectedPatient?.doctor_name },
                  { label: 'Department', value: selectedPatient?.department },
                  { label: 'Test Name', value: selectedPatient?.procedure_name },
                  { label: 'Price', value: selectedPatient?.price },
                ].map(({ label, value }, index) => (
                  <div className="mb-2" key={index}>
                    <label className="block text-sm font-medium text-gray-700">{label}</label>
                    <input
                      value={value || ''}
                      className="border p-2 mb-4 w-full"
                      readOnly
                    />
                  </div>
                ))}
              </div>
            </div>

            <div>
              <h2 className="font-bold text-xl mb-2">Payment Method</h2>
              <div className="flex items-center">
                <input
                  type="radio"
                  id="cash"
                  name="paymentMethod"
                  checked={paymentMethod.cash}
                  onChange={() => setPaymentMethod({ cash: true, online: false })}
                />
                <label htmlFor="cash" className="ml-2">Cash</label>

                <input
                  type="radio"
                  id="online"
                  name="paymentMethod"
                  checked={paymentMethod.online}
                  onChange={() => setPaymentMethod({ cash: false, online: true })}
                  className="ml-4"
                />
                <label htmlFor="online" className="ml-2">Online</label>
              </div>
            </div>

            <button
              className="mt-4 p-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              onClick={onSave}
            >
              Save Billing
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingModal;
