import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';

const StaffRegistrationForm = () => {
    const navigate = useNavigate();

    const [educationalRows, setEducationalRows] = useState([{ degree: '', university: '', passoutYear: '', grade: '', specialization: '' }]);
    const [employmentRows, setEmploymentRows] = useState([{ id: 1, organisation: '', designation: '', periodOfService: '', annualCTC: '' }]);
    const [familyRows, setFamilyRows] = useState([{ name: '', age: '', relation: '', occupation: '' }]);

    const handleAddEducationalRow = () => {
        setEducationalRows([...educationalRows, { degree: '', university: '', passoutYear: '', grade: '', specialization: '' }]);
    };

    const handleAddEmploymentRow = () => {
        const newRow = { id: employmentRows.length + 1, organisation: '', designation: '', periodOfService: '', annualCTC: '' };
        setEmploymentRows([...employmentRows, newRow]);
    };

    const handleAddFamilyRow = () => {
        setFamilyRows([...familyRows, { name: '', age: '', relation: '', occupation: '' }]);
    };

    const handleInputChange = (index, event, table) => {
        const { name, value } = event.target;
        if (table === 'educational') {
            const rows = [...educationalRows];
            rows[index][name] = value;
            setEducationalRows(rows);
        } else if (table === 'employment') {
            const rows = [...employmentRows];
            rows[index][name] = value;
            setEmploymentRows(rows);
        } else if (table === 'family') {
            const rows = [...familyRows];
            rows[index][name] = value;
            setFamilyRows(rows);
        }
    };
    

    return (
        <div className="  mt-5 px-8 pt-2 pb-8 mb-4 ">
            <div className="flex items-center justify-between w-full pb-4">
          {/* Button on the left */}
          <button
            className="flex items-center gap-2 p-2 bg-blue-800 text-white rounded hover:bg-blue-600"
            onClick={() => navigate('/hr')}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>

          {/* Heading centered */}
          <h1 className="text-2xl font-bold text-green-400 flex-grow text-center">
          Employee Joining Form          </h1>
        </div>

         
            <div className='p-6 shadow-md '>
  {/* First row */}
 
            <div className="mb-4 text-right">
                <p>Date: {new Date().toLocaleDateString()}</p>
            </div>
            <div className="flex mb-4">
                <div className="w-1/2 pr-4">
                    <div className="flex mb-4">
                        <div className="pr-4 w-1/2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="firstName">
                                First Name
                            </label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="firstName" type="text" placeholder="First Name" />
                        </div>
                        <div className="pr-4 w-1/2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastName">
                                Last Name
                            </label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="lastName" type="text" placeholder="Last Name" />
                        </div>
                    </div>
                    <div className="pr-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fatherName">
                            Father's Name
                        </label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="fatherName" type="text" placeholder="Father's Name" />
                    </div>
                    {/* Add Correspondence Address here */}
                </div>
                {/* Right side */}
                <div className="w-1/2 flex items-center justify-center">
                    <div className="w-32 h-32 bg-gray-200 rounded-full flex items-center justify-center">
                        Profile Picture
                    </div>
                </div>
            </div>
            {/* Next row */}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="permanentAddress">
                    Permanent Address
                </label>
                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="permanentAddress" type="text" placeholder="Permanent Address" />
            </div>
            {/* Third row */}
            <div className="flex mb-4">
                <div className="w-1/3 pr-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="emergencyName">
                        Telephone
                    </label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="emergencyName" type="text" placeholder="Name" />
                </div>
                <div className="w-1/3 px-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="relation">
                        Mobile No.
                    </label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="relation" type="text" placeholder="Relation" />
                </div>
                <div className="w-1/3 pl-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="emergencyContact">
                        Email
                    </label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="emergencyContact" type="text" placeholder="Contact Number" />
                </div>
            </div>
            <div className="flex mb-4">
                <div className="w-1/3 pr-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="emergencyName">
                        Date of Birth
                    </label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="emergencyName" type="text" placeholder="Name" />
                </div>
                <div className="w-1/3 px-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="relation">
                        Marital Status
                    </label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="relation" type="text" placeholder="Relation" />
                </div>
                <div className="w-1/3 pl-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="emergencyContact">
                        Blood Group
                    </label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="emergencyContact" type="text" placeholder="Contact Number" />
                </div>
                
            </div>
            
            {/* Educational Details Table */}
            <div className='mb-4'>
                <h2 className="block text-gray-700 text-sm font-bold mb-2">Educational Details</h2>
                <table className="w-full">
                <thead>
                    <tr>
                        <th className="border px-4 py-2">Degree</th>
                        <th className="border px-4 py-2">University/Institute</th>
                        <th className="border px-4 py-2">Passout Year</th>
                        <th className="border px-4 py-2">Percentage/Grade</th>
                        <th className="border px-4 py-2">Specialization</th>
                    </tr>
                </thead>
                <tbody>
                {educationalRows.map((row, index) => (
                        <tr key={index}>
                            <td className="border px-4 py-2">
                                <input
                                    type="text"
                                    name="degree"
                                    value={row.degree}
                                    onChange={e => handleInputChange(index, e)}
                                    className="w-full focus:outline-none"
                                    placeholder="Enter degree"
                                />
                            </td>
                            <td className="border px-4 py-2">
                                <input
                                    type="text"
                                    name="university"
                                    value={row.university}
                                    onChange={e => handleInputChange(index, e)}
                                    className="w-full focus:outline-none"
                                    placeholder="Enter university/institute"
                                />
                            </td>
                            <td className="border px-4 py-2">
                                <input
                                    type="text"
                                    name="passoutYear"
                                    value={row.passoutYear}
                                    onChange={e => handleInputChange(index, e)}
                                    className="w-full focus:outline-none"
                                    placeholder="Enter passout year"
                                />
                            </td>
                            <td className="border px-4 py-2">
                                <input
                                    type="text"
                                    name="grade"
                                    value={row.grade}
                                    onChange={e => handleInputChange(index, e)}
                                    className="w-full focus:outline-none"
                                    placeholder="Enter percentage/grade"
                                />
                            </td>
                            <td className="border px-4 py-2">
                                <input
                                    type="text"
                                    name="specialization"
                                    value={row.specialization}
                                    onChange={e => handleInputChange(index, e)}
                                    className="w-full focus:outline-none"
                                    placeholder="Enter specialization"
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
            <button onClick={handleAddEducationalRow} className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Add Row</button>
</div>
            </div>
            <div className='mb-4'>
            <h2 className="block text-gray-700 text-sm font-bold mb-2">Employment Details (Last Organisation)</h2>
            <table className="w-full">
                <thead>
                    <tr>
                        <th className="border px-4 py-2">S.No</th>
                        <th className="border px-4 py-2">Organisation</th>
                        <th className="border px-4 py-2">Designation</th>
                        <th className="border px-4 py-2">Period of Service</th>
                        <th className="border px-4 py-2">Annual CTC</th>
                    </tr>
                </thead>
                <tbody>
                {employmentRows.map((row, index) => (
                        <tr key={index}>
                            <td className="border px-4 py-2">{row.id}</td>
                            <td className="border px-4 py-2">
                                <input
                                    type="text"
                                    name="organisation"
                                    value={row.organisation}
                                    onChange={e => handleInputChange(index, e)}
                                    className="w-full focus:outline-none"
                                    placeholder="Enter organisation"
                                />
                            </td>
                            <td className="border px-4 py-2">
                                <input
                                    type="text"
                                    name="designation"
                                    value={row.designation}
                                    onChange={e => handleInputChange(index, e)}
                                    className="w-full focus:outline-none"
                                    placeholder="Enter designation"
                                />
                            </td>
                            <td className="border px-4 py-2">
                                <input
                                    type="text"
                                    name="periodOfService"
                                    value={row.periodOfService}
                                    onChange={e => handleInputChange(index, e)}
                                    className="w-full focus:outline-none"
                                    placeholder="Enter period of service"
                                />
                            </td>
                            <td className="border px-4 py-2">
                                <input
                                    type="text"
                                    name="annualCTC"
                                    value={row.annualCTC}
                                    onChange={e => handleInputChange(index, e)}
                                    className="w-full focus:outline-none"
                                    placeholder="Enter annual CTC"
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
            <button onClick={handleAddEmploymentRow} className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Add Row</button>
            </div>
        </div>
            <div>
                <h2 className="block text-gray-700 text-sm font-bold mb-2">Family Details</h2>
                <table className="w-full">
                <thead>
                    <tr>
                        <th className="border px-4 py-2">s.No</th>
                        <th className="border px-4 py-2">Name</th>
                        <th className="border px-4 py-2">Age</th>
                        <th className="border px-4 py-2">Relation</th>
                        <th className="border px-4 py-2">Occupation</th>
                    </tr>
                </thead>
                <tbody>
                {familyRows.map((row, index) => (
                        <tr key={index}>
                            <td className="border px-4 py-2">
                                <input
                                    type="text"
                                    name="degree"
                                    value={row.degree}
                                    onChange={e => handleInputChange(index, e)}
                                    className="w-full focus:outline-none"
                                    placeholder="Enter degree"
                                />
                            </td>
                            <td className="border px-4 py-2">
                                <input
                                    type="text"
                                    name="university"
                                    value={row.university}
                                    onChange={e => handleInputChange(index, e)}
                                    className="w-full focus:outline-none"
                                    placeholder="Enter university/institute"
                                />
                            </td>
                            <td className="border px-4 py-2">
                                <input
                                    type="text"
                                    name="passoutYear"
                                    value={row.passoutYear}
                                    onChange={e => handleInputChange(index, e)}
                                    className="w-full focus:outline-none"
                                    placeholder="Enter passout year"
                                />
                            </td>
                            <td className="border px-4 py-2">
                                <input
                                    type="text"
                                    name="grade"
                                    value={row.grade}
                                    onChange={e => handleInputChange(index, e)}
                                    className="w-full focus:outline-none"
                                    placeholder="Enter percentage/grade"
                                />
                            </td>
                            <td className="border px-4 py-2">
                                <input
                                    type="text"
                                    name="specialization"
                                    value={row.specialization}
                                    onChange={e => handleInputChange(index, e)}
                                    className="w-full focus:outline-none"
                                    placeholder="Enter specialization"
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
            <button onClick={handleAddFamilyRow} className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Add Row</button>
</div>
           
            </div>
            <div className="text-center">
                <button className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Submit</button>
            </div>
            </div>
           
        </div>
    );
}

export default StaffRegistrationForm;
