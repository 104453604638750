import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faFileAlt,
  faArrowLeft,
  faSync,
  faCalendar,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';

import config from "../../config";
import PatientDetails from "./patientreports";
import AdmissionForm from "./AdmissionForm";
import CardiologyForm from "./CardiologyForm";
import axios from "axios";
import TestResults from "./TestReports";

const TestCategory = ({ title, onClick }) => (
  <div className="w-full">
    <h3
      className="text-xl font-semibold bg-gray-100 hover:bg-cyan-300 rounded-md p-4 cursor-pointer shadow-md"
      onClick={onClick}
    >
      {title}
    </h3>
  </div>
);


const OutpatientsList = () => {
  const [patients, setPatients] = useState([]);  
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [selectedMedicine, setSelectedMedicine] = useState("");
  const [brandNames, setBrandNames] = useState([]);
  const inputRef = useRef(null);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [showPrescriptionForm, setShowPrescriptionForm] = useState(false);
  const [showAdmissionForm, setShowAdmissionForm] = useState(false);
  const [showCardiologyForm, setShowCardiologyForm] = useState(false); // Add state for lab module
  const navigate = useNavigate();

  const [medicines, setMedicines] = useState([
    {
      brandname: "",
      name: "",
      frequency: "",
      instruction: "",
      morning: false,
      afternoon: false,
      evening: false,
      night: false,
    },
  ]);
  const [medicineNames, setMedicineNames] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [emptyFields, setEmptyFields] = useState([]);
  const [patientData, setPatientData] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
  const [isAppointmentModalOpen, setAppointmentModalOpen] = useState(false);
  const [uhidAppointFilter, setuhidAppointFilter] = useState("");
  const [departmentAppointFilter, setdepartmentAppointFilter] = useState("");
  const [startDateAppointFilter, setstartDateAppointFilter] = useState("");
  const [endDateAppointFilter, setendDateAppointFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const storedUserData = localStorage.getItem("user");
  const parsedUserData = storedUserData ? JSON.parse(storedUserData) : null;
  const [appointments, setAppointments] = useState([]);
  const [labTests, setLabTests] = useState([]);

  const handleResetForm = () => {
    setPatients([]);
    setPatientData(null);
    setShowModal(false);
    setShowModal2(false);
    setShowPrescriptionForm(false);
    setShowAdmissionForm(false);
    setShowCardiologyForm(false);
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = () => {
    if (parsedUserData.user.user_role === "Admin") {
      fetch(`${config.apiUrl}/doctor/patients`)
        .then((response) => response.json())
        .then((data) => setPatients(data))
        .catch((error) => console.error("Error fetching patient data:", error));
    } else {
      fetch(
        `${config.apiUrl}/doctor/patients/${parsedUserData.user.user_department}/${parsedUserData.user.user_first_name}.${parsedUserData.user.user_last_name}`
      )
        .then((response) => response.json())
        .then((data) => setPatients(data))
        .catch((error) => console.error("Error fetching patient data:", error));
    }
  };

  useEffect(() => {
    fetch(`${config.apiUrl}/op/departments`)
      .then((response) => response.json())
      .then((data) => {
        setDepartments(data);
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${config.apiUrl}/pharmacy/getallstock`)
      .then((response) => response.json())
      .then((data) => {
        setMedicineNames(data);
      })
      .catch((error) => console.error("Error fetching medicine names:", error));
  }, []);

  const handleInputChange = (index, field, value) => {
    const updatedMedicines = [...medicines];
    updatedMedicines[index][field] = value;
    setMedicines(updatedMedicines);

    const updatedEmptyFields = [...emptyFields];
    if (value.trim() === "" && !updatedEmptyFields.includes(index)) {
      updatedEmptyFields.push(index); // Field is empty, add to emptyFields
    } else if (value.trim() !== "") {
      const indexOfField = updatedEmptyFields.indexOf(index);
      if (indexOfField !== -1) {
        updatedEmptyFields.splice(indexOfField, 1);
      }
    }
    setEmptyFields(updatedEmptyFields);

    if (field === "name") {
      setSelectedMedicine(value);
    }
  };

  useEffect(() => {
    if (selectedMedicine) {
      fetch(`${config.apiUrl}/pharmacy/allstock/${selectedMedicine}`)
        .then((response) => response.json())
        .then((data) => {
          if (Array.isArray(data)) {
            setBrandNames(data);
          }
        })
        .catch((error) => console.error("Error fetching brand names:", error));
    }
  }, [selectedMedicine]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const updatedEmptyFields = [];

    medicines.forEach((medicine, index) => {
      if (
        medicine.brandname === "" ||
        medicine.name === "" ||
        medicine.frequency === "" ||
        medicine.instruction === ""
      ) {
        updatedEmptyFields.push(index);
      }
    });

    setEmptyFields(updatedEmptyFields);

    if (updatedEmptyFields.length > 0) {
      const firstEmptyFieldIndex = updatedEmptyFields[0];
      const element = document.getElementById(
        `medicineName-${firstEmptyFieldIndex}`
      );
      if (element) {
        element.focus();
      }
      return;
    }

    const isAnyCheckboxUnchecked = medicines.some(
      (medicine) =>
        !(
          medicine.morning ||
          medicine.afternoon ||
          medicine.evening ||
          medicine.night
        )
    );

    if (isAnyCheckboxUnchecked) {
      alert("Please select at least one checkbox.");
      return;
    }

    setIsButtonDisabled(true);
    setShowSuccessPopUp(true);

    setTimeout(() => {
      setShowSuccessPopUp(false);
    }, 2500);
    fetch(`${config.apiUrl}/doctor/savePrescription`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        patientname: selectedPatient.patient_name,
        uhid: selectedPatient.uhid,
        age: selectedPatient.age,
        doctor_name: selectedPatient.doctor_name,
        department: selectedPatient.department,
        medicines,
        Mobile_number: selectedPatient.Mobile_number,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Prescription data saved successfully:", data);
        setMedicines([
          { brandname: "", name: "", frequency: "", instruction: "", time: "" },
        ]);

        const updateDoctorStatusPromise = () => {
          fetch(
            `${config.apiUrl}/op/Update-Doctor-Visit/${selectedPatient.uhid}/${selectedPatient.date}/${selectedPatient.doctor_name}/${selectedPatient.department}`,
            {
              method: "PUT",
            }
          )
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json();
            })
            .catch((error) => {
              console.error("Error updating Doctor visit:", error);
              throw new Error("Error updating Doctor visit");
            });
        };
        updateDoctorStatusPromise();
        handleCloseModal();
        handleResetForm();
      })
      .catch((error) => {
        console.error("Error saving prescription data:", error);
      });
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 2000);
  };

  const handlePatientClick = (patient) => {
    setSelectedPatient(patient);
    setShowModal(true);
    setShowPrescriptionForm(false);
    setShowAdmissionForm(false);
    setShowCardiologyForm(false);
  };

  const ViewTestResults = (patient) => {
    setSelectedPatient(patient);
    setShowModal4(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setShowModal2(false);
    
    setShowModal4(false);
    setShowPrescriptionForm(false);
    setShowAdmissionForm(false);
    setShowCardiologyForm(false);
  };
  const handleAddMedicine = () => {
    setMedicines([
      ...medicines,
      {
        brandname: "",
        name: "",
        frequency: "",
        instruction: "",
        time: "",
      },
    ]);
    setEmptyFields([]);
  };

  const handleReportClick = (patient) => {
    fetch(
      `${config.apiUrl}/doctor/patient/${patient.uhid}/${patient.doctor_name}/${patient.department}/${patient.date}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch patient data");
        }
        return response.json();
      })
      .then((data) => {
        setPatientData(data);
        setShowModal2(true);
      })
      .catch((error) => {
        console.error("Error fetching patient data:", error);
      });
  };

  const handleCheckboxClick = (index, time) => {
    const updatedSelectedTimes = [...selectedTimes];
    updatedSelectedTimes[index] = {
      ...updatedSelectedTimes[index],
      [time]: !updatedSelectedTimes[index]?.[time],
    };
    setSelectedTimes(updatedSelectedTimes);
  };

  const handleRemoveMedicine = (index) => {
    const updatedMedicines = [...medicines];
    updatedMedicines.splice(index, 1);
    setMedicines(updatedMedicines);
  };

  const handleSearchAppointUhid = (e) => {
    setuhidAppointFilter(e.target.value);
  };

  const handleSearchAppointDepartment = (e) => {
    setdepartmentAppointFilter(e.target.value);
  };

  const handleStartDateAppointChange = (e) => {
    setstartDateAppointFilter(e.target.value);
  };

  const handleEndDateAppointChange = (e) => {
    setendDateAppointFilter(e.target.value);
  };

  const indFormattedDate = (date) => {
    const utcDate = new Date(date);
    const options = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const formattedDate = new Intl.DateTimeFormat("en-IN", options).format(
      utcDate
    );
    const [day, month, year] = formattedDate.split("/");
    return `${year}-${month}-${day}`;
  };
  const handleOpenAppointmentModal = () => {
    setAppointmentModalOpen(true);
  };

  const handleCloseAppointmentModal = () => {
    setAppointmentModalOpen(false);
  };
  const fetchFutureAppoinment = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/op/future-appointments`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (data.success) {
        setAppointments(data.data);
      } else {
        console.error("Error fetching future appointments:", data.message);
      }
    } catch (error) {
      console.error("Error fetching future appointments:", error);
    }
  };

  const filteredAppointments = appointments.filter((appointment) => {
    const uhidMatch = appointment.uhid
      .toLowerCase()
      .includes(uhidAppointFilter.toLowerCase());
    const departmentMatch = appointment.department_name
      .toLowerCase()
      .includes(departmentAppointFilter.toLowerCase());
    let dateMatch = true;

    if (startDateAppointFilter && endDateAppointFilter) {
      const appointmentDate = indFormattedDate(appointment.date);
      const startDate = indFormattedDate(startDateAppointFilter);
      const endDate = indFormattedDate(endDateAppointFilter);

      dateMatch = appointmentDate >= startDate && appointmentDate <= endDate;
    }

    return uhidMatch && departmentMatch && dateMatch;
  });

  const [testCategories, setTestCategories] = useState([]);

  useEffect(() => {
    const fetchLabCategories = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/lab/categories`);
        const dynamicCategories = response.data.map(cat => ({
          title: cat.category,
          onClick: () => handleCategoryClick(cat.categoryCode) // Function to handle category clicks
        }));

        // Include static categories at the end of the dynamic ones
        const staticCategories = [
          { title: 'Prescription', onClick: () => setShowPrescriptionForm(!showPrescriptionForm) },
          { title: 'Admission', onClick: () => setShowAdmissionForm(!showAdmissionForm) }
        ];

        setTestCategories([...dynamicCategories, ...staticCategories]);
      } catch (error) {
        console.error("Error fetching lab categories:", error);
        alert('Failed to fetch lab categories.');
      }
    };

    fetchLabCategories();
  }, []);

  const handleCategoryClick = async (categoryCode) => {
    // Fetch lab tests based on selected category
    await fetchLabTests(categoryCode);
    setShowCardiologyForm(!showCardiologyForm)
  };

  const fetchLabTests = async (category) => {
    try {
      const response = await axios.get(`${config.apiUrl}/lab/availablelabtest/${category}`);
      setLabTests(response.data); // Set the fetched lab tests to state
    } catch (error) {
      console.error("Error fetching lab tests:", error);
      alert('Failed to fetch lab tests.');
    }
  };


  return (
    <div>
      <div className="flex items-center justify-between w-full pb-4">
        {/* Button on the left */}
        <button
          className="flex items-center gap-2 p-2 bg-blue-800 text-white rounded hover:bg-blue-600"
          onClick={() => navigate('/doctor')}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>

        {/* Heading centered */}
        <h1 className="text-2xl font-bold text-green-400 flex-grow text-center">
          Out Patients List's
        </h1>
      </div>
      <div className="flex mb-2">
        <div className="w-1/4 p-4 bg-gradient-to-r from-green-400 to-blue-500">
          <h2 className="text-lg font-semibold mb-4 text-white">
            Today Patients
          </h2>
        </div>
        <div className="w-1/4 p-4 bg-gradient-to-r from-purple-400 to-pink-500">
          <h2 className="text-lg font-semibold mb-4 text-white">
            Today Appointments
          </h2>
        </div>
        <div className="w-1/4 p-4 bg-gradient-to-r from-yellow-400 to-red-500">
          <h2 className="text-lg font-semibold mb-4 text-white">
            Today Operations
          </h2>
        </div>
        <div className="w-1/4 p-4 bg-gradient-to-r from-indigo-400 to-teal-500">
          <h2 className="text-lg font-semibold mb-4 text-white">
            Online Appointments
          </h2>
        </div>
      </div>
      <div className="flex items-center mt-4 mb-2">
        <div className="relative mr-4">
          <FontAwesomeIcon
            icon={faSearch}
            className="absolute text-black-500 h-4 w-4 left-3 top-3"
          />
          <input
            type="text"
            placeholder="Search by UHID or Patient Name"
            className="pl-8 pr-2 py-2 border rounded"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        {parsedUserData.user.user_role === "Admin" ? (
          <div>
            <label htmlFor="departmentsList">Department</label>
            <input
              ref={inputRef}
              list="departmentsList"
              className="p-2 border rounded ml-4"
              placeholder="Select Department"
              onChange={(e) => {
                const selectedValue = e.target.value;
                const isValid = departments.some(
                  (department) => department.department === selectedValue
                );
                if (isValid || !selectedValue) {
                  setSelectedDepartment(selectedValue);
                } else {
                  setSelectedDepartment("");
                }
              }}
              onBlur={() => {
                if (inputRef.current && !inputRef.current.value) {
                  setSelectedDepartment("");
                }
              }}
            />
            <datalist id="departmentsList">
              {departments.map((department) => (
                <option key={department.id} value={department.department} />
              ))}
            </datalist>
          </div>
        ) : (
          ""
        )}

        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-4 flex items-center"
          onClick={() => {
            handleOpenAppointmentModal();
            fetchFutureAppoinment();
          }}
        >
          <FontAwesomeIcon icon={faCalendar} className="mr-2" />
          Appointment
        </button>
      </div>

      <div className="" >
        <div className=" p-2" >
          {showSuccessPopUp && (
            <div className="fixed bottom-4 right-4 bg-green-500 text-white py-2 px-4 rounded shadow">
              Patient visit ✅
            </div>
          )}
          {/* <h2 className="text-lg font-semibold">Recent Patients</h2> */}
          <div className="flex items-center" >
            <h2 className="text-xl mb-2 mr-2">RECENT PATIENT LIST</h2>
            <FontAwesomeIcon
              icon={faSync}
              className="w-6 h-4 text-black-600 cursor-pointer ml-2 mb-2"
              onClick={fetchInitialData}
            />
          </div>
          <table className="w-full">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Date</th>
                <th>UHID</th>
                <th>Patient Name</th>
                <th>Age</th>
                <th>Consultation Doctor</th>
                <th>Department</th>
                <th>Op Reports</th>
                <th>Suggestion</th>
                <th>Test Reports</th>

                {/* <th>Past History</th> */}
              </tr>
            </thead>
            <tbody >
              {Array.isArray(patients) && patients.length > 0 ? (
                patients

                  .filter((patient) => {
                    const searchTerm = searchQuery.toLowerCase();
                    return (
                      patient.uhid.toLowerCase().includes(searchTerm) ||
                      patient.patient_name.toLowerCase().includes(searchTerm)
                    );
                  })
                  .filter(
                    (patient) =>
                      !selectedDepartment ||
                      patient.department === selectedDepartment
                  )
                  .map((patient, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{patient.date}</td>
                      <td>{patient.uhid}</td>
                      <td>{patient.patient_name}</td>
                      <td>{patient.age}</td>
                      <td>{patient.doctor_name}</td>
                      <td>{patient.department}</td>
                      <td>
                        <button
                          onClick={() => handleReportClick(patient)}
                          style={{
                            marginLeft: "30px",
                            fontSize: "25px",
                            color: "blue",
                          }}
                        >
                          <FontAwesomeIcon icon={faFileAlt} />
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={() => handlePatientClick(patient)}
                          className=" bg-yellow-500 text-white py-1 px-2 rounded-md"
                        >
                          Suggestion
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={() => ViewTestResults(patient)}
                          className=" bg-blue-800 text-white py-1 px-2 rounded-md "
                        >
                          View
                        </button>
                      </td>
                      {/* <td>
          <button onClick={() => handlePatientHistory(patient)}>History</button>
          </td> */}
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan="10">No patients available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {showModal && selectedPatient && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
          <div className="bg-gray-100 p-8 shadow-md rounded-md w-3/4 ">
            {showCardiologyForm ? (
              <>
                <style>
                  {`
                   .highlight-input {
                   border: 1px solid red;
                  } 
                `}
                </style>
                <div className="flex justify-end mt-0 ">
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={handleCloseModal}
                    className="text-red-500 cursor-pointer"
                  />
                </div>
                <h2 className="text-2xl font-semibold mb-4 text-blue-700 text-center">
                  {selectedPatient &&
                    `${selectedPatient.patient_name}'s ${labTests[0]?.category || ''} Test Form`}
                </h2>

                <div className="bg-gray-100 p-4 rounded-b-md">
                  <div className="flex justify-start mt-0">
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      onClick={() => {
                        setShowCardiologyForm(false);
                        setEmptyFields([]);
                      }}
                      className="text-red-500 cursor-pointer"
                    />
                  </div>
                </div>

                <CardiologyForm selectedPatient={selectedPatient} labTests={labTests} />

              </>
            ) : showAdmissionForm ? (
              <>
                <style>
                  {`
           .highlight-input {
           border: 1px solid red;
          } 
        `}
                </style>
                <div className="flex justify-end mt-0">
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={handleCloseModal}
                    className="text-red-500 cursor-pointer"
                  />
                </div>
                <h2 className="text-2xl ml-60 font-semibold mb-4 text-blue-700">
                  {selectedPatient && `${selectedPatient.patient_name} - Admission Form`}
                </h2>

                <div className="bg-gray-100 p-4 rounded-b-md">
                  <div className="flex justify-start mt-0">
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      onClick={() => {
                        setShowAdmissionForm(false);
                        setEmptyFields([]);
                      }}
                      className="text-red-500 cursor-pointer"
                    />
                  </div>
                </div>

                <AdmissionForm selectedPatient={selectedPatient} />
              </>
            ) : showPrescriptionForm ? (
              <>
                <style>
                  {`
           .highlight-input {
           border: 1px solid red;
          } 
        `}
                </style>
                <div className="flex justify-end mt-0">
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={handleCloseModal}
                    className="text-red-500 cursor-pointer"
                  />
                </div>
                <h2 className="text-2xl ml-80 font-semibold mb-4 text-blue-700">
                  {selectedPatient &&
                    `${selectedPatient.patient_name}'s Prescription`}
                </h2>

                <div className="bg-gray-100 p-4 rounded-b-md">
                  <div className="flex justify-start mt-0">
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      onClick={() => {
                        setShowPrescriptionForm(false);
                        setEmptyFields([]);
                      }}
                      className="text-red-500 cursor-pointer"
                    />
                  </div>
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th className="text-center">Medicine Name & Dosage</th>
                        <th className="text-center">Brand</th>
                        <th className="text-center">No. of Days</th>
                        <th className="text-center">Instruction</th>
                        <th colSpan="4" className="text-center">
                          Time Schedule
                        </th>
                        <th className="text-center">Action</th>
                      </tr>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th className="text-center">Morning</th>
                        <th className="text-center">Afternoon</th>
                        <th className="text-center">Evening</th>
                        <th className="text-center">Night</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {medicines.map((medicine, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="text"
                              className={`mt-1 p-2 border rounded-md w-full 
                              ${emptyFields.includes(index) &&
                                  medicines[index].name.trim() === ""
                                  ? "highlight-input"
                                  : ""
                                }`}
                              list="medicineNames"
                              placeholder="Enter Medicine Name"
                              value={medicines[index].name}
                              onChange={(e) =>
                                handleInputChange(index, "name", e.target.value)
                              }
                            />
                            <datalist id="medicineNames">
                              {medicineNames.map((suggestion, index) => (
                                <option
                                  key={index}
                                  value={`${suggestion.medicinename} ${suggestion.dosage}`}
                                />
                              ))}
                            </datalist>
                          </td>

                          <td>
                            <input
                              type="text"
                              className={`mt-1 p-2 border rounded-md w-full 
        ${emptyFields.includes(index) &&
                                  medicines[index].brandname.trim() === ""
                                  ? "highlight-input"
                                  : ""
                                }`}
                              list="brandname"
                              placeholder="Enter brand Name"
                              value={medicines[index].brandname || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "brandname",
                                  e.target.value
                                )
                              }
                            />
                            <datalist id="brandname">
                              {brandNames.map((suggestion, index) => (
                                <option
                                  key={index}
                                  value={suggestion.brandname}
                                />
                              ))}
                            </datalist>
                          </td>

                          <td>
                            <input
                              type="text"
                              className={`mt-1 p-2 border rounded-md w-full 
                              ${emptyFields.includes(index) &&
                                  medicines[index].frequency.trim() === ""
                                  ? "highlight-input"
                                  : ""
                                }`}
                              list="Frequency"
                              placeholder="Enter Frequency"
                              value={medicines[index].frequency}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "frequency",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <select
                              className={`mt-1 p-3 border rounded-md w-full 
    ${emptyFields.includes(index) && medicine.instruction.trim() === ""
                                  ? "highlight-input"
                                  : ""
                                }`}
                              style={{ backgroundColor: "white" }}
                              value={medicine.instruction}
                              onChange={(e) => {
                                const updatedMedicines = [...medicines];
                                updatedMedicines[index].instruction =
                                  e.target.value;
                                setMedicines(updatedMedicines);
                              }}
                            >
                              <option value="">Select Instruction</option>
                              <option value="Before meal">Before meal</option>
                              <option value="After meal">After meal</option>
                            </select>
                          </td>

                          <td
                            className="text-center cursor-pointer"
                            onClick={() =>
                              handleCheckboxClick(index, "morning")
                            }
                          >
                            <input
                              type="checkbox"
                              checked={medicine.morning}
                              onChange={(e) => {
                                const updatedMedicines = [...medicines];
                                updatedMedicines[index].morning =
                                  e.target.checked;
                                setMedicines(updatedMedicines);
                              }}
                            />
                          </td>
                          <td
                            className="text-center cursor-pointer"
                            onClick={() =>
                              handleCheckboxClick(index, "afternoon")
                            }
                          >
                            <input
                              type="checkbox"
                              checked={medicine.afternoon}
                              onChange={(e) => {
                                const updatedMedicines = [...medicines];
                                updatedMedicines[index].afternoon =
                                  e.target.checked;
                                setMedicines(updatedMedicines);
                              }}
                            />
                          </td>
                          <td
                            className="text-center cursor-pointer"
                            onClick={() =>
                              handleCheckboxClick(index, "evening")
                            }
                          >
                            <input
                              type="checkbox"
                              checked={medicine.evening}
                              onChange={(e) => {
                                const updatedMedicines = [...medicines];
                                updatedMedicines[index].evening =
                                  e.target.checked;
                                setMedicines(updatedMedicines);
                              }}
                            />
                          </td>
                          <td
                            className="text-center cursor-pointer"
                            onClick={() => handleCheckboxClick(index, "night")}
                          >
                            <input
                              type="checkbox"
                              checked={medicine.night}
                              onChange={(e) => {
                                const updatedMedicines = [...medicines];
                                updatedMedicines[index].night =
                                  e.target.checked;
                                setMedicines(updatedMedicines);
                              }}
                            />
                          </td>

                          <td
                            className="cursor-pointer text-center"
                            onClick={() => handleRemoveMedicine(index)}
                          >
                            <FontAwesomeIcon
                              icon={faTimes}
                              style={{
                                color: "red",
                                fontSize: "1.2rem",
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <button
                    onClick={handleAddMedicine}
                    className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-md"
                  >
                    Add Medicine
                  </button>
                  <br />

                  <button
                    onClick={handleSubmit}
                    disabled={isButtonDisabled}
                    className={`mt-4 bg-green-500 text-white py-2 px-4 rounded-md ${isButtonDisabled ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                  >
                    Submit
                  </button>
                </div>
              </>
            ) : (
              <div className="p-6 bg-gray-600 rounded-md shadow-lg max-w-5xl  max-h-[80vh] overflow-y-auto">
                {/* Close button at the top right */}
                <div className="flex justify-end">
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={handleCloseModal}
                    className="text-red-500 text-xl cursor-pointer"
                  />
                </div>

                {/* Title */}
                <h2 className="text-2xl font-semibold text-cyan-300 text-center my-4">
                  {selectedPatient && `${selectedPatient.patient_name} - Test Suggestion`}
                </h2>

                {/* Display Box with All Available Titles */}
                <div className="p-4 border rounded-md bg-gray-100 mb-6">
                  <h3 className="text-lg font-semibold text-gray-700 mb-2">Available Test Categories:</h3>
                </div>

                {/* Test Categories in Grid Layout */}
                <div className="container mx-auto px-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {testCategories.map((category, index) => (
                      <TestCategory
                        key={index}
                        title={category.title}
                        onClick={category.onClick}
                      />
                    ))}
                  </div>
                </div>

              </div>
            )}
          </div>
        </div>
      )}

      {showModal2 && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
          <div className="bg-gray-100 p-8 shadow-md rounded-md w-3/4">
            <div className="flex justify-end mt-0">
              <FontAwesomeIcon
                icon={faTimes}
                onClick={handleCloseModal}
                className="text-red-500 cursor-pointer"
              />
            </div>
            {patientData ? (
              // Render patient data using PatientDetails component
              <PatientDetails
                patientData={patientData}
                allergyData={patientData.allergyData}
                chronicData={patientData.chronicData}
                treatmentData={patientData.treatmentData}
                medicineData={patientData.medicineData}
                hospitalizationsData={patientData.hospitalizationsData}
                immunizationData={patientData.immunizationData}
                familyHistoryData={patientData.familyHistoryData}
                socialHistoryData={patientData.socialHistoryData}
                pdfsData={patientData.pdfsData}
              />
            ) : (
              <div>Loading...</div> // Show loading indicator while fetching data
            )}
          </div>
        </div>
      )}

      {showModal4 && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
          <div className="bg-gray-100 p-8 shadow-md rounded-md w-3/4">
            <div className="flex justify-end mt-0">
              <FontAwesomeIcon
                icon={faTimes}
                onClick={handleCloseModal}
                className="text-red-500 cursor-pointer"
              />
            </div>
            <TestResults patient={selectedPatient} onClose={handleCloseModal} />
          </div>
        </div>
      )}
      {isAppointmentModalOpen && (
        <div
          className={`fixed inset-0 overflow-y-auto ${isAppointmentModalOpen ? "backdrop-filter backdrop-blur-sm" : ""
            } transition-all duration-300 ${isAppointmentModalOpen
              ? "opacity-100"
              : "opacity-0 pointer-events-none"
            }`}
        >
          <div className="flex items-center justify-center h-screen">
            <div
              className="bg-gray-100 p-4 rounded shadow-lg transition-transform duration-300 transform scale-100 border-2 border-blue-500 max-h-full overflow-auto"
              style={{ top: "5%", height: "80%", width: "90%" }}
            >
              <div className="flex justify-end mb-2">
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={handleCloseAppointmentModal}
                  className="text-red-500 cursor-pointer"
                  size="lg"
                />
              </div>

              <div className="flex justify-between mb-4">
                <div>
                  <h2 className="text-3xl font-bold text-blue-500">
                    Appointment
                  </h2>
                </div>
                <div className="flex items-center space-x-2">
                  <input
                    type="text"
                    placeholder="Search UHID"
                    value={uhidAppointFilter}
                    onChange={handleSearchAppointUhid}
                    className="px-2 py-1 border border-gray-400 rounded"
                  />
                  <input
                    type="text"
                    placeholder="Search Department"
                    value={departmentAppointFilter}
                    onChange={handleSearchAppointDepartment}
                    className="px-2 py-1 border border-gray-400 rounded"
                  />
                  <label>Start Date:</label>
                  <input
                    type="date"
                    value={startDateAppointFilter}
                    onChange={handleStartDateAppointChange}
                    className="px-2 py-1 border border-gray-400 rounded"
                  />
                  <label>End Date:</label>
                  <input
                    type="date"
                    value={endDateAppointFilter}
                    onChange={handleEndDateAppointChange}
                    className="px-2 py-1 border border-gray-400 rounded"
                  />
                </div>
              </div>

              <table className="border-collapse bg-gray-300 border border-gray-500">
                <thead>
                  <tr>
                    <th className="border border-gray-500 text-center px-4 py-2">
                      S.NO
                    </th>
                    <th className="border border-gray-500 text-center px-4 py-2">
                      UHID
                    </th>
                    <th className="border border-gray-500 text-center px-4 py-2">
                      Appointment Date
                    </th>
                    <th className="border border-gray-500 text-center px-4 py-2">
                      Patient Name
                    </th>
                    <th className="border border-gray-500 text-center px-4 py-2">
                      Department
                    </th>
                    <th className="border border-gray-500 text-center px-4 py-2">
                      Doctor Name
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAppointments.map((appointment, index) => (
                    <tr key={index}>
                      <td className="border border-gray-500 px-4 py-2 text-center">
                        {index + 1}
                      </td>
                      <td className="border border-gray-500 px-4 py-2 text-center">
                        {appointment.uhid}
                      </td>
                      <td className="border border-gray-500 px-4 py-2 text-center">
                        {indFormattedDate(appointment.date)}
                      </td>
                      <td className="border border-gray-500 px-4 py-2 text-center">
                        {appointment.patient_name}
                      </td>
                      <td className="border border-gray-500 px-4 py-2 text-center">
                        {appointment.department_name}
                      </td>
                      <td className="border border-gray-500 px-4 py-2 text-center">
                        {appointment.consultation_doctor}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OutpatientsList;