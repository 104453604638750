import React, { useState } from 'react';
import config from '../../config';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const DoctorRegistrationForm = () => {
  // State to hold form data
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    gender: '',
    age: '',
    dob: '',
    specialist: '',
    qualification: '',
    department: '',
    doj: '',
    consultant_fees: '',
    experience: '',
    contact_number: '',
    phone_number: '',
    address: '',
    aadhar_number: '',
    availabilities: [],
  });
  const [showRedBorders, setShowRedBorders] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [newAvailability, setNewAvailability] = useState({
    day_of_week: '',
    morning_start_time: '',
    morning_end_time: '',
    evening_start_time: '',
    evening_end_time: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleChangeDate = (e) => {
    const selectedDate = e.target.value;
    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format

    // Only update state if the selected date is below or equal to the current date
    if (selectedDate <= currentDate) {
      const dob = new Date(selectedDate);
      const ageDiffMilliseconds = Date.now() - dob.getTime();
      const ageDate = new Date(ageDiffMilliseconds);
      const age = Math.abs(ageDate.getUTCFullYear() - 1970);

      // Update the state with DOB and calculated age
      setFormData({
        ...formData,
        dob: selectedDate,
        age: age.toString(),
      });
    } else {
      // You can display an error message or perform other actions if needed
      console.log('Invalid date selected. Please choose a date below or equal to the current date.');
    }
  };


  const handleAddAvailability = () => {
    setIsModalOpen(true);
  };



  const handleTimeChange = (field, e) => {
    const rawTime = e.target.value;
    setNewAvailability({ ...newAvailability, [field]: rawTime });
  };

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(':');
    const hh = parseInt(hours, 10);
    const period = hh >= 12 ? 'PM' : 'AM';
    const hh12 = hh % 12 || 12;
    return `${String(hh12).padStart(2, '0')}:${minutes || '00'} ${period}`;
  };

  // Only run the effect when tableData changes


  const handleBack = () => {
    setIsModalOpen(false);
    setFormData({
      ...formData,
      availabilities: [...tableData],
    });


  };
  console.log("availabilities...", formData.availabilities);

  const handleAddToTable = () => {
    if (newAvailability.day_of_week) {
      if ((newAvailability.morning_start_time && newAvailability.morning_end_time) || (newAvailability.evening_start_time && newAvailability.evening_end_time)) {
        setTableData(prevTableData => [...prevTableData, { ...newAvailability }]);

        console.log("availabilities", newAvailability);


      } else {
        alert('Please provide at least one of morning or evening timings for the selected day of the week.');
      }
    } else {
      alert('Please select the day of the week before adding to the table.');
    }

    setNewAvailability({
      day_of_week: '',
      morning_start_time: '',
      morning_end_time: '',
      evening_start_time: '',
      evening_end_time: '',
    });
  };

  const clearFormFields = () => {
    setFormData({
      name: '',
      gender: '',
      age: '',
      dob: '',
      specialist: '',
      qualification: '',
      department: '',
      doj: '',
      consultant_fees: '',
      experience: '',
      contact_number: '',
      phone_number: '',
      address: '',
      aadhar_number: '',
      availabilities: [],
    });

    setTableData([]);
    setNewAvailability({
      day_of_week: '',
      morning_start_time: '',
      morning_end_time: '',
      evening_start_time: '',
      evening_end_time: '',
    });
  };

  const handleRemoveRow = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };
  console.log("rtables", tableData);


  const handleSubmit = async () => {


    if (!formData.name.trim() || !formData.gender || !formData.age || !formData.specialist.trim() || !formData.qualification.trim() || !formData.department.trim() || !formData.doj || !formData.consultant_fees || !formData.experience || !formData.contact_number || !formData.phone_number || !formData.address || !formData.aadhar_number) {


      setShowRedBorders(true);



      setTimeout(() => {
        setShowRedBorders(false);
      }, 2000);



      return;
    }
    if (tableData.length === 0) {
      alert('Please add at least one availability before submitting the form.');
      return;
    }

    try {
      // Perform any additional validation here if needed
      setIsSubmitting(true);
      // Make the API request
      const response = await axios.post(`${config.apiUrl}/consultation/addDoctor`, formData);

      // Handle success (you can redirect, show a success message, etc.)
      alert('Form submitted successfully!', response.data);
      clearFormFields();
    } catch (error) {
      // Handle errors here (log to console, show an error message, etc.)
      console.error('Error submitting form:', error);

      // Optionally, you can also log the response from the server if available
      if (error.response) {
        console.error('Server response:', error.response.data);
      }
    } finally {
      // Set isSubmitting back to false after the form submission is complete
      setIsSubmitting(false);
    }
  };





  // Function to handle form submission


  return (

    <div className=" p-4 ">
        <div className="flex items-center justify-between w-full pb-4">
        <button
          className="flex items-center gap-2 p-2 bg-blue-800 text-white rounded hover:bg-blue-600"
          onClick={() => navigate('/hr')}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>

        <h1 className="text-2xl font-bold text-green-400 flex-grow text-center">
        Doctor Registration
        </h1>
      </div>
      {/* <h2 className="text-2xl font-bold mb-4 text-white"></h2> */}
      {/* <div className="flex justify-center items-center min-h-screen bg-gray-100"> */}
   
      <form className="space-y- overflow-y-auto h-[70vh] p-10">
        <div className="grid grid-cols-3 gap-4 ">

          <div className="flex flex-col ">
            <label htmlFor="name" className="block mb-1">Name</label>
            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} className={`border ${showRedBorders && !formData.name.trim() ? 'border-red-500' : 'border-gray-300'} px-4 py-2 rounded-md w-full required`} />
          </div>
          {/* Gender */}
          <div className="flex flex-col">
            <label htmlFor="gender" className="block mb-1">Gender</label>
            <select id="gender" name="gender" value={formData.gender} onChange={handleChange} className={`border ${showRedBorders && !formData.gender.trim() ? 'border-red-500' : 'border-gray-300'} px-4 py-2 rounded-md w-full`}>
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="col-span-1 flex items-center justify-center -mt-9">
            <div className="w-32 h-32 bg-gray-200 rounded-full flex items-center justify-center">
              Profile Picture
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-4">
          <div className="mb-4">
            <label htmlFor="dob" className="block mb-1">Date of Birth</label>
            <input type="date" id="dob" name="dob" value={formData.dob} onChange={handleChangeDate} className={`border ${showRedBorders && !formData.dob.trim() ? 'border-red-500' : 'border-gray-300'} px-4 py-2 rounded-md w-full`} />
          </div>

          <div className="mb-4">
            <label htmlFor="age" className="block mb-1">Age</label>
            <input type="number" id="age" name="age" value={formData.age} onChange={handleChange} className={`border ${showRedBorders && !formData.age.trim() ? 'border-red-500' : 'border-gray-300'} px-4 py-2 mt-1 rounded-md w-full`} />
          </div>

          <div className="mb-4">
            <label htmlFor="specialist" className="block mb-1">Specialist</label>
            <input type="text" id="specialist" name="specialist" value={formData.specialist} onChange={handleChange} className={`border ${showRedBorders && !formData.specialist.trim() ? 'border-red-500' : 'border-gray-300'} px-4 py-2 rounded-md w-full`} />
          </div>
        </div>


        <div className="grid grid-cols-3 gap-4">
          {/* Specialist */}

          {/* Qualification */}
          <div className="mb-4">
            <label htmlFor="qualification" className="block mb-1">Qualification</label>
            <input type="text" id="qualification" name="qualification" value={formData.qualification} onChange={handleChange} className={`border ${showRedBorders && !formData.qualification.trim() ? 'border-red-500' : 'border-gray-300'} px-4 py-2 rounded-md w-full`} />
          </div>
          <div className="mb-4">
            <label htmlFor="department" className="block mb-1">Department</label>
            <input type="text" id="department" name="department" value={formData.department} onChange={handleChange} className={`border ${showRedBorders && !formData.department.trim() ? 'border-red-500' : 'border-gray-300'} px-4 py-2 rounded-md w-full`} />
          </div>
          {/* Aadhar Number */}
          <div className="mb-4">
            <label htmlFor="aadhar_number" className="block mb-1">Aadhar Number</label>
            <input type="text" id="aadhar_number" pattern="[0-9]{12}"
              maxLength="12" name="aadhar_number" value={formData.aadhar_number} onChange={handleChange} className={`border ${showRedBorders && !formData.aadhar_number.trim() ? 'border-red-500' : 'border-gray-300'} px-4 py-2 rounded-md w-full`} />
          </div>
        </div>
        {/* Department */}
        <div className="grid grid-cols-3 gap-4">


          {/* Date of Joining */}
          <div className="mb-4">
            <label htmlFor="doj" className="block mb-1">Date of Joining</label>
            <input type="date" id="doj" name="doj" value={formData.doj} onChange={handleChange} className={`border ${showRedBorders && !formData.aadhar_number.trim() ? 'border-red-500' : 'border-gray-300'} px-4 py-2 rounded-md w-full`} />
          </div>
          {/* Consultant Fees */}
          <div className="mb-4">
            <label htmlFor="consultant_fees" className="block mb-1">Consultant Fees</label>
            <input type="number" id="consultant_fees" name="consultant_fees" value={formData.consultant_fees} onChange={handleChange} className={`border ${showRedBorders && !formData.consultant_fees.trim() ? 'border-red-500' : 'border-gray-300'} px-4 py-2 rounded-md w-full`} />
          </div>
          <div className="mb-4">
            <label htmlFor="experience" className="block mb-1">Experience</label>
            <input type="text" id="experience" name="experience" value={formData.experience} onChange={handleChange} className={`border ${showRedBorders && !formData.aadhar_number.trim() ? 'border-red-500' : 'border-gray-300'} px-4 py-2 rounded-md w-full`} />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4">

          {/* Experience */}

          {/* Contact Number */}
          <div className="mb-4">
            <label htmlFor="contact_number" className="block mb-1">Contact Number</label>
            <input type="tel" id="contact_number" pattern="[0-9]{10}"
              maxLength="10" name="contact_number" value={formData.contact_number} onChange={handleChange} className={`border ${showRedBorders && !formData.contact_number.trim() ? 'border-red-500' : 'border-gray-300'} px-4 py-2 rounded-md w-full`} />
          </div>
          {/* Phone Number */}
          <div className="mb-4">
            <label htmlFor="phone_number" className="block mb-1">Phone Number</label>
            <input type="tel" id="phone_number" pattern="[0-9]{10}"
              maxLength="10" name="phone_number" value={formData.phone_number} onChange={handleChange} className={`border  ${showRedBorders && !formData.phone_number.trim() ? 'border-red-500' : 'border-gray-300'} px-4 py-2 rounded-md w-full`} />
          </div>
          {/* Address */}
          <div className="mb-4">
            <label htmlFor="address" className="block mb-1">Address</label>
            <input type="text" id="address" name="address" value={formData.address} onChange={handleChange} className={`border ${showRedBorders && !formData.phone_number.trim() ? 'border-red-500' : 'border-gray-300'} px-4 py-2 rounded-md w-full`} />
          </div>
        </div>


        <button type="button" onClick={handleAddAvailability} >
          Add Availability
        </button><div className="flex justify-end mt-4 mr-4">
          <button
            type="button"
            className="bg-white text-violet text-xl rounded-md px-4 py-1 mb-7" disabled={isSubmitting}
            onClick={handleSubmit}>  {isSubmitting ? 'Submitting...' : 'Submit'}</button>
        </div>



        {isModalOpen && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center w-full">
            <div className="bg-white p-8 rounded-md w-4/5">
              <button type="button" onClick={handleBack} className="bg-red-500 text-white px-4 py-1 float-right rounded-md mr-2">
                Back
              </button>
              <h2 className="text-xl font-bold mb-4">Add Availability</h2>
              <div className="grid grid-cols-6 gap-4">
                {/* Day of Week */}
                <div className="flex flex-col">
                  <label htmlFor="day_of_week" className="block mb-1">
                    Day of Week
                  </label>
                  <select
                    id="day_of_week"
                    name="day_of_week"
                    value={newAvailability.day_of_week}
                    onChange={(e) => setNewAvailability({ ...newAvailability, day_of_week: e.target.value })}
                    className="border border-gray-300 px-4 py-2 rounded-md w-full"
                  >
                    <option value="" disabled>Select Day</option>
                    <option value="Monday">Monday</option>
                    <option value="Tuesday">Tuesday</option>
                    <option value="Wednesday">Wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                    <option value="Saturday">Saturday</option>
                    <option value="Sunday">Sunday</option>
                  </select>
                </div>


                {/* Morning Start Time */}
                <div className="flex flex-col">
                  <label htmlFor="morning_start_time" className="block mb-1">
                    Morning Start Time
                  </label>
                  <input
                    type="time"
                    id="morning_start_time"
                    name="morning_start_time"
                    value={newAvailability.morning_start_time}
                    onChange={(e) => handleTimeChange('morning_start_time', e)}
                    className="border border-gray-300 px-4 py-2 rounded-md w-full"
                  />

                </div>
                <div className="flex flex-col">
                  <label htmlFor="morning_end_time" className="block mb-1">
                    Morning End Time
                  </label>
                  <input
                    type="time"
                    id="morning_end_time"
                    name="morning_end_time"
                    value={newAvailability.morning_end_time}
                    onChange={(e) => handleTimeChange('morning_end_time', e)}
                    className="border border-gray-300 px-4 py-2 rounded-md w-full"
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="evening_start_time" className="block mb-1">
                    Evening Start Time
                  </label>
                  <input
                    type="time"
                    id="evening_start_time"
                    name="evening_start_time"
                    value={newAvailability.evening_start_time}
                    onChange={(e) => handleTimeChange('evening_start_time', e)}
                    className="border border-gray-300 px-4 py-2 rounded-md w-full"
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="evening_end_time" className="block mb-1">
                    Evening End Time
                  </label>
                  <input
                    type="time"
                    id="evening_end_time"
                    name="evening_end_time"
                    value={newAvailability.evening_end_time}
                    onChange={(e) => handleTimeChange('evening_end_time', e)}
                    className="border border-gray-300 px-4 py-2 rounded-md w-full"
                  />
                </div>



                <button
                  type="button"
                  onClick={handleAddToTable}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md mt-7 cursor-pointer"
                >
                  Add
                </button>

              </div>
              <table>
                <thead>
                  <tr>
                    <th>Day of Week</th>
                    <th>Morning Start Time</th>
                    <th>Morning End Time</th>
                    <th>Evening Start Time</th>
                    <th>Evening End Time</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {tableData.map((data, index) => (
                    <tr key={index}>
                      <td>{data.day_of_week}</td>
                      <td>{data.morning_start_time}</td>
                      <td>{data.morning_end_time}</td>
                      <td>{data.evening_start_time}</td>
                      <td>{data.evening_end_time}</td>

                      <td>
                        <button onClick={() => handleRemoveRow(index)} className="text-red-500 ml-5">
                          &#10006; {/* Unicode for red cross ("X") */}
                        </button>
                      </td>

                    </tr>

                  ))}
                </tbody>
              </table>
              <br />


            </div>

          </div>
        )}
      </form>
    </div>

  );
};

export default DoctorRegistrationForm;
