import React, { useState, useEffect} from "react";

function PatientDetails({
  patientData,
  chronicData,
  treatmentData,
  medicineData,
  hospitalizationsData,
  immunizationData,
  familyHistoryData,
  socialHistoryData,
  pdfsData,
}) {

  

  return (
    <div>
      <h2 className="text-2xl ml-80 font-semibold mb-4 text-blue-700 ">
        {patientData.patient_name}'s Previous Reports
      </h2>
      <div style={{ height: "500px", overflow: "auto" }}>
        <table>
          <tbody>
            {patientData.height_cm && (
              <tr>
                <td>
                  {" "}
                  <b>Height (cm):</b>
                </td>
                <td>{patientData.height_cm}</td>
                {patientData.weight_kg && (
                  <>
                    <td>
                      <b>Weight (kg):</b>
                    </td>
                    <td>{patientData.weight_kg}</td>
                  </>
                )}
              </tr>
            )}

            {patientData.bp_mmhg && (
              <tr>
                <td>
                  <b>Blood Pressure (mmHg):</b>
                </td>
                <td>{patientData.bp_mmhg}</td>
                {patientData.temperature_celsius && (
                  <>
                    <td>
                      <b>Temperature (°C):</b>
                    </td>
                    <td>{patientData.temperature_celsius}</td>
                  </>
                )}
              </tr>
            )}

            {patientData.current_problem && (
              <tr>
                <td>Current Problem:</td>
                <td>{patientData.current_problem}</td>
                {patientData.allergyData &&
                  patientData.allergyData.length > 0 && (
                    <>
                      <td>Allergies:</td>
                      <td>
                        <ul>
                          {patientData.allergyData.map((allergy, index) => (
                            <li key={index}>
                              {allergy.allergy_type}{" "}
                              {allergy.allergy_description}
                            </li>
                          ))}
                        </ul>
                      </td>
                    </>
                  )}
              </tr>
            )}

            {chronicData && chronicData.length > 0 && (
              <tr>
                <td>Chronic Data:</td>
                <td>
                  <ul>
                    {chronicData.map((chronic, index) => (
                      <li key={index}>{chronic.Chronic_description}</li>
                    ))}
                  </ul>
                </td>
                {treatmentData && treatmentData.length > 0 && (
                  <>
                    <td>Treatment:</td>
                    <td>
                      <ul>
                        {treatmentData.map((treatment, index) => (
                          <li key={index}>{treatment.treatment_description}</li>
                        ))}
                      </ul>
                    </td>
                  </>
                )}
              </tr>
            )}

            {medicineData && medicineData.length > 0 && (
              <tr>
                <td>Medicine:</td>
                <td>
                  <ul>
                    {medicineData.map((medicine, index) => (
                      <li key={index}>
                        {medicine.medicine_name}
                        {medicine.dosage}
                      </li>
                    ))}
                  </ul>
                </td>
                {hospitalizationsData && hospitalizationsData.length > 0 && (
                  <>
                    <td>hospitalizationsData:</td>
                    <td>
                      <ul>
                        {hospitalizationsData.map((hospitalizations, index) => (
                          <li key={index}>
                            {hospitalizations.hopitalizations_description}
                          </li>
                        ))}
                      </ul>
                    </td>
                  </>
                )}
              </tr>
            )}

            {immunizationData && immunizationData.length > 0 && (
              <tr>
                <td>immunization:</td>
                <td>
                  <ul>
                    {immunizationData.map((immunization, index) => (
                      <li key={index}>{immunization.immunization_type}</li>
                    ))}
                  </ul>
                </td>
                {familyHistoryData && familyHistoryData.length > 0 && (
                  <>
                    <td>FamilyHistory:</td>
                    <td>
                      <ul>
                        {familyHistoryData.map((familyHistory, index) => (
                          <li key={index}>
                            {familyHistory.family_history_type}
                          </li>
                        ))}
                      </ul>
                    </td>
                  </>
                )}
              </tr>
            )}

            {socialHistoryData && socialHistoryData.length > 0 && (
              <tr>
                <td>socialHistoryData:</td>
                <td>
                  <ul>
                    {socialHistoryData.map((socialHistory, index) => (
                      <li key={index}>{socialHistory.social_History_type}</li>
                    ))}
                  </ul>
                </td>
                {patientData.plan_of_care && (
                  <>
                    <td>
                      <b>plan_of_care</b>
                    </td>
                    <td>{patientData.plan_of_care}</td>
                  </>
                )}
              </tr>
            )}

           
          </tbody>
        </table>
      </div>

      
    </div>
  );
}

export default PatientDetails;