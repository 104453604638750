import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getISOWeek, format } from 'date-fns';

const Opbarchart = ({ data }) => {
  
  const [overallChartData, setOverallChartData] = useState([]);
  const [monthlyChartData, setMonthlyChartData] = useState([]);
  const [dailyChartData, setDailyChartData] = useState([]);

  // Helper function to group data based on time intervals (date and day)
  const groupDataByTime = (data, interval) => {
    return data.reduce((acc, item) => {
      const timeKey = getTimeKey(item.date, interval);
      if (!acc[timeKey]) {
        acc[timeKey] = [];
      }
      acc[timeKey].push(item);
      return acc;
    }, {});
  };

  const getAllDaysOfWeek = () => {
    const daysOfWeek = [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday','Sunday'];
    // Keep the order as is
    return daysOfWeek;
  };

  // Helper function to group data based on date and day
  const groupDataByTimeAndDay = (data) => {
    const allDaysOfWeek = getAllDaysOfWeek();

    const groupedData = data.reduce((acc, item) => {
      const date = new Date(item.date);
      const day = date.toLocaleDateString('en-US', { weekday: 'long' });

      if (!acc[day]) {
        acc[day] = [];
      }
      acc[day].push(item);

      return acc;
    }, {});

    // Fill in missing days with empty array
    allDaysOfWeek.forEach((day) => {
      if (!groupedData[day]) {
        groupedData[day] = [];
      }
    });

    return groupedData;
  };

  // Helper function to get time key based on the interval
  const getTimeKey = (dateString, interval) => {
    const date = new Date(dateString);
    
    if (interval === 'week') {
      return `${date.getFullYear()}-W${getISOWeek(date)}`;
    } else if (interval === 'month') {
      const monthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
      return `${date.getFullYear()}-${date.getMonth() + 1} ${monthName}`;
    } else if (interval === 'day') {
      // Use toISOString to ensure a consistent format matching your data
      return date.toISOString().split('T')[0];
    }
    
    return dateString;
  };

  const processDataForTimeIntervals = (interval, data) => {
    const groupedData = interval === 'day' ? groupDataByTimeAndDay(data) : groupDataByTime(data, interval);
  
    if (interval === 'day') {
      const currentDate = new Date();
      const currentWeekStart = new Date(currentDate); // Clone the current date to avoid mutating it
      currentWeekStart.setDate(currentWeekStart.getDate() - currentDate.getDay() + 1); // Set to Monday of the current week
      const currentWeekEnd = new Date(currentWeekStart);
      currentWeekEnd.setDate(currentWeekEnd.getDate() + 6); // Sunday of the current week
  
      const currentWeekData = data.filter(item => {
        const date = new Date(item.date);
        // Compare the date without considering the time (only compare year, month, and day)
        return date >= currentWeekStart && date <= currentWeekEnd;
      });
  
      const counts = Array(7).fill(0); // Initialize counts array for each day of the week
      currentWeekData.forEach(item => {
        const dayIndex = new Date(item.date).getDay(); // Get the day index (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
        counts[dayIndex]++; // Increment count for the corresponding day
      });
  
      const labels = getAllDaysOfWeek(); // Get all days of the week
      const rotatedCounts = [...counts.slice(1), counts[0]]; // Rotate the counts array to start with Monday
      return labels.map((label, index) => ({
        name: label,
        count: rotatedCounts[index],
      }));
    }
    else if (interval === 'month') {
      // Process data for monthly interval
      const labels = [];
      const counts = [];
  
      // Iterate through the grouped data and calculate counts for each month
      Object.keys(groupedData)
        .sort((a, b) => new Date(a) - new Date(b)) // Sort the keys (months) chronologically
        .forEach((key) => {
          labels.push(key);
          counts.push(groupedData[key].length);
        });
  
      return labels.map((label, index) => ({
        name: label,
        count: counts[index],
      }));
     } else {
      // For other intervals, keep the existing logic
      const labels = [];
      const counts = [];
      Object.keys(groupedData)
        .sort((a, b) => getAllDaysOfWeek().indexOf(a) - getAllDaysOfWeek().indexOf(b))
        .forEach((key) => {
          labels.push(key);
          counts.push(groupedData[key].length);
        });
  
      // Ensure labels and counts are aligned correctly
      return labels.map((label, index) => ({
        name: label,
        count: counts[getAllDaysOfWeek().indexOf(label)], // Get the count based on the index of the label
      }));
    }
  };
  
  
  
  
  
  
  



useEffect(() => {
  if (!Array.isArray(data) || data.length === 0) {
    console.error('Invalid or empty data received:', data);
    return;
  }

  // Overall Count
  const overallData = [
    { name: 'Last Year', count: 0, color: 'rgba(75,192,192,0.6)' },
    { name: 'This Year', count: 0, color: 'rgba(75,192,192,0.6)' },
  ];

  // Process overall data
  data.forEach((item) => {
    const date = new Date(item.date);
    const currentYear = new Date().getFullYear();

    if (date.getFullYear() === currentYear - 1) {
      // Last year count
      overallData[0].count += 1;
    } else if (date.getFullYear() === currentYear) {
      // This year count
      overallData[1].count += 1;
    }
  });

  setOverallChartData(overallData);

  // Monthly Chart
  const monthlyData = processDataForTimeIntervals('month', data);
  setMonthlyChartData(monthlyData);

  // Daily Chart
  const dailyData = processDataForTimeIntervals('day', data);

  console.log('dailyData:', dailyData); // Add this log to check the dailyData

  setDailyChartData(dailyData);
}, [data]);


  return (
    <div>
    <div className="flex justify-between space-x-4">
      <div className="w-1/3">
        <h3>Overall Count</h3>
        <ResponsiveContainer width="100%" height={200}>
          <BarChart data={overallChartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />            
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="count" fill="rgba(75,192,192,0.6)" barSize={60}/>
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="w-3/6">
  <h3>Daily Count</h3>
  <ResponsiveContainer width="100%" height={250}>
  <BarChart data={dailyChartData}>
  <CartesianGrid strokeDasharray="3 3" />
  <XAxis dataKey="name" tick={{ fontSize: 12 }}label={{fontSize: 12 }} />
  <YAxis label={{  angle: -90, position: 'insideLeft', fontSize: 12 }} />
  <Tooltip />
  <Legend />
  <Bar dataKey="count" fill="rgba(245,158,11,0.6)" barSize={30}/>
</BarChart>

  </ResponsiveContainer>
</div>


      
    </div>
    <div className="flex justify-between space-x-4" ><div className="w-1/3">
    <h3>Monthly Count</h3>
    <ResponsiveContainer width="100%" height={200}>
      <BarChart data={monthlyChartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tickFormatter={(value) => value.split(' ')[1]} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="count" fill="rgba(120,156,225,0.6)" barSize={50 } />        
          </BarChart>
    </ResponsiveContainer>
  </div></div>
  </div>
  );
};

export default Opbarchart;