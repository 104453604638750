import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import config from '../../config';

const LabInventory = () => {
  const [labTest, setLabTest] = useState({
    testName: '',
    category: '',
    price: '',
    categoryCode: '',
  });

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Fetch categories from API
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/lab/categories`);
      const options = response.data.map(category => ({
        value: category.category,
        label: category.category,
        code: category.categoryCode, // Include categoryCode in options
      }));
      setCategoryOptions(options);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setErrorMessage('Failed to load categories. Please try again later.');
    }
  };

  useEffect(() => {
    fetchCategories(); // Fetch categories on component mount
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLabTest({ ...labTest, [name]: value });
  };

  const handleCategoryChange = (selectedOption) => {
    setLabTest({ ...labTest, category: selectedOption.value, categoryCode: selectedOption.code });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage(''); // Clear previous error messages

    // Validate price input
    if (labTest.price <= 0) {
      setErrorMessage('Price must be a positive number.');
      setLoading(false);
      return;
    }

    try {
      await axios.post(`${config.apiUrl}/lab/lab-tests`, labTest);
      alert('Lab Test added successfully!');
      setLabTest({ testName: '', category: '', price: '', categoryCode: '' });
    } catch (error) {
      console.error('Error adding lab test:', error);
      setErrorMessage(error.response?.data?.message || 'Failed to add lab test');
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <div className="container mx-auto px-4 mt-10">
      <h1 className="text-3xl font-bold text-center text-blue-800 mb-8">Add New Lab Test</h1>

      {errorMessage && <div className="text-red-500 text-center mb-4">{errorMessage}</div>}

      <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-white p-6 rounded-lg shadow-lg">
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Test Name</label>
          <input
            type="text"
            name="testName"
            value={labTest.testName}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Category</label>
          <Select
            options={categoryOptions}
            onChange={handleCategoryChange}
            className="w-full"
            placeholder="Select Category"
          />
        </div>

        {/* Display categoryCode input if a category is selected */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Category Code</label>
          <input
            type="text"
            name="categoryCode"
            value={labTest.categoryCode}
            readOnly // Make it read-only as it should be auto-filled
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Price</label>
          <input
            type="number"
            name="price"
            value={labTest.price}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <button
          type="submit"
          className={`w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={loading} // Disable button while loading
        >
          {loading ? 'Adding...' : 'Add Lab Test'}
        </button>
      </form>
    </div>
  );
};

export default LabInventory;
