import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUserPlus,
  faMoneyBill,
  faHospitalUser,
  faUserMd,
  faUsersCog,
  faPills,
  faShoppingCart, faFileInvoice,faDollarSign, faFlask  
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom"; // Import useLocation for routing
import "../../index.css";

const Icon = ({ icon, title, onClick, isExpanded, expandSidebar, isActive, theme, color }) => (
  <div
    className={`p-2 flex items-center cursor-pointer mt-4 rounded-lg mx-1 transition-all duration-300
      ${isActive ? "bg-[#2ecc71]" : "hover:bg-[#EE82EE]"} ${theme === "dark" ? "" : "border-black"}`}
    onClick={() => {
      expandSidebar(); // Automatically expand the sidebar when an icon is clicked
      onClick(); // Call the original click handler
    }}
  >
    <FontAwesomeIcon icon={icon} className="w-6 h-6" style={{ color }} />
    {isExpanded && <span className="ml-3 text-sm font-medium">{title}</span>}
  </div>
);

const Sidebar = ({ handleNavigation, width, setSidebarWidth, style, theme }) => {
  const [activeItem, setActiveItem] = useState(null); // State to track the active item
  const location = useLocation(); // Get the current location

  const expandSidebar = () => {
    if (width === "50px") {
      setSidebarWidth("230px"); // Expand the sidebar if collapsed
    }
  };

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  // useEffect(() => {
  //   // Reset the active item to "Dashboard" when the path is the dashboard
  //   if (location.pathname === "/dashboard") {
  //     setActiveItem("Dashboard");
  //   } else {
  //     // Set the active item based on the current path
  //     switch (location.pathname) {
  //       case "/front-office":
  //         setActiveItem("Front Office");
  //         break;
  //       case "/billing":
  //         setActiveItem("Accounts & Revenue");
  //         break;
  //       case "/nurse-station":
  //         setActiveItem("Nurse Station");
  //         break;
  //       case "/doctor":
  //         setActiveItem("Doctor Management");
  //         break;
  //       case "/hr":
  //         setActiveItem("HR Management");
  //         break;
  //       case "/pharmacy":
  //         setActiveItem("Pharmacy Management");
  //         break;
  //       case "/purchase":
  //         setActiveItem("Purchase Management");
  //         break;
  //       default:
  //         setActiveItem(null); // Reset if none match
  //         break;
  //     }
  //   }
  // }, [location.pathname]); // Run this effect whenever the path changes


  useEffect(() => {
    // Check if the pathname starts with "/front-office"
    if (location.pathname.startsWith("/front-office")) {
      setActiveItem("Front Office");
    } else if (location.pathname.startsWith("/billing")) {
      setActiveItem("Accounts & Revenue");
    } else if (location.pathname.startsWith("/nurse-station")) {
      setActiveItem("Nurse Station");
    } else if (location.pathname.startsWith("/doctor")) {
      setActiveItem("Doctor Management");
    } else if (location.pathname.startsWith("/hr")) {
      setActiveItem("HR Management");
    } else if (location.pathname.startsWith("/pharmacy")) {
      setActiveItem("Pharmacy Management");
    } else if (location.pathname.startsWith("/purchase")) {
      setActiveItem("Purchase Management");
    } else {
      setActiveItem(null);
    }
  }, [location.pathname]);


  return (
    <div
      className={`flex flex-col bg-gray-900 shadow-md h-full rounded text-gray-200`}
      style={{ ...style, width }}
    >
      <Icon
        icon={faHome}
        title="Dashboard"
        onClick={() => {
          handleNavigation("/dashboard");
          handleItemClick("Dashboard");
        }}
        theme={theme}
        isExpanded={width === "230px"}
        expandSidebar={expandSidebar}
        isActive={activeItem === "Dashboard"}
        color="#1E90FF" // Dodger Blue
      />

      <Icon
        icon={faUserPlus}
        title="Front Office"
        onClick={() => {
          handleNavigation("/front-office");
          handleItemClick("Front Office");
        }}
        isExpanded={width === "230px"}
        expandSidebar={expandSidebar}
        isActive={activeItem === "Front Office"}
        theme={theme}
        color="#e67e22" // Color for Front Office
      />

      <Icon
        icon={faDollarSign}
        title="Accounts & Revenue"
        onClick={() => {
          handleNavigation("/billing");
          handleItemClick("Accounts & Revenue");
        }}
        isExpanded={width === "230px"}
        expandSidebar={expandSidebar}
        isActive={activeItem === "Accounts & Revenue"}
        color="#f1c40f" // Color for Accounts & Revenue
      />

      <Icon
        icon={faHospitalUser}
        title="Nurse Station"
        onClick={() => {
          handleNavigation("/nurse-station");
          handleItemClick("Nurse Station");
        }}
        isExpanded={width === "230px"}
        expandSidebar={expandSidebar}
        isActive={activeItem === "Nurse Station"}
        color="#9b00b6" // Color for Nurse Station
      />

      <Icon
        icon={faUserMd}
        title="Doctor Management"
        onClick={() => {
          handleNavigation("/doctor");
          handleItemClick("Doctor Management");
        }}
        isExpanded={width === "230px"}
        expandSidebar={expandSidebar}
        isActive={activeItem === "Doctor Management"}
        color="#006400" // Color for Doctor Management
      />

      <Icon
        icon={faUsersCog}
        title="HR Management"
        onClick={() => {
          handleNavigation("/hr");
          handleItemClick("HR Management");
        }}
        isExpanded={width === "230px"}
        expandSidebar={expandSidebar}
        isActive={activeItem === "HR Management"}
        color="#e74c3c" // Color for HR Management
      />

      <Icon
        icon={faPills}
        title="Pharmacy Management"
        onClick={() => {
          handleNavigation("/pharmacy");
          handleItemClick("Pharmacy Management");
        }}
        isExpanded={width === "230px"}
        expandSidebar={expandSidebar}
        isActive={activeItem === "Pharmacy Management"}
        color="#FF69B4" // Color for Pharmacy Management
      />

      <Icon
        icon={faShoppingCart}
        title="Purchase Management"
        onClick={() => {
          handleNavigation("/purchase");
          handleItemClick("Purchase Management");
        }}
        isExpanded={width === "230px"}
        expandSidebar={expandSidebar}
        isActive={activeItem === "Purchase Management"}
        color="#d35400" // Color for Purchase Management
      />
      <Icon
        icon={faFlask}
        title="Laboratory Management"
        onClick={() => {
          handleNavigation("/lab");
          handleItemClick("Laboratory Management");
        }}
        isExpanded={width === "230px"}
        expandSidebar={expandSidebar}
        isActive={activeItem === "Laboratory Management"}
        color="#1E00FF" // Color for Purchase Management
      />
    </div>
  );
};

export default Sidebar;
