import React from 'react';
import { useNavigate } from 'react-router-dom';
import Registration from "../../Assets/op bill.jpg";
import LabBilling from "../../Assets/labbill.jpg";
import Radiology from "../../Assets/radiobill.jpg";
import Cardiology from "../../Assets/cardiobill.jpg";
import Pharmacy from "../../Assets/pharmacybill.jpg";
import billhis from "../../Assets/bill-history.jpg"

const Button = ({ onClick, title }) => (
  <button className="animated-button" onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" className="arr-2" viewBox="0 0 24 24">
      <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
    </svg>
    <span className="text">{title}</span>
    <span className="circle"></span>
    <svg xmlns="http://www.w3.org/2000/svg" className="arr-1" viewBox="0 0 24 24">
      <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
    </svg>
  </button>
);

const FrontOffice = () => {
  const navigate = useNavigate();
  const [hovered, setHovered] = React.useState(null);
  const handleMouseEnter = (index) => setHovered(index);
  const handleMouseLeave = () => setHovered(null);

  const cardData = [
    { title: 'OP Register Bill', path: '/billing/registration', imgSrc: Registration },
    { title: 'Lab Bill', path: '/billing/LabBilling', imgSrc: LabBilling },
    { title: 'Radiology Bill', path: '/billing/radiology', imgSrc: Radiology },
    { title: 'Cardiology Bill', path: '/billing/cardiology', imgSrc: Cardiology },
    // { title: 'Pharmacy Bill', path: '/billing', imgSrc: Pharmacy },
    { title: 'OP Billing History', path: '/billing/billhistory', imgSrc: billhis },

  ];
  
  const handleCardClick = (path) => {
    navigate(path);
  };

  return (
    <div className="container mx-auto px-4">
      {/* Page Title */}
      <h1 className="text-2xl font-bold text-center mt-8 mb-6 text-green-500">Accounts & Revenue Management</h1>

      {/* Cards Section */}
      <div className="flex flex-wrap justify-center gap-6">
        {cardData.map((card, index) => (
          <div
            key={index}
            className={`relative w-80 h-[250px] bg-base-100 border rounded-lg shadow-xl overflow-hidden cursor-pointer  bg-gray-300
            transition-transform duration-200 ${hovered === index ? 'scale-105' : ''}`}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <figure className="h-2/3">
              <img
                src={card.imgSrc}
                alt={card.title}
                className="w-full h-full object-cover"
              />
            </figure>
            <div className="card-body h-1/3 flex flex-col items-center justify-center">
              {/* <h2 className="text-lg font-semibold mb-2">{card.title}</h2> */}
              <div className="mb-4">
                <Button onClick={() => handleCardClick(card.path)} title={card.title} />
              </div>
              <p className="text-gray-600 ">{card.content}</p>

            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FrontOffice;
