import React, { useState, useEffect } from 'react';
import config from '../../config';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const CBCform = ({ setSelectedReport, selectedPatient }) => {
  // State variables for each input field
  const [hemoglobinValue, setHemoglobinValue] = useState('');
  const [rbcCountValue, setRbcCountValue] = useState('');
  const [wbcCountValue, setWbcCountValue] = useState('');
  const [plateletCountValue, setPlateletCountValue] = useState('');
  const [hematocritValue, setHematocritValue] = useState('');
  const [mcvValue, setMcvValue] = useState('');
  const [mchValue, setMchValue] = useState('');
  const [mchcValue, setMchcValue] = useState('');
  const [neutrophilsValue, setNeutrophilsValue] = useState('');
  const [lymphocytesValue, setLymphocytesValue] = useState('');
  const [monocytesValue, setMonocytesValue] = useState('');
  const [eosinophilsValue, setEosinophilsValue] = useState('');
  const [basophilsValue, setBasophilsValue] = useState('');

  // State variable to track errors
  const [errors, setErrors] = useState({});

  const validateInputs = () => {
    const newErrors = {};
    // Check for empty fields
    if (!hemoglobinValue) newErrors.hemoglobin = true;
    if (!rbcCountValue) newErrors.rbcCount = true;
    if (!wbcCountValue) newErrors.wbcCount = true;
    if (!plateletCountValue) newErrors.plateletCount = true;
    if (!hematocritValue) newErrors.hematocrit = true;
    if (!mcvValue) newErrors.mcv = true;
    if (!mchValue) newErrors.mch = true;
    if (!mchcValue) newErrors.mchc = true;
    if (!neutrophilsValue) newErrors.neutrophils = true;
    if (!lymphocytesValue) newErrors.lymphocytes = true;
    if (!monocytesValue) newErrors.monocytes = true;
    if (!eosinophilsValue) newErrors.eosinophils = true;
    if (!basophilsValue) newErrors.basophils = true;

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleSubmit = async () => {
    if (!validateInputs()) return; // Stop submission if validation fails

    const cbcReport = {
      uhid: selectedPatient.uhid,
      patient_name: selectedPatient.patient_name,
      hemoglobin: hemoglobinValue || null,
      rbc_count: rbcCountValue || null,
      wbc_count: wbcCountValue || null,
      platelet_count: plateletCountValue || null,
      hematocrit: hematocritValue || null,
      mcv: mcvValue || null,
      mch: mchValue || null,
      mchc: mchcValue || null,
      neutrophils: neutrophilsValue || null,
      lymphocytes: lymphocytesValue || null,
      monocytes: monocytesValue || null,
      eosinophils: eosinophilsValue || null,
      basophils: basophilsValue || null,
    };

    try {
      const response = await fetch(`${config.apiUrl}/lab/cbc/report`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(cbcReport),
      });

      if (!response.ok) throw new Error('Failed to submit report');

      alert('CBC report submitted successfully');
      setSelectedReport(null);
    } catch (error) {
      console.error('Error submitting CBC report:', error);
      alert('Failed to submit the report. Please try again.');
    }
  };

  // A helper function to determine if the input should have an error class
  const getInputClassName = (field) => {
    return errors[field] ? 'w-full p-2 border border-red-500 rounded' : 'w-full p-2 border border-gray-300 rounded';
  };

  return (
    <div className="h-[500px] overflow-auto p-6">
      <h2 className="text-2xl font-bold mb-4 text-center">Complete Blood Count (CBC) Report</h2>

      {/* Hemoglobin Level */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Hemoglobin (g/dL):</label>
        <input
          type="number"
          step="0.1"
          placeholder="Enter hemoglobin level"
          value={hemoglobinValue}
          onChange={(e) => setHemoglobinValue(e.target.value)}
          className={getInputClassName('hemoglobin')}
        />
      </div>

      {/* Red Blood Cell (RBC) Count */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">RBC Count (million/µL):</label>
        <input
          type="number"
          step="0.01"
          placeholder="Enter RBC count"
          value={rbcCountValue}
          onChange={(e) => setRbcCountValue(e.target.value)}
          className={getInputClassName('rbcCount')}
        />
      </div>

      {/* White Blood Cell (WBC) Count */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">WBC Count (cells/µL):</label>
        <input
          type="number"
          step="1"
          placeholder="Enter WBC count"
          value={wbcCountValue}
          onChange={(e) => setWbcCountValue(e.target.value)}
          className={getInputClassName('wbcCount')}
        />
      </div>

      {/* Platelet Count */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Platelet Count (cells/µL):</label>
        <input
          type="number"
          step="1"
          placeholder="Enter platelet count"
          value={plateletCountValue}
          onChange={(e) => setPlateletCountValue(e.target.value)}
          className={getInputClassName('plateletCount')}
        />
      </div>

      {/* Hematocrit (HCT) */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Hematocrit (HCT) (%):</label>
        <input
          type="number"
          step="0.1"
          placeholder="Enter hematocrit value"
          value={hematocritValue}
          onChange={(e) => setHematocritValue(e.target.value)}
          className={getInputClassName('hematocrit')}
        />
      </div>

      {/* Mean Corpuscular Volume (MCV) */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">MCV (fL):</label>
        <input
          type="number"
          step="0.1"
          placeholder="Enter MCV value"
          value={mcvValue}
          onChange={(e) => setMcvValue(e.target.value)}
          className={getInputClassName('mcv')}
        />
      </div>

      {/* Mean Corpuscular Hemoglobin (MCH) */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">MCH (pg):</label>
        <input
          type="number"
          step="0.1"
          placeholder="Enter MCH value"
          value={mchValue}
          onChange={(e) => setMchValue(e.target.value)}
          className={getInputClassName('mch')}
        />
      </div>

      {/* Mean Corpuscular Hemoglobin Concentration (MCHC) */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">MCHC (g/dL):</label>
        <input
          type="number"
          step="0.1"
          placeholder="Enter MCHC value"
          value={mchcValue}
          onChange={(e) => setMchcValue(e.target.value)}
          className={getInputClassName('mchc')}
        />
      </div>

      {/* Differential WBC Count */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Differential WBC Count (%)</label>
        <div className="grid grid-cols-2 gap-4">
          {['Neutrophils', 'Lymphocytes', 'Monocytes', 'Eosinophils', 'Basophils'].map((cellType, index) => (
            <div key={index} className="mb-4">
              <label className="block text-sm font-medium mb-2">{cellType}:</label>
              <input
                type="number"
                step="0.1"
                placeholder={`Enter ${cellType.toLowerCase()} value`}
                value={eval(`${cellType.toLowerCase()}Value`)} // Using eval to access the state variables
                onChange={(e) => eval(`set${cellType}Value(e.target.value)`)} // Using eval to update the state
                className={getInputClassName(cellType.toLowerCase())}
              />
            </div>
          ))}
        </div>
      </div>

      <button
        className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        onClick={handleSubmit}
      >
        Submit CBC Report
      </button>
    </div>
  );
};

const PaidLabList = () => {
  const [labData, setLabData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);

  const navigate =useNavigate();

  // Fetch data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true); // Show loading state
    try {
      const response = await fetch(`${config.apiUrl}/bill/paid-lablist`);
      if (!response.ok) throw new Error('Error fetching data');
      const data = await response.json();
      setLabData(data);
      setErrorMessage('');
    } catch (error) {
      console.error('Error fetching lab data:', error);
      setErrorMessage('Failed to fetch data. Please try again.');
    } finally {
      setLoading(false); // Hide loading state
    }
  };

  const renderReportForm = (procedure) => {
    console.log("procedure 2222220", procedure);
    
    switch (procedure.procedure_name) {
      case 'Complete Blood Count (CBC)\t':
        return <CBCform selectedPatient={procedure}  setSelectedReport={setSelectedReport}/>;
      case 'Blood glucose testing:':
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">Blood Glucose Report</h2>
            <label className="block mb-2">Glucose Level (mg/dL):</label>
            <input
              type="number"
              placeholder="Enter glucose level"
              className="w-full p-2 border rounded"
            />
            <label className="block mt-4 mb-2">Test Time:</label>
            <input
              type="datetime-local"
              className="w-full p-2 border rounded"
            />
            <button
              onClick={() => setSelectedReport(null)}
              className="mt-4 bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded"
            >
              Submit Report
            </button>
          </div>
        );

      default:
        return <p>No specific form available for this procedure.</p>;
    }
  };

  return (
    <div className="container mx-auto p-8">
      <div className="flex items-center justify-between w-full pb-4">
        <button
          className="flex items-center gap-2 p-2 bg-blue-800 text-white rounded hover:bg-blue-600"
          onClick={() => navigate('/lab')}
        >
          <FontAwesomeIcon icon={faArrowCircleLeft} />
        </button>

        <h1 className="text-2xl font-bold text-green-400 flex-grow text-center">
        Paid Lab Test List
        </h1>
      </div>
     
      {loading && (
        <div className="text-center my-4">
          <div className="loader"></div>
          <p>Loading data...</p>
        </div>
      )}

      {errorMessage && (
        <div className="bg-red-200 text-red-800 p-4 mb-4 rounded">
          {errorMessage}
        </div>
      )}

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300 rounded-lg">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-4 text-center">Date</th>
              <th className="py-3 px-4 text-left">UHID</th>
              <th className="py-3 px-4 text-left">Patient Name</th>
              <th className="py-3 px-4 text-center">Age</th>
              <th className="py-3 px-4 text-left">Doctor & Department</th>
              <th className="py-3 px-4 text-left">Mobile Number</th>
              <th className="py-3 px-4 text-left">Procedure</th>
              <th className="py-3 px-4 text-center">Fill Report</th>
            </tr>
          </thead>

          <tbody className="text-gray-700 text-sm">
            {labData.length > 0 ? (
              labData.map((item) => (
                <tr
                  key={item.id}
                  className="border-b border-gray-200 hover:bg-gray-100"
                >
                  <td className="py-3 px-4 text-center">
                    {new Date(item.timestamp).toLocaleString()}
                  </td>
                  <td className="py-3 px-4 text-left">{item.uhid}</td>
                  <td className="py-3 px-4 text-left">{item.patient_name}</td>
                  <td className="py-3 px-4 text-center">{item.age}</td>
                  <td className="py-3 px-4 text-left">
                    <span className="font-medium">{item.doctor_name}</span>
                    <br />
                    <span className="text-gray-500 text-sm">
                      {item.department}
                    </span>
                  </td>
                  <td className="py-3 px-4 text-left">{item.Mobile_number}</td>
                  <td className="py-3 px-4 text-left">{item.procedure_name}</td>
                  <td className="py-3 px-4 text-center">
                    <button
                      onClick={() => setSelectedReport(item)}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
                    >
                      Fill Report
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="text-center py-6">
                  No records found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {selectedReport && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
            {renderReportForm(selectedReport)}
            <button
              onClick={() => setSelectedReport(null)}
              className="mt-4 bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaidLabList;
