import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Dashboard = () => {
  const [date, setDate] = useState(new Date());

  const formattedDate = date.toISOString().split('T')[0];

  return (
    <div className='ml-2'>
      <div className="flex items-center space-x-4 mt-5">
        <label className="text-sm font-medium text-gray-400">Select Date:</label>
        <DatePicker
          selected={date}
          onChange={(date) => setDate(date)}
          dateFormat="yyyy-MM-dd"
          className="border rounded p-1 bg-transparent"
        />
      </div>
      <div className='flex flex-row justify-between mt-5'>
        <iframe 
          src={`https://ksrinsights.fiveytech.com/d-solo/de2eqqmruovlsa/hms-demo?orgId=1&var-date=${formattedDate}&panelId=1`} 
          width="260" 
          height="120" 
          frameBorder="0">
        </iframe>
        <iframe 
          src={`https://ksrinsights.fiveytech.com/d-solo/de2eqqmruovlsa/hms-demo?var-date=${formattedDate}&orgId=1&showCategory=Repeat+options&panelId=3`} 
          width="260" 
          height="120" 
          frameBorder="0">
        </iframe>
        <iframe 
          src={`https://ksrinsights.fiveytech.com/d-solo/de2eqqmruovlsa/hms-demo?orgId=1&var-date=${formattedDate}&panelId=4`} 
          width="260" 
          height="120" 
          frameBorder="0">
        </iframe>
        <iframe 
          src={`https://ksrinsights.fiveytech.com/d-solo/de2eqqmruovlsa/hms-demo?orgId=1&var-date=${formattedDate}&panelId=5`} 
          width="260" 
          height="120" 
          frameBorder="0">
        </iframe>
      </div>
      {/* <iframe src="https://ksrinsights.fiveytech.com/d-solo/de2eqqmruovlsa/hms-demo?orgId=1&var-date=2024-10-30&theme=light&panelId=6" width="450" height="200" frameborder="0"></iframe> */}
      <div className='mt-5'>
      <iframe src="https://ksrinsights.fiveytech.com/d-solo/de2eqqmruovlsa/hms-demo?orgId=1&var-date=2024-10-30&theme=dark&panelId=6" width="450" height="200" frameborder="0"></iframe>
        </div>
    </div>
  );
};

export default Dashboard;
