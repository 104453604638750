import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../Assets/newlogo.png';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faCog } from '@fortawesome/free-solid-svg-icons';
import profile from '../../Assets/p-one.png';
import config from '../../config';
 
const Header = ({ onThemeToggle, theme }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
 
  const handleLogout = async () => {
    const logoutEventData = {
        userId: user.user.user_id,
        userFirstName: user.user.user_first_name,
        userLastName: user.user.user_last_name,
        userRole: user.user.user_role,
    };

    setIsButtonDisabled(true);

    try {
        const response = await fetch(`${config.apiUrl}/user/logout`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(logoutEventData),
        });

        if (response.ok) {
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            navigate("/");
        } else {
            const result = await response.json();
            console.error("Error logging logout event:", result.message);
        }
    } catch (error) {
        console.error("Error logging logout event:", error.message);
    } finally {
        setIsButtonDisabled(false);
    }
};
 
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };
 
  return (
    <header>
      <nav className={`container-fluid items-center max-h-14 ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'}`}>
        <div className="flex justify-between">
          <NavLink to="/" className="flex items-center">
            <img src={logo} alt="Logo" className="w-16 h-16" />
            <h1
              className="font-bold text-3xl"
              style={{
                background: 'linear-gradient(45deg, #3498db, #2ecc71)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Doctor Connect
            </h1>
          </NavLink>
 
          <div className="flex items-center pr-4">
            <div className="flex-grow-0 font-serif flex items-center gap-2">
              <div className= {`leading-5 ${theme==='dark'?'text-white':''}`}>
                <h6 className="font-bold">{user ? `${user.user.user_role}` : 'Guest'}</h6>
                <p >{`${user?.user.user_first_name} ${user?.user.user_last_name}`}</p>
              </div>
              <img src={profile} alt="Profile" className="w-10 h-10 mr-4 rounded-full" />
 
              {/* Settings Icon */}
              <div className="relative">
                <button
                  className="bg-transparent border-none focus:outline-none mr-4"
                  onClick={toggleDropdown}
                >
                  <FontAwesomeIcon
                    icon={faCog}
                    size="lg"
                    className={theme === 'dark' ? 'text-white' : 'text-gray-800'}
                  />
                </button>
 
                {isDropdownOpen && (
                  <div
                    className={`absolute right-0 mt-2 w-40 shadow-lg rounded-md py-2 z-10 ${
                      theme === 'dark' ? 'bg-white text-black' : 'bg-gray-800 text-white'
                    }`}
                    onMouseLeave={() => setIsDropdownOpen(false)} // Close dropdown when mouse leaves dropdown area
                  >
                    {/* Only show the opposite theme in the dropdown */}
                    {theme === 'light' ? (
                      <div
                        className="cursor-pointer px-4 py-2 bg-black hover:bg-gray-200"
                        onClick={() => onThemeToggle('dark')}
                      >
                        Dark Mode
                      </div>
                    ) : (
                      <div
                        className="cursor-pointer px-4 py-2 bg-light hover:bg-gray-200"
                        onClick={() => onThemeToggle('light')}
                      >
                        Light Mode
                      </div>
                    )}
                  </div>
                )}
              </div>
 
              <div className="">
                <button
                  className="bg-transparent border-none focus:outline-none"
                  onClick={handleLogout}
                  disabled={isButtonDisabled}
                >
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    className={theme === 'dark' ? 'text-white' : 'text-gray-800'}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};
 
export default Header;

