import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faHistory, faCheck, faChartBar, faCalendar, faSearch, faSync, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import config from '../../config';
import Opbarchart from './opbarcharts';
import OpfilterDayBarchart from './opfiltercharts';
import { useNavigate } from 'react-router-dom';

const LoadingSpinner = () => (
  <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center flex items-center bg-blue-500 p-2 rounded-lg shadow-lg">
    <div className="animate-spin rounded-full border-t-4 bg-violet-950 border-opacity-50 h-8 w-8 mx-2"></div>
    <div className="text-white font-bold">Loading...</div>
  </div>
);


const Outpatient = () => {
  const [patientData, setPatientData] = useState(null);
  const [oldpatientData, setOldPatientData] = useState(null);
  const [patientHistory, setPatientHistory] = useState([]);
  const navigate = useNavigate();

  //time delay
  const [delayedRender, setDelayedRender] = useState(false);
  const renderChartWithDelay = () => {
    setTimeout(() => {
      setDelayedRender(true);
    }, 2000);
  };
  // console.log('user',userdata);
  const storedUserData = localStorage.getItem("user");

  // Parse the JSON string
  const parsedUserData = storedUserData ? JSON.parse(storedUserData) : null;
  console.log('userOp', parsedUserData);


  const [uhidHistorySearch, setUhidHistorySearch] = useState('');
  const [departmentHistorySearch, setDepartmentHistorySearch] = useState('');
  const [startHistoryDate, setStartHistoryDate] = useState(new Date());
  const [endHistoryDate, setEndHistoryDate] = useState(new Date());

  const [uhidAppointFilter, setuhidAppointFilter] = useState('');
  const [departmentAppointFilter, setdepartmentAppointFilter] = useState('');
  const [startDateAppointFilter, setstartDateAppointFilter] = useState('');
  const [endDateAppointFilter, setendDateAppointFilter] = useState('');

  const handleSearchAppointUhid = (e) => {
    setuhidAppointFilter(e.target.value);
  };

  const handleSearchAppointDepartment = (e) => {
    setdepartmentAppointFilter(e.target.value);
  };

  const handleStartDateAppointChange = (e) => {
    setstartDateAppointFilter(e.target.value);
  };

  const handleEndDateAppointChange = (e) => {
    setendDateAppointFilter(e.target.value);
  };

  const [errorMessage, setErrorMessage] = useState('');
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [OPSuccess, setOPSuccess] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showAllergyInput, setshowAllergyInput] = useState(false);
  const [selectedAllergy, setSelectedAllergy] = useState('');
  const [inputAllergyValue, setinputAllergyValue] = useState('');
  const [tableAllergyData, settableAllergyData] = useState([]);
  // Chronic Field
  const [showChronicInput, setshowChronicInput] = useState(false);
  const [inputChronicValue, setinputChronicValue] = useState('');
  const [tableChronicData, settableChronicData] = useState([]);
  // Treatment Field
  const [showTreatmentInput, setshowTreatmentInput] = useState(false);
  const [inputTreatmentValue, setinputTreatmentValue] = useState('');
  const [tableTreatmentData, settableTreatmentData] = useState([]);
  // Surgeon Field
  const [showSurgeonInput, setshowSurgeonInput] = useState(false);
  const [inputSurgeonValue, setinputSurgeonValue] = useState('');
  const [tabletSurgeonData, settabletSurgeonData] = useState([]);
  // Hospitalized Field
  const [showHospitalizedInput, setshowHospitalizedInput] = useState(false);
  const [inputHospitalizedValue, setinputHospitalizedValue] = useState('');
  const [tabletHospitalizedData, settabletHospitalizedData] = useState([]);
  // Immunizations Field
  const [showImmunizationInput, setshowImmunizationInput] = useState(false);
  const [inputImmunizationValue, setinputImmunizationValue] = useState('');
  const [tabletImmunizationData, settabletImmunizationData] = useState([]);
  // Family History
  const [showFamilyInput, setshowFamilyInput] = useState(false);
  const [inputFamilyValue, setinputFamilyValue] = useState('');
  const [tabletFamilyData, settabletFamilyData] = useState([]);

  const [showMedicationInput, setShowMedicationInput] = useState(false);
  const [medications, setMedications] = useState([]);

  const [medicine, setMedicine] = useState('');
  const [dosage, setDosage] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkedSocialItems, setCheckedSocialItems] = useState({
    smoking: false,
    alcohol: false,
    drugs: false,
    exercise: false,
    others: false,
    none: false,
  });

  const [newSocialItem, setNewSocialItem] = useState('');
  const [tableSocialData, settableSocialData] = useState([]);
  const [isTableSocialVisible, setisTableSocialVisible] = useState(false);


  const [hasPhysicalExam, setHasPhysicalExam] = useState(false);
  const [diagnosticTests, setDiagnosticTests] = useState([null]);
  const [fileSelected, setFileSelected] = useState([false]);
  const [isReport, setIsReport] = useState(false);

  const inputRef = useRef(null);
  const [errors, setErrors] = useState({
    height: '',
    bloodPressure: '',
    weight: '',
    temperature: '',
    currentProblem: '',
  });

  const [formData, setFormData] = useState({
    height: '',
    bloodPressure: '',
    weight: '',
    temperature: '',
    currentProblem: '',
  });




  const [isAppointmentModalOpen, setAppointmentModalOpen] = useState(false);

  const [isHistoryModalOpen, setHistoryModalOpen] = useState(false);

  const [isStaticsModalOpen, setIsStaticsModalOpen] = useState(false);

  const handleOpenStaticsModal = () => {
    setIsStaticsModalOpen(true);
  };

  const handleCloseStaticsModal = () => {
    setIsStaticsModalOpen(false);
    setDelayedRender(false);

  };
  const handleOpenReportModal = () => {
    setIsReport(true);
  };

  const handleCloseReportModal = () => {
    setIsReport(false);
  };
  const handleOpenHistoryModal = () => {
    setHistoryModalOpen(true);
  };

  const indFormattedDate = (date) => {
    const utcDate = new Date(date);
    const options = { timeZone: 'Asia/Kolkata', year: 'numeric', month: '2-digit', day: '2-digit' };

    const formattedDate = new Intl.DateTimeFormat('en-IN', options).format(utcDate);

    // Extracting individual components and joining them with hyphens
    const [day, month, year] = formattedDate.split('/');
    return `${year}-${month}-${day}`;
  };

  const handleCloseHistoryModal = () => {
    setHistoryModalOpen(false);
    setStartHistoryDate(new Date());
    setEndHistoryDate(new Date());
  };

  // Function to handle opening the appointment modal
  const handleOpenAppointmentModal = () => {
    setAppointmentModalOpen(true);
  };

  // Function to handle closing the appointment modal
  const handleCloseAppointmentModal = () => {
    setAppointmentModalOpen(false);
  };

  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {

        // Fetch initial data
        await fetchInitialData();

        // Fetch departments
        await fetchDepartment();
        // await  fetchHistoryData();

        // Fetch old patient data based on selectedPatient
        // await fetchPatientData();
      } catch (error) {
        console.error('No Patient Available', error);
      }
    };

    fetchData();

  }, [selectedPatient]); // Dependencies might need adjustment based on your actual use case

  const fetchDepartment = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/op/departments`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setDepartments(data);
      console.log('Departments:', data);
    } catch (error) {
      console.error('Error fetching departments:', error);
    }
  };

  const fetchFutureAppoinment = async () => {
    try {
      if (parsedUserData.user.user_role === "Admin") {
        const response = await fetch(`${config.apiUrl}/op/future-appointments`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        if (data.success) {
          setAppointments(data.data);
          console.log('Future Appointments:', data);
        } else {
          console.error('Error fetching future appointments:', data.message);
        }
      } else {
        const response = await fetch(`${config.apiUrl}/op/future-appointments-department/${parsedUserData.user.user_department}`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        if (data.success) {
          setAppointments(data.data);
          console.log('Future Appointments:', data);
        } else {
          console.error('Error fetching future appointments:', data.message);
        }
      }
    } catch (error) {
      console.error('Error fetching future appointments:', error);
    }
  };

  const fetchInitialData = async () => {
    try {
      if (parsedUserData.user.user_role === "Admin") {
        const response = await fetch(`${config.apiUrl}/op/paid-patients/date`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        console.log('res', response);
        const data = await response.json();
        setPatientData(data.data);
        setErrorMessage('');
      } else {
        const response = await fetch(`${config.apiUrl}/op/paid-patients-departments/date/${parsedUserData.user.user_department}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        console.log('res', response);
        const data = await response.json();
        setPatientData(data.data);
        setErrorMessage('');
      }

    } catch (error) {
      console.error('Error fetching initial data:', error);
      setErrorMessage('No Patient Available');
    }
  };

  const fetchHistoryData = async () => {
    try {
      if (parsedUserData.user.user_role === 'Admin') {
        const response = await fetch(`${config.apiUrl}/op/Get_Out_Patient`);

        console.log('HTTP Response:', response.status); // Log the HTTP response status

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        console.log('Server Response:', data); // Log the full server response

        if (Array.isArray(data) && data.length > 0) {
          setPatientHistory(data);
          console.log('History:', data);
        } else {
          console.error('Error fetching History: Empty or invalid response');
        }

        return data;
      } else {
        const response = await fetch(`${config.apiUrl}/op/Get_Out_Patient-department/${parsedUserData.user.user_department}`);

        console.log('HTTP Response:', response.status); // Log the HTTP response status

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        console.log('Server Response:', data); // Log the full server response

        if (Array.isArray(data) && data.length > 0) {
          setPatientHistory(data);
          console.log('History:', data);
        } else {
          console.error('Error fetching History: Empty or invalid response');
        }

        return data;
      }

    } catch (error) {
      console.error('NO patient Available:', error);
      throw error; // Re-throw the error to handle it further if needed
    }
  };



  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    fetchInitialData();
  };

  const handleResetForm = () => {

    setSelectedPatient(null);
    setshowAllergyInput(false);
    setinputAllergyValue('');
    settableAllergyData([]);
    setshowChronicInput(false);
    setinputChronicValue('');
    settableChronicData([]);
    setshowTreatmentInput(false);
    setinputTreatmentValue('');
    settableTreatmentData([]);
    setshowSurgeonInput(false);
    setinputSurgeonValue('');
    settabletSurgeonData([]);
    setshowHospitalizedInput(false);
    setinputHospitalizedValue('');
    settabletHospitalizedData([]);
    setshowImmunizationInput(false);
    setinputImmunizationValue('');
    settabletImmunizationData([]);
    setshowFamilyInput(false);
    setinputFamilyValue('');
    settabletFamilyData([]);
    setShowMedicationInput(false);
    setMedications([]);
    setMedicine('');
    setDosage('');
    setCheckedSocialItems({
      smoking: false,
      alcohol: false,
      drugs: false,
      exercise: false,
      others: false,
      none: false,
    });
    setNewSocialItem('');
    settableSocialData([]);
    setisTableSocialVisible(false);
    setDiagnosticTests([null]);
    setOldPatientData([null]);
    console.log('handleresett');
  };





  // useEffect(() => {
  // }, [oldpatientData]);

  const handleViewClick = (patient) => {
    console.log('patient', patient);
    setSelectedPatient(patient);
    openModal()
  };

  const handleSubmit = async () => {
    let formValid = true;
    const newErrors = {
      height: '',
      bloodPressure: '',
      weight: '',
      temperature: '',
      currentProblem: '',
    };


    if (!formData.height) {
      newErrors.height = 'Enter the value';
      formValid = false;
    }

    if (!formData.bloodPressure) {
      newErrors.bloodPressure = 'Enter the value';
      formValid = false;
    }

    if (!formData.weight) {
      newErrors.weight = 'Enter the value';
      formValid = false;
    }

    if (!formData.temperature) {
      newErrors.temperature = 'Enter the value';
      formValid = false;
    }


    if (formValid) {
      const promises = [];
      // const formattedDate = new Date(selectedPatient.date).toISOString().split('T')[0];

      const allergiesData = tableAllergyData.map((item) => ({
        uhid: selectedPatient.uhid,
        date: indFormattedDate(selectedPatient.date),
        allergy_type: item.type,
        allergy_description: item.details,
      }));


      const chronicData = tableChronicData.map((item) => ({
        uhid: selectedPatient.uhid,
        date: indFormattedDate(selectedPatient.date),
        Chronic_description: item,
      }));


      const treatmentData = tableTreatmentData.map((item) => ({
        uhid: selectedPatient.uhid,
        date: indFormattedDate(selectedPatient.date),
        treatment_description: item,
      }));


      const hospitalizationData = tabletHospitalizedData.map((item) => ({
        uhid: selectedPatient.uhid,
        date: indFormattedDate(selectedPatient.date),
        hopitalizations_description: item,
      }));


      const surgeryData = tabletSurgeonData.map((item) => ({
        uhid: selectedPatient.uhid,
        date: indFormattedDate(selectedPatient.date),
        surgery_description: item,
      }));


      const immunizationData = tabletImmunizationData.map((item) => ({
        uhid: selectedPatient.uhid,
        date: indFormattedDate(selectedPatient.date),
        immunization_type: item,
      }));


      const medicationData = medications.map((item) => ({
        uhid: selectedPatient.uhid,
        date: indFormattedDate(selectedPatient.date),
        medicine_name: item.medicine,
        dosage: item.dosage,
      }));


      const familyData = tabletFamilyData.map((item) => ({
        uhid: selectedPatient.uhid,
        date: indFormattedDate(selectedPatient.date),
        family_history_type: item,
      }));


      const socialData = tableSocialData.map((item) => ({
        uhid: selectedPatient.uhid,
        date: indFormattedDate(selectedPatient.date),
        social_History_type: item.social
      }));

      const socialHistoryId = checkedSocialItems.none ? 'no' : Object.values(checkedSocialItems).some((value) => value) ? 'yes' : 'no';
      console.log("socialHistoryId", socialHistoryId);

      const utcDate = new Date(selectedPatient.date || selectedPatient.registration_date);
      const istDate = utcDate.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
      const dateOnly = istDate.split(',')[0];
      const [day, month, year] = dateOnly.split('/');
      const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;


      let dataToSend;
      dataToSend = {
        patient_name: selectedPatient.patient_name,
        uhid: selectedPatient.uhid,
        doctor_name: selectedPatient.consultation_doctor,
        department: selectedPatient.department_name,
        age: selectedPatient.age,
        Mobile_number: selectedPatient.Mobile_number,
        date: formattedDate,
        height_cm: formData.height,
        weight_kg: formData.weight,
        bp_mmhg: formData.bloodPressure,
        temperature_celsius: formData.temperature,
        current_problem: formData.currentProblem,
        past_checkup: formData.pastmedicalhistory,
        allergies_id: showAllergyInput ? 'yes' : 'no',
        chronic_conditions_id: showChronicInput ? 'yes' : 'no',
        treatment_before_id: showTreatmentInput ? 'yes' : 'no',
        hospitalizations_id: showHospitalizedInput ? 'yes' : 'no',
        surgeries_id: showSurgeonInput ? 'yes' : 'no',
        immunizations_id: showImmunizationInput ? 'yes' : 'no',
        medications_history_id: showMedicationInput ? 'yes' : 'no',
        family_history_id: showFamilyInput ? 'yes' : 'no',
        social_history_id: socialHistoryId,
        physical_examination_findings: hasPhysicalExam ? 'yes' : 'no',
        plan_of_care: formData.planOfCare
      };


      console.log('data', dataToSend);


      handleResetForm();
      setShowSuccessPopUp(true);

      setTimeout(() => {
        setShowSuccessPopUp(false);
      }, 2500);

      const addRecordPromise = fetch(`${config.apiUrl}/op/Outpatient-Data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .catch((error) => {
          console.error('Error adding record:', error);
          throw new Error('Error adding record');
        });

      promises.push(addRecordPromise);

      const updateOpStatusPromise = fetch(`${config.apiUrl}/op/update-opstatus/${selectedPatient.uhid}/${formattedDate}/${selectedPatient.department_name}/${selectedPatient.consultation_doctor}`, {
        method: 'PUT',
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .catch((error) => {
          console.error('Error updating op status:', error);
          throw new Error('Error updating op status');
        });

      promises.push(updateOpStatusPromise);



      if (showAllergyInput) {
        const addAllergiesPromise = fetch(`${config.apiUrl}/op/Op_Allergy`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(allergiesData),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
              return response.json();
            } else {
              // Handle non-JSON response (if applicable)
              return response.text();
            }
          })
          .catch((error) => {
            console.error('Error adding allergies data:', error);
            throw new Error('Error adding allergies data');
          });
        promises.push(addAllergiesPromise);

      }

      if (showChronicInput) {
        const addChronicPromise = fetch(`${config.apiUrl}/op/Op_Chronic_Conditions`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(chronicData),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
              return response.json();
            } else {
              return response.text();
            }
          })
          .catch((error) => {
            console.error('Error adding allergies data:', error);
            throw new Error('Error adding allergies data');
          });
        promises.push(addChronicPromise);
      }

      if (showTreatmentInput) {
        const addTreatmentPromise = fetch(`${config.apiUrl}/op/Op_Treatment_Before`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(treatmentData),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
              return response.json();
            } else {
              return response.text();
            }
          })
          .catch((error) => {
            console.error('Error adding allergies data:', error);
            throw new Error('Error adding allergies data');
          });
        promises.push(addTreatmentPromise);
      }

      if (showHospitalizedInput) {
        const addhospitalizationsPromise = fetch(`${config.apiUrl}/op/Op_Hospitalizations`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(hospitalizationData),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
              return response.json();
            } else {
              return response.text();
            }
          })
          .catch((error) => {
            console.error('Error adding allergies data:', error);
            throw new Error('Error adding allergies data');
          });
        promises.push(addhospitalizationsPromise);
      }

      if (showSurgeonInput) {
        const addsurgeryPromise = fetch(`${config.apiUrl}/op/Op_Surgery`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(surgeryData),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
              return response.json();
            } else {
              return response.text();
            }
          })
          .catch((error) => {
            console.error('Error adding allergies data:', error);
            throw new Error('Error adding allergies data');
          });

        promises.push(addsurgeryPromise);
      }

      if (showImmunizationInput) {
        const addimmunizationPromise = fetch(`${config.apiUrl}/op/Op_Immunization`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(immunizationData),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
              return response.json();
            } else {
              return response.text();
            }
          })
          .catch((error) => {
            console.error('Error adding allergies data:', error);
            throw new Error('Error adding allergies data');
          });
        promises.push(addimmunizationPromise);
      }

      if (showMedicationInput) {
        const addmedicationPromise = fetch(`${config.apiUrl}/op/Op_Medications`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(medicationData),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
              return response.json();
            } else {
              return response.text();
            }
          })
          .catch((error) => {
            console.error('Error adding allergies data:', error);
            throw new Error('Error adding allergies data');
          });
        promises.push(addmedicationPromise);
      }

      if (showFamilyInput) {
        const addfamilyPromise = fetch(`${config.apiUrl}/op/Op_Family_History`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(familyData),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
              return response.json();
            } else {
              return response.text();
            }
          })
          .catch((error) => {
            console.error('Error adding allergies data:', error);
            throw new Error('Error adding allergies data');
          });
        promises.push(addfamilyPromise);
      }

      if (socialHistoryId !== 'no') {
        const addsocialPromise = fetch(`${config.apiUrl}/op/Op_Social_History`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(socialData),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
              return response.json();
            } else {
              return response.text();
            }
          })
          .catch((error) => {
            console.error('Error adding allergies data:', error);
            throw new Error('Error adding allergies data');
          });
        promises.push(addsocialPromise);
      }
      if (hasPhysicalExam) {
        const filesToUpload = diagnosticTests.filter(Boolean);

        if (filesToUpload.length > 0) {
          const formDataFiles = new FormData();

          // Append each file to FormData with the correct field name
          filesToUpload.forEach((file, index) => {
            formDataFiles.append('file', file);  // Use 'file' as the field name
          });

          formDataFiles.append('uhid', selectedPatient.uhid);
          formDataFiles.append('date', indFormattedDate(selectedPatient.date))

          try {
            // Send the request to upload files
            const responseFiles = await fetch(`${config.apiUrl}/op/Op_Pdfs_Upload`, {
              method: 'POST',
              body: formDataFiles,
            });

            if (responseFiles.ok) {
              console.log('Files uploaded successfully');
              // Handle success, e.g., update UI
            } else {
              console.error('Failed to upload files');
              // Handle failure
            }
          } catch (error) {
            console.error('Error uploading files:', error);
            // Handle error
          }

        }
      }



      Promise.all(promises)
        .then((results) => {
          const [addRecordResult, updateOpStatusResult, addAllergiesResult] = results;

          setOPSuccess(true);
          setSubmitted(true);

          setTimeout(() => {
            setOPSuccess(false);
          }, 2000);

           fetchInitialData();
        })
        .catch((error) => {
          console.error('Error:', error);
          setErrorMessage('An error occurred while processing.');
        });
    } else {
      setErrors(newErrors);
      setTimeout(() => {
        setErrors({
          height: '',
          bloodPressure: '',
          weight: '',
          temperature: '',
          currentProblem: '',
        });
      }, 2500);
    }
  };


  const handleAddToTable = () => {
    if (selectedAllergy && inputAllergyValue.trim()) {
      settableAllergyData(prevData => [...prevData, { type: selectedAllergy, details: inputAllergyValue.trim() }]);
      setinputAllergyValue('');
      setSelectedAllergy('');
    }
  };

  const handleChronicAddToTable = () => {
    if (inputChronicValue.trim()) {
      settableChronicData(prevData => [...prevData, inputChronicValue.trim()]);
      setinputChronicValue('');
    }
  };


  const handleTreatmentAddToTable = () => {
    if (inputTreatmentValue.trim()) {
      settableTreatmentData(prevData => [...prevData, inputTreatmentValue.trim()]);
      setinputTreatmentValue('');
    }
  };

  const handleSurgeonAddToTable = () => {
    if (inputSurgeonValue.trim()) {
      settabletSurgeonData(prevData => [...prevData, inputSurgeonValue.trim()]);
      setinputSurgeonValue('');
    }
  };

  const handleHospitalizedAddToTable = () => {
    if (inputHospitalizedValue.trim()) {
      settabletHospitalizedData(prevData => [...prevData, inputHospitalizedValue.trim()]);
      setinputHospitalizedValue('');
    }
  };

  const handleImmunizationAddToTable = () => {
    if (inputImmunizationValue.trim()) {
      settabletImmunizationData(prevData => [...prevData, inputImmunizationValue.trim()]);
      setinputImmunizationValue('');
    }
  };
  const handleAddMedication = () => {
    if (medicine && dosage) {
      setMedications([...medications, { id: Date.now(), medicine, dosage }]);
      setMedicine('');
      setDosage('');
    }
  };

  const handleRemoveMedication = (id) => {
    setMedications(medications.filter(item => item.id !== id));
  };


  const handleFamilyHisAddToTable = () => {
    if (inputFamilyValue.trim()) {
      settabletFamilyData(prevData => [...prevData, inputFamilyValue.trim()]);
      setinputFamilyValue('');
    }
  };

  const handleSocialChange = (e) => {
    const { name, checked } = e.target;

    if (name === 'none') {
      // If "none" checkbox is clicked, set social_history_id to 'no' and uncheck all other checkboxes
      setCheckedSocialItems((prevItems) => ({
        ...Object.fromEntries(Object.keys(prevItems).map((key) => [key, false])),
        [name]: checked,
      }));
      setisTableSocialVisible(false);
      settableSocialData([]);
    } else {
      setCheckedSocialItems((prevItems) => ({ ...prevItems, [name]: checked }));

      if (checked && name !== 'others') {
        // Add selected item directly to the table
        settableSocialData((prevData) => [...prevData, { social: name.charAt(0).toUpperCase() + name.slice(1) }]);
      }

      setisTableSocialVisible(true); // Show the table when any checkbox is clicked
    }
  };

  const filteredPatientHistory = patientHistory.filter((patienthistory) => {
    const uhidMatch = patienthistory.uhid.toLowerCase().includes(uhidHistorySearch.toLowerCase());
    const departmentMatch = patienthistory.department.toLowerCase().includes(departmentHistorySearch.toLowerCase());
    const dateMatch = indFormattedDate(patienthistory.date) >= indFormattedDate(startHistoryDate) && indFormattedDate(patienthistory.date) <= indFormattedDate(endHistoryDate);
    // return uhidMatch && departmentMatch;
    return uhidMatch && departmentMatch && dateMatch;
  });

  const filteredAppointments = appointments.filter((appointment) => {
    const uhidMatch = appointment.uhid.toLowerCase().includes(uhidAppointFilter.toLowerCase());
    const departmentMatch = appointment.department_name.toLowerCase().includes(departmentAppointFilter.toLowerCase());
    let dateMatch = true;

    if (startDateAppointFilter && endDateAppointFilter) {
      const appointmentDate = indFormattedDate(appointment.date);
      const startDate = indFormattedDate(startDateAppointFilter);
      const endDate = indFormattedDate(endDateAppointFilter);

      dateMatch = appointmentDate >= startDate && appointmentDate <= endDate;
    }

    return uhidMatch && departmentMatch && dateMatch;
  });

  const handleSocialAddItem = () => {
    if (checkedSocialItems.others && newSocialItem.trim() !== '') {
      settableSocialData([...tableSocialData, { social: newSocialItem }]);
      setNewSocialItem('');
    }
  };

  const handleAddDiagnosticTest = () => {
    setDiagnosticTests([...diagnosticTests, null]);
    setFileSelected([...fileSelected, false]);
  };

  const handleDiagnosticTestChange = (file, index) => {
    if (isUploadDisabled(index)) {
      return;
    }

    const updatedTests = [...diagnosticTests];
    updatedTests[index] = file;
    setDiagnosticTests(updatedTests);

    const updatedFileSelected = [...fileSelected];
    updatedFileSelected[index] = true;
    setFileSelected(updatedFileSelected);
  };

  const handleRemoveDiagnosticTest = (index) => {
    const updatedTests = [...diagnosticTests];
    updatedTests.splice(index, 1);
    setDiagnosticTests(updatedTests);

    const updatedFileSelected = [...fileSelected];
    updatedFileSelected.splice(index, 1);
    setFileSelected(updatedFileSelected);
  };

  const handleNoPhysicalExam = () => {
    setHasPhysicalExam(false);
    // Clear diagnosticTests and disable fileSelected for all items
    setDiagnosticTests([null]);
    setFileSelected([false]);
  };

  const isUploadDisabled = (index) => {
    return fileSelected[index];
  };

  return (

    <div className=" p-8 flex flex-col  h-full w-100">
      {!selectedPatient && (
        <div className="flex flex-col">
          {showSuccessPopUp && (
            <div className="fixed bottom-4 right-4 bg-green-500 text-white py-2 px-4 rounded shadow">
              Patient moved to Doctor
            </div>
          )}

          
      <div className="flex items-center justify-between w-full pb-4">
        {/* Button on the left */}
        <button
          className="flex items-center gap-2 p-2 bg-blue-800 text-white rounded hover:bg-blue-600"
          onClick={() => navigate('/nurse-station')}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>

        {/* Heading centered */}
        <h1 className="text-2xl font-bold text-green-400 flex-grow text-center">
          Out Patients List's
        </h1>
      </div>

          <div className="flex items-center">
            <h2 className="text-lg mb-4"><b>OUTPATIENT LIST</b></h2>
            <FontAwesomeIcon
              icon={faSync}
              className="w-6 h-4 text-black-600 cursor-pointer ml-2 mb-4"
              onClick={fetchInitialData}
            />
          </div>

          <div className="flex items-center">
            <div className="relative">
              <FontAwesomeIcon
                icon={faSearch}
                className="absolute text-black-500 h-4 w-4 left-3 top-2"
              />
              <input
                type="text"
                placeholder="Search by UHID or Patient Name"
                className="pl-8 pr-2 py-2 border rounded"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            {parsedUserData.user.user_role === "Admin" ? (
              <div> 
                {/* <label htmlFor="departmentsList" className="ml-4">Department</label> */}
              <input
                ref={inputRef}
                list="departmentsList"
                className="p-2 border rounded ml-4"
                placeholder="Select Department"
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  const isValid = departments.some(department => department.department === selectedValue);
                  if (isValid || !selectedValue) {
                    setSelectedDepartment(selectedValue);
                  } else {
                    setSelectedDepartment('');
                  }
                }}
                onBlur={() => {
                  if (inputRef.current && !inputRef.current.value) {
                    setSelectedDepartment('');
                  }
                }}
              />
              <datalist id="departmentsList">
                {departments.map((department) => (
                  <option key={department.id} value={department.department} />
                ))}
              </datalist></div>) : ''}

            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-4 flex items-center"
              onClick={() => {
                handleOpenAppointmentModal();
                fetchFutureAppoinment();
              }}
            >
              <FontAwesomeIcon icon={faCalendar} className="mr-2" />
              Appointment
            </button>

            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-4 flex items-center"
              onClick={() => {
                handleOpenHistoryModal();
                fetchHistoryData();
              }}
            >
              <FontAwesomeIcon icon={faHistory} className="mr-2" />
              History
            </button>

            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-4 flex items-center"
              onClick={() => {
                handleOpenStaticsModal();
                fetchHistoryData();
              }}
            >
              <FontAwesomeIcon icon={faChartBar} className="mr-2" />
              <span>Statistics</span>
            </button>
          </div>

          <table className="border-collapse ">
            <thead>
              <tr>
                <th className=" text-center px-4 py-2">S.NO</th>
                <th className=" text-center px-4 py-2">UHID</th>
                <th className=" text-center px-4 py-2">Patient Name</th>
                <th className=" text-center px-4 py-2">Doctor Name</th>
                <th className=" text-center px-4 py-2">Department</th>
                <th className=" text-center px-4 py-2">Registration Fees</th>
                <th className=" text-center px-4 py-2">Consultation Fees</th>
                <th className=" text-center px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {patientData &&
                patientData
                  .filter((patient) => {
                    const searchTerm = searchQuery.toLowerCase();
                    return (
                      patient.uhid.toLowerCase().includes(searchTerm) ||
                      patient.patient_name.toLowerCase().includes(searchTerm)
                    );
                  })
                  .filter((patient) => !selectedDepartment || patient.department_name === selectedDepartment)
                  .map((patient, index) => (
                    <tr key={patient.uhid}>
                      <td className=" px-4 py-2 text-center">{index + 1}</td>
                      <td className=" px-4 py-2 text-center">{patient.uhid}</td>
                      <td className=" px-4 py-2 text-center">{patient.patient_name}</td>
                      <td className=" px-4 py-2 text-center">{patient.consultation_doctor}</td>
                      <td className=" px-4 py-2 text-center">{patient.department_name}</td>
                      <td className=" px-4 py-2 text-center">
                        {patient.registration_payment_status === 1 ? 'PAID' : patient.registration_fees}
                      </td>
                      <td className=" px-4 py-2 text-center">{patient.consultpayment_Status === 1 ? 'PAID' : patient.consultation_fees}</td>
                      <td className=" px-4 py-2 text-center">
                        <button disabled={selectedPatient !== null} onClick={() => handleViewClick(patient)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      )}
      {selectedPatient && (
        <div
          className={`fixed inset-0 overflow-y-auto ${isModalOpen ? 'backdrop-filter backdrop-blur-sm' : ''} transition-all duration-300 ${isModalOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
            }`}
        >
          <div className="flex items-center justify-center h-screen">
            <div
              className={`bg-white p-4 w-1/2 md:w-1/2 lg:w-3/4 rounded shadow-lg transition-transform duration-300 transform ${isModalOpen ? 'scale-100' : 'scale-0'
                } border-2 border-blue-500 max-h-full overflow-auto`}
              style={{
                scrollbarWidth: 'thin',
                scrollbarColor: 'rgba(0, 0, 0, 0.5) transparent',

              }}
            >
              <div className="flex flex-col">
                <div className="flex justify-between items-center">

                  <div className="flex ml-4 items-center">
                    <div className="flex justify-end">
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => {
                          setSelectedPatient(null);
                          closeModal();
                          setshowAllergyInput(false);
                          setinputAllergyValue('');
                          settableAllergyData([]);
                          setshowChronicInput(false);
                          setinputChronicValue('');
                          settableChronicData([]);
                          setshowTreatmentInput(false);
                          setinputTreatmentValue('');
                          settableTreatmentData([]);
                          setshowSurgeonInput(false);
                          setinputSurgeonValue('');
                          settabletSurgeonData([]);
                          setshowHospitalizedInput(false);
                          setinputHospitalizedValue('');
                          settabletHospitalizedData([]);
                          setshowImmunizationInput(false);
                          setinputImmunizationValue('');
                          settabletImmunizationData([]);
                          setshowFamilyInput(false);
                          setinputFamilyValue('');
                          settabletFamilyData([]);
                          setShowMedicationInput(false);
                          setMedications([]);
                          setMedicine('');
                          setDosage('');
                          setCheckedSocialItems({
                            smoking: false,
                            alcohol: false,
                            drugs: false,
                            exercise: false,
                            others: false,
                            none: false,
                          });
                          setNewSocialItem('');
                          settableSocialData([]);
                          setisTableSocialVisible(false);
                        }}
                        className="text-red-500 cursor-pointer"
                        size="lg"
                      />
                    </div>
                  </div>

                  <div className="mb-6 mt-10">
                    <h2 className="font-bold text-xl mb-2 text-gray-700">Consultation Doctor</h2>
                    <p>{selectedPatient.consultation_doctor}</p>
                  </div>

                </div>
                <div className="grid grid-cols-1 gap-6">
                  <div className="space-y-4">
                    <div className="flex space-x-4">
                      <div className="flex-1">
                        <label className="block text-sm font-bold  text-gray-700">Patient Name</label>
                        <input type="text" placeholder="Patient name" className="mt-1 p-2 border rounded w-full" value={selectedPatient.patient_name || ''} readOnly />
                      </div>
                      <div className="flex-1">
                        <b><label className="block text-sm font-bold text-gray-700">UHID</label></b>
                        <input type="text" placeholder="UHID" className="mt-1 p-2 border rounded w-full" value={selectedPatient.uhid || ''} readOnly />
                      </div>
                      <div className="flex-1">
                        <b><label className="block text-sm font-bold text-gray-700">Age</label></b>
                        <input type="text" placeholder="Age" className="mt-1 p-2 border rounded w-full" value={selectedPatient.age || ''} readOnly />
                      </div>
                      <div className="flex-1">
                        <label className="block text-sm font-bold text-gray-700">Mobile Number</label>
                        <input type="text" placeholder="Mobile Number" className="mt-1 p-2 border rounded w-full" value={selectedPatient.Mobile_number || ''} readOnly />
                      </div>

                    </div>

                    <div className="flex space-x-4">
                      <div className="flex-1">
                        <label className="block text-sm font-bold text-gray-700">Doctor</label>
                        <input type="text" placeholder="Consultation fees" className="mt-1 p-2 border rounded w-full" value={selectedPatient.consultation_doctor || ''} readOnly />
                      </div>
                      <div className="flex-1">
                        <label className="block text-sm font-bold text-gray-700">Department</label>
                        <input type="text" placeholder="Department" className="mt-1 p-2 border rounded w-full" value={selectedPatient.department_name || ''} readOnly />
                      </div>
                      <div className="flex-1">
                        <label className="block text-sm font-bold text-gray-700">Date</label>
                        <input type="text" placeholder="Date" className="mt-1 p-2 border rounded w-full" value={(() => {
                          const utcDate = new Date(selectedPatient.date || selectedPatient.registration_date);
                          const istDate = utcDate.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
                          const dateOnly = istDate.split(',')[0];
                          const [day, month, year] = dateOnly.split('/');
                          const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
                          return formattedDate;
                        })()} readOnly />
                      </div>
                    </div>

                    <div>
                      <div className="flex space-x-4">
                        <div className="flex-1">
                          <label className="block text-sm font-bold text-gray-700">Height (cm)</label>
                          <input type="text" placeholder="Enter Height" className="mt-1 p-2 border rounded w-full" pattern="[0-9]+([.][0-9]+)?" title="Enter a value"
                            onKeyPress={(e) => {
                              const charCode = e.which ? e.which : e.keyCode;
                              if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => setFormData({ ...formData, height: e.target.value })}
                          />
                          {errors.height && <p className="text-red-500">{errors.height}</p>}

                        </div>
                        <div className="flex-1">
                          <label className="block text-sm font-bold text-gray-700">Weight (kg)</label>
                          <input type="text" placeholder="Enter Weight" className="mt-1 p-2 border rounded w-full" pattern="[0-9]+([.][0-9]+)?" title="Enter a value"
                            onKeyPress={(e) => {
                              const charCode = e.which ? e.which : e.keyCode;
                              if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
                                e.preventDefault();
                              }
                            }} onChange={(e) => setFormData({ ...formData, weight: e.target.value })} />
                          {errors.weight && <p className="text-red-500">{errors.weight}</p>}

                        </div>
                        <div className="flex-1">
                          <label className="block text-sm font-bold text-gray-700">BP (mmHg) </label>
                          <input type="text" placeholder="Enter Height" className="mt-1 p-2 border rounded w-full" pattern="[0-9]+([.][0-9]+)?" title="Enter a value"
                            onKeyPress={(e) => {
                              const charCode = e.which ? e.which : e.keyCode;
                              if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
                                e.preventDefault();
                              }
                            }} onChange={(e) => setFormData({ ...formData, bloodPressure: e.target.value })} />
                          {errors.bloodPressure && <p className="text-red-500">{errors.bloodPressure}</p>}
                        </div>
                        <div className="flex-1">
                          <label className="block text-sm font-bold text-gray-700">Temperature (°C)</label>
                          <input type="text" placeholder="Enter Height" className="mt-1 p-2 border rounded w-full" pattern="[0-9]+([.][0-9]+)?" title="Enter a value"
                            onKeyPress={(e) => {
                              const charCode = e.which ? e.which : e.keyCode;
                              if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
                                e.preventDefault();
                              }
                            }} onChange={(e) => setFormData({ ...formData, temperature: e.target.value })} />
                          {errors.temperature && <p className="text-red-500">{errors.temperature}</p>}
                        </div>
                      </div>

                      <div className="space-y-4">
                        <div className="flex space-x-4">
                          <div className="flex-1">
                            <label className="block text-sm font-bold text-gray-700">Medical Affliction</label>
                            <textarea placeholder="" className="mt-1 p-2 border rounded w-full" onChange={(e) => setFormData({ ...formData, currentProblem: e.target.value })}
                            ></textarea>
                          </div>
                          <div className="flex-1">
                            <label className="block text-sm font-bold text-gray-700">Past Checkup</label>
                            <textarea placeholder="" className="mt-1 p-2 border rounded w-full" onChange={(e) => setFormData({ ...formData, pastmedicalhistory: e.target.value })}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div>
                      <div className="p-4 border rounded-md shadow-md flex w-full">
                        <div className="flex-1 w-1/2">
                          <label className="block mb-2 text-lg font-semibold">Allergies</label>
                          <div className="mb-4">
                            <input
                              type="radio"
                              name="allergies"
                              value="yes"
                              className="mr-2"
                              onChange={() => setshowAllergyInput(true)}
                            />
                            <label>Yes</label>

                            <input
                              type="radio"
                              name="allergies"
                              value="no"
                              className="ml-4 mr-2"
                              onChange={() => setshowAllergyInput(false)}
                              onClick={() => {
                                setinputAllergyValue('');
                                settableAllergyData([]); // Clear the table data when 'No' is clicked
                              }}
                            />
                            <label>No</label>
                          </div>

                          {showAllergyInput && (
                            <div className="mb-4 flex items-center">
                              <div>
                                <select
                                  className="p-2 border w-11/15 rounded-md"
                                  onChange={(e) => setSelectedAllergy(e.target.value)}
                                  value={selectedAllergy}
                                >
                                  <option value="">Select Allergy Type</option>
                                  <option value="Food Allergy">Food Allergy</option>
                                  <option value="Pet Allergy">Pet Allergy</option>
                                  <option value="Drug Allergy">Drug Allergy</option>
                                  <option value="Pollen Allergyy">Pollen Allergy</option>
                                  <option value="Latex Allergy">Latex Allergy</option>
                                  <option value="Mold Allergy">Mold Allergy</option>
                                  <option value="Insect and Pest Allergy">Insect and Pest Allergy</option>
                                  <option value="Others">Others</option>
                                </select>
                              </div>

                              <input
                                type="text"
                                value={inputAllergyValue}
                                onChange={(e) => setinputAllergyValue(e.target.value)}
                                className="p-2 border rounded-md focus:outline-none focus:border-blue-500 ml-2"
                                placeholder="Enter allergy description"
                              />

                              <button
                                onClick={handleAddToTable}
                                className="px-4 py-2 ml-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                              >
                                Add
                              </button>
                            </div>
                          )}
                        </div>

                        {tableAllergyData.length > 0 && (
                          <div className="flex-1 ml-4">
                            <table className="w-6/7 border-collapse border">
                              <thead>
                                <tr>
                                  <th className="border p-2 text-center">S.No</th>
                                  <th className="border p-2 text-center">Allergy</th>
                                  <th className="border p-2 text-center">Description</th>
                                  <th className="border p-2 text-center">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tableAllergyData.map((item, index) => (
                                  <tr key={index}>
                                    <td className="border p-2 text-center">{index + 1}</td>
                                    <td className="border p-2 text-center">{item.type}</td>
                                    <td className="border p-2 text-center">{item.details}</td>
                                    <td className="border p-2 text-center">
                                      <FontAwesomeIcon
                                        icon={faTimes}
                                        onClick={() => {
                                          const updatedData = [...tableAllergyData];
                                          updatedData.splice(index, 1);
                                          settableAllergyData(updatedData);
                                        }}
                                        className="text-red-500 cursor-pointer"
                                        size="lg"
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>


                      <div className="p-4 border rounded-md shadow-md flex">
                        <div className="flex-1">
                          <label className="block mb-2 text-lg font-semibold">Chronic or significant medical conditions</label>
                          <div className="mb-4">
                            <input
                              type="radio"
                              name="Chronic"
                              value="yes"
                              className="mr-2"
                              onChange={() => setshowChronicInput(true)}
                            />
                            <label>Yes</label>

                            <input
                              type="radio"
                              name="Chronic"
                              value="no"
                              className="ml-4 mr-2"
                              onChange={() => setshowChronicInput(false)}
                              onClick={() => {
                                setinputChronicValue('');
                                settableChronicData([]); // Clear the table data when 'No' is clicked
                              }}
                            />
                            <label>No</label>
                          </div>

                          {showChronicInput && (
                            <div className="mb-4">
                              <input
                                type="text"
                                value={inputChronicValue}
                                onChange={(e) => setinputChronicValue(e.target.value)}
                                className="p-2 border rounded-md focus:outline-none focus:border-blue-500"
                                placeholder="Enter medical condition"
                              />
                              <button
                                onClick={handleChronicAddToTable}
                                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 ml-4"
                              >
                                Add
                              </button>
                            </div>
                          )}
                        </div>

                        {tableChronicData.length > 0 && (
                          <div className="flex-1 ml-4">
                            <table className="w-full border-collapse border">
                              <thead>
                                <tr>
                                  <th className="border p-2 text-center">S.No</th>
                                  <th className="border p-2 text-center">Chronic or Significant</th>
                                  <th className="border p-2 text-center">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tableChronicData.map((Chronic, index) => (
                                  <tr key={index}>
                                    <td className="border p-2 text-center">{index + 1}</td>
                                    <td className="border p-2 text-center">{Chronic}</td>
                                    <td className="border p-2 text-center">
                                      <FontAwesomeIcon
                                        icon={faTimes}
                                        onClick={() => settableChronicData(prevData => prevData.filter((_, i) => i !== index))}
                                        className="text-red-500 cursor-pointer"
                                        size="lg"
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>

                      <div className="p-4 border rounded-md shadow-md flex">
                        <div className="flex-1">
                          <label className="block mb-2 text-lg font-semibold">Treatment Before</label>
                          <div className="mb-4">
                            <input
                              type="radio"
                              name="Treatment"
                              value="yes"
                              className="mr-2"
                              onChange={() => setshowTreatmentInput(true)}
                            />
                            <label>Yes</label>

                            <input
                              type="radio"
                              name="Treatment"
                              value="no"
                              className="ml-4 mr-2"
                              onChange={() => setshowTreatmentInput(false)}
                              onClick={() => {
                                setinputTreatmentValue('');
                                settableTreatmentData([]);
                              }}
                            />
                            <label>No</label>
                          </div>

                          {showTreatmentInput && (
                            <div className="mb-4">
                              <input
                                type="text"
                                value={inputTreatmentValue}
                                onChange={(e) => setinputTreatmentValue(e.target.value)}
                                className="p-2 border rounded-md focus:outline-none focus:border-blue-500"
                                placeholder='Enter treatment'
                              />

                              <button
                                onClick={handleTreatmentAddToTable}
                                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 ml-4"                                            >
                                Add
                              </button>
                            </div>
                          )}
                        </div>

                        {tableTreatmentData.length > 0 && (
                          <div className="flex-1 ml-4">
                            <table className="w-full border-collapse border">
                              <thead>
                                <tr>
                                  <th className="border p-2 text-center">S.No</th>
                                  <th className="border p-2 text-center">Treatment</th>
                                  <th className="border p-2 text-center">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tableTreatmentData.map((Treatment, index) => (
                                  <tr key={index}>
                                    <td className="border p-2 text-center">{index + 1}</td>
                                    <td className="border p-2 text-center">{Treatment}</td>
                                    <td className="border p-2 text-center">
                                      <FontAwesomeIcon
                                        icon={faTimes}
                                        onClick={() => settableTreatmentData(prevData => prevData.filter((_, i) => i !== index))}
                                        className="text-red-500 cursor-pointer"
                                        size="lg"

                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>


                      <div className="p-4 border rounded-md shadow-md flex">
                        <div className="flex-1">
                          <label className="block mb-2 text-lg font-semibold"> Hospitalized Before</label>
                          <div className="mb-4">
                            <input
                              type="radio"
                              name="Hospitalized"
                              value="yes"
                              className="mr-2"
                              onChange={() => setshowHospitalizedInput(true)}
                            />
                            <label>Yes</label>

                            <input
                              type="radio"
                              name="Hospitalized"
                              value="no"
                              className="ml-4 mr-2"
                              onChange={() => setshowHospitalizedInput(false)}
                              onClick={() => {
                                setinputHospitalizedValue('');
                                settabletHospitalizedData([]); // Clear the table data when 'No' is clicked
                              }}
                            />
                            <label>No</label>
                          </div>

                          {showHospitalizedInput && (
                            <div className="mb-4">
                              <input
                                type="text"
                                value={inputHospitalizedValue}
                                onChange={(e) => setinputHospitalizedValue(e.target.value)}
                                className="p-2 border rounded-md focus:outline-none focus:border-blue-500"
                                placeholder="Enter details"
                              />
                              <button
                                onClick={handleHospitalizedAddToTable}
                                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 ml-4"                                            >
                                Add
                              </button>
                            </div>
                          )}
                        </div>

                        {tabletHospitalizedData.length > 0 && (
                          <div className="flex-1 ml-4">
                            <table className="w-full border-collapse border">
                              <thead>
                                <tr>
                                  <th className="border p-2 text-center">S.No</th>
                                  <th className="border p-2 text-center">Hospitalizedt</th>
                                  <th className="border p-2 text-center">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tabletHospitalizedData.map((Hospitalized, index) => (
                                  <tr key={index}>
                                    <td className="border p-2 text-center">{index + 1}</td>
                                    <td className="border p-2 text-center">{Hospitalized}</td>
                                    <td className="border p-2 text-center">
                                      <FontAwesomeIcon
                                        icon={faTimes}
                                        onClick={() => settabletHospitalizedData(prevData => prevData.filter((_, i) => i !== index))}
                                        className="text-red-500 cursor-pointer"
                                        size="lg"
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>


                      <div className="p-4 border rounded-md shadow-md flex">
                        <div className="flex-1">
                          <label className="block mb-2 text-lg font-semibold">Surgeries Undergone</label>
                          <div className="mb-4">
                            <input
                              type="radio"
                              name="Surgeon"
                              value="yes"
                              className="mr-2"
                              onChange={() => setshowSurgeonInput(true)}
                            />
                            <label>Yes</label>

                            <input
                              type="radio"
                              name="Surgeon"
                              value="no"
                              className="ml-4 mr-2"
                              onChange={() => setshowSurgeonInput(false)}
                              onClick={() => {
                                setinputSurgeonValue('');
                                settabletSurgeonData([]); // Clear the table data when 'No' is clicked
                              }}
                            />
                            <label>No</label>
                          </div>

                          {showSurgeonInput && (
                            <div className="mb-4">
                              <input
                                type="text"
                                value={inputSurgeonValue}
                                onChange={(e) => setinputSurgeonValue(e.target.value)}
                                className="p-2 border rounded-md focus:outline-none focus:border-blue-500"
                                placeholder="Enter details"
                              />
                              <button
                                onClick={handleSurgeonAddToTable}
                                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 ml-4"                                            >
                                Add
                              </button>
                            </div>
                          )}
                        </div>

                        {tabletSurgeonData.length > 0 && (
                          <div className="flex-1 ml-4">
                            <table className="w-full border-collapse border">
                              <thead>
                                <tr>
                                  <th className="border p-2 text-center">S.No</th>
                                  <th className="border p-2 text-center">Surgery</th>
                                  <th className="border p-2 text-center">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tabletSurgeonData.map((Surgeon, index) => (
                                  <tr key={index}>
                                    <td className="border p-2 text-center">{index + 1}</td>
                                    <td className="border p-2 text-center">{Surgeon}</td>
                                    <td className="border p-2 text-center">
                                      <FontAwesomeIcon
                                        icon={faTimes}
                                        onClick={() => settabletSurgeonData(prevData => prevData.filter((_, i) => i !== index))}
                                        className="text-red-500 cursor-pointer"
                                        size="lg"
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>

                      <div className="p-4 border rounded-md shadow-md flex">
                        <div className="flex-1">
                          <label className="block mb-2 text-lg font-semibold">Immunizations Details</label>
                          <div className="mb-4">
                            <input
                              type="radio"
                              name="Immunization"
                              value="yes"
                              className="mr-2"
                              onChange={() => setshowImmunizationInput(true)}
                            />
                            <label>Yes</label>

                            <input
                              type="radio"
                              name="Immunization"
                              value="no"
                              className="ml-4 mr-2"
                              onChange={() => setshowImmunizationInput(false)}
                              onClick={() => {
                                setinputImmunizationValue('');
                                settabletImmunizationData([]); // Clear the table data when 'No' is clicked
                              }}
                            />
                            <label>No</label>
                          </div>

                          {showImmunizationInput && (
                            <div className="mb-4">
                              <input
                                type="text"
                                value={inputImmunizationValue}
                                onChange={(e) => setinputImmunizationValue(e.target.value)}
                                className="p-2 border rounded-md focus:outline-none focus:border-blue-500"
                                placeholder="Enter details"
                              />
                              <button
                                onClick={handleImmunizationAddToTable}
                                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 ml-4"                                            >
                                Add
                              </button>
                            </div>
                          )}
                        </div>

                        {tabletImmunizationData.length > 0 && (
                          <div className="flex-1 ml-4">
                            <table className="w-full border-collapse border">
                              <thead>
                                <tr>
                                  <th className="border p-2 text-center">S.No</th>
                                  <th className="border p-2 text-center">Immunizations</th>
                                  <th className="border p-2 text-center">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tabletImmunizationData.map((Immunization, index) => (
                                  <tr key={index}>
                                    <td className="border p-2 text-center">{index + 1}</td>
                                    <td className="border p-2 text-center">{Immunization}</td>
                                    <td className="border p-2 text-center">
                                      <FontAwesomeIcon
                                        icon={faTimes}
                                        onClick={() => settabletImmunizationData(prevData => prevData.filter((_, i) => i !== index))}
                                        className="text-red-500 cursor-pointer"
                                        size="lg"
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>


                      <div className="p-4 border rounded-md shadow-md flex w-full">
                        <div className="flex-1 w-1/2">
                          <label className="block mb-2 text-lg  font-semibold">Medication History:</label>
                          <div className="mb-4">
                            <input type="radio" name="medication" value=" yes" onChange={() => setShowMedicationInput(true)} />
                            <label className="mr-2"> Yes</label>

                            <input type="radio" name="medication" value=" no" onChange={() => setShowMedicationInput(false)} onClick={() => {
                              setMedicine('');
                              setDosage('');
                              setMedications([]);
                            }} className="ml-4 mr-2" />
                            <label> No</label>
                          </div>

                          {showMedicationInput && (
                            <div className=' mb-4 flex items-center '>
                              <div>
                                <input type="text" value={medicine} placeholder='Medicine Name' onChange={(e) => setMedicine(e.target.value)} className="p-2 border    rounded-md focus:outline-none focus:border-blue-500 mr-2" />
                              </div>
                              <div className='flex items-center '>

                                <input type="text" placeholder='Dosage' value={dosage} onChange={(e) => setDosage(e.target.value)} className="p-2 border rounded-md focus:outline-none focus:border-blue-500 mr-2" />
                                <button onClick={handleAddMedication} className="px-4 py-2 mr-3 bg-blue-500 text-white rounded-md hover:bg-blue-600">
                                  Add
                                </button>
                              </div>

                            </div>
                          )}
                        </div>

                        {medications.length > 0 && (<div className="flex-1 ml-4 ">
                          <table className="w-6/7 border-collapse border">
                            <thead>
                              <tr>
                                <th className="border p-2 text-center">S.No</th>
                                <th className="border p-2 text-center">Medicine</th>
                                <th className="border p-2 text-center">Dosage</th>
                                <th className="border p-2 text-center">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {medications.map((item, index) => (
                                <tr key={item.id}>
                                  <td className="border p-2 text-center">{index + 1}</td>
                                  <td className="border p-2 text-center">{item.medicine}</td>
                                  <td className="border p-2 text-center">{item.dosage}</td>
                                  <td className="border p-2 text-center">
                                    <FontAwesomeIcon
                                      icon={faTimes}
                                      onClick={() => handleRemoveMedication(item.id)}
                                      className="text-red-500 cursor-pointer "
                                      size="lg"
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        )}
                      </div>


                      <div className="p-4 border rounded-md shadow-md flex">
                        <div className="flex-1">
                          <label className="block mb-2 text-lg font-semibold">Family History</label>
                          <div className="mb-4">
                            <input
                              type="radio"
                              name="Family_History"
                              value="yes"
                              className="mr-2"
                              onChange={() => setshowFamilyInput(true)}
                            />
                            <label>Yes</label>

                            <input
                              type="radio"
                              name="Family_History"
                              value="no"
                              className="ml-4 mr-2"
                              onChange={() => setshowFamilyInput(false)}
                              onClick={() => {
                                setinputFamilyValue('');
                                settabletFamilyData([]);
                              }}
                            />
                            <label>No</label>
                          </div>

                          {showFamilyInput && (
                            <div className="mb-4">
                              <input
                                type="text"
                                value={inputFamilyValue}
                                onChange={(e) => setinputFamilyValue(e.target.value)}
                                className="p-2 border rounded-md focus:outline-none focus:border-blue-500"
                                placeholder="Enter details"
                              />
                              <button
                                onClick={handleFamilyHisAddToTable}
                                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 ml-4"
                              >
                                Add
                              </button>
                            </div>
                          )}
                        </div>

                        {tabletFamilyData.length > 0 && (
                          <div className="flex-1 ml-4">
                            <table className="w-full border-collapse border">
                              <thead>
                                <tr>
                                  <th className="border p-2 text-center">S.No</th>
                                  <th className="border p-2 text-center">History</th>
                                  <th className="border p-2 text-center">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tabletFamilyData.map((Family_History, index) => (
                                  <tr key={index}>
                                    <td className="border p-2 text-center">{index + 1}</td>
                                    <td className="border p-2 text-center">{Family_History}</td>
                                    <td className="border p-2 text-center">
                                      <FontAwesomeIcon
                                        icon={faTimes}
                                        onClick={() => settabletFamilyData(prevData => prevData.filter((_, i) => i !== index))}
                                        className="text-red-500 cursor-pointer"
                                        size="lg"
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>

                      <div className="p-4 border rounded-md shadow-md flex space-x-4">
                        <div className="flex-1 space-y-4">
                          <label className="block mb-2 text-lg font-semibold">Social History</label>
                          <div className="flex mt-2 space-x-2">
                            {Object.keys(checkedSocialItems).map((item) => (
                              <div key={item} className="flex items-center">
                                <input
                                  type="checkbox"
                                  id={item}
                                  name={item}
                                  checked={checkedSocialItems[item]}
                                  onChange={handleSocialChange}
                                  className="mr-2"
                                />
                                <label htmlFor={item}>{item.charAt(0).toUpperCase() + item.slice(1)}</label>
                              </div>
                            ))}
                          </div>

                          {checkedSocialItems.others && (
                            <div className="mt-2 flex items-center space-x-2">
                              <input
                                type="text"
                                placeholder="Enter additional information"
                                value={newSocialItem}
                                onChange={(e) => setNewSocialItem(e.target.value)}
                                className="p-2 border rounded"
                              />
                              <button onClick={handleSocialAddItem} className="bg-blue-500 text-white p-2 rounded">
                                Add
                              </button>
                            </div>
                          )}
                        </div>

                        {isTableSocialVisible && (
                          <div className="flex-1">
                            <table className="w-full border-collapse border">
                              <thead>
                                <tr className="bg-gray-200">
                                  <th className="border p-2 text-center">S.No</th>
                                  <th className="border p-2 text-center">Social</th>
                                  <th className="border p-2 text-center">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tableSocialData.map((item, index) => (
                                  <tr key={index} className="border-b">
                                    <td className="border p-2 text-center">{index + 1}</td>
                                    <td className="border p-2 text-center">{item.social}</td>
                                    <td className="border p-2 text-center">
                                      <FontAwesomeIcon
                                        icon={faTimes}
                                        onClick={() => settableSocialData(tableSocialData.filter((_, i) => i !== index))}
                                        className="text-red-500 cursor-pointer"
                                        size="lg"
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>





                      <div className="p-4 border rounded-md shadow-md flex space-x-4">
                        <div>
                          <label className="block mb-2">Physical Examination Findings:</label>
                          <div className="flex items-center space-x-4">
                            <input type="radio" id="yesPhysicalExam" name="physicalExam" value="yes" onChange={() => setHasPhysicalExam(true)} />
                            <label htmlFor="yesPhysicalExam">Yes</label>
                            <input
                              type="radio"
                              id="noPhysicalExam"
                              name="physicalExam"
                              value="no"
                              onChange={() => handleNoPhysicalExam()}
                            />
                            <label htmlFor="noPhysicalExam">No</label>
                          </div>

                          {hasPhysicalExam && (
                            <div className="mt-4">
                              <label className="block mb-2">Diagnostic Tests and Results:</label>
                              {diagnosticTests.map((test, index) => (
                                <div key={index} className="flex items-center mb-2">
                                  {isUploadDisabled(index) ? (
                                    <div className="flex items-center">
                                      {/* Show a green checkmark for the uploaded message */}
                                      <FontAwesomeIcon icon={faCheck} className="text-green-500" />
                                    </div>
                                  ) : (
                                    <React.Fragment>
                                      <input
                                        type="file"
                                        onChange={(e) => handleDiagnosticTestChange(e.target.files[0], index)}
                                        id={`fileInput-${index}`}
                                        className="hidden"
                                      />

                                      <label
                                        htmlFor={`fileInput-${index}`}
                                        className={`bg-blue-500 text-white p-1 rounded cursor-pointer flex items-center ${isUploadDisabled(index) ? 'bg-gray-400 cursor-not-allowed' : ''}`}
                                      >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                        </svg>
                                        Upload
                                      </label>
                                    </React.Fragment>
                                  )}

                                  <span className="ml-2">{test && test.name}</span>

                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    onClick={() => handleRemoveDiagnosticTest(index)}
                                    className="text-red-500 cursor-pointer ml-4 "
                                    size="lg"
                                  />
                                </div>
                              ))}
                              <button className="bg-blue-500 text-white p-2 rounded" onClick={handleAddDiagnosticTest}>
                                Add More
                              </button>

                              <div className="mt-4">
                                <label className="block mb-2">Plan of Care:</label>
                                <textarea className="p-2 border rounded w-full" onChange={(e) => setFormData({ ...formData, planOfCare: e.target.value })}></textarea>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>



                  </div>
                </div>

                <div className="flex justify-end mt-4">
                  <div>
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4"
                      disabled={!selectedPatient} onClick={handleSubmit}
                    >
                      Submit
                    </button>
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                      onClick={() => {
                        fetchInitialData();
                        setSelectedPatient(null);
                        setshowAllergyInput(false);
                        setinputAllergyValue('');
                        settableAllergyData([]);
                        setshowChronicInput(false);
                        setinputChronicValue('');
                        settableChronicData([]);
                        setshowTreatmentInput(false);
                        setinputTreatmentValue('');
                        settableTreatmentData([]);
                        setshowSurgeonInput(false);
                        setinputSurgeonValue('');
                        settabletSurgeonData([]);
                        setshowHospitalizedInput(false);
                        setinputHospitalizedValue('');
                        settabletHospitalizedData([]);
                        setshowImmunizationInput(false);
                        setinputImmunizationValue('');
                        settabletImmunizationData([]);
                        setshowFamilyInput(false);
                        setinputFamilyValue('');
                        settabletFamilyData([]);
                        setShowMedicationInput(false);
                        setMedications([]);
                        setMedicine('');
                        setDosage('');
                        setCheckedSocialItems({
                          smoking: false,
                          alcohol: false,
                          drugs: false,
                          exercise: false,
                          others: false,
                          none: false,
                        });
                        setNewSocialItem('');
                        settableSocialData([]);
                        setisTableSocialVisible(false);
                        setDiagnosticTests([null]);
                        setOldPatientData([null]);
                      }}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {errorMessage && <p className="text-red-500">{errorMessage}</p>}

      {isAppointmentModalOpen && (
        <div className={`fixed inset-0 overflow-y-auto ${isAppointmentModalOpen ? 'backdrop-filter backdrop-blur-sm' : ''} transition-all duration-300 ${isAppointmentModalOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
          <div className="flex items-center justify-center h-screen">
            <div className="bg-white p-4 rounded shadow-lg transition-transform duration-300 transform scale-100 border-2 border-blue-500 max-h-full overflow-auto" style={{ top: '5%', height: '80%', width: '90%' }}>
              <div className="flex justify-end mb-2">
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={handleCloseAppointmentModal}
                  className="text-red-500 cursor-pointer"
                  size="lg"
                />
              </div>

              <div className="flex justify-between mb-4">
                <div>
                  <h2 className="text-3xl font-bold text-blue-500">Appointment</h2>
                </div>
                <div className="flex items-center space-x-2">
                  <input
                    type="text"
                    placeholder="Search UHID" value={uhidAppointFilter} onChange={handleSearchAppointUhid}
                    className="px-2 py-1 border border-gray-400 rounded"
                  />
                  <input
                    type="text"
                    placeholder="Search Department" value={departmentAppointFilter} onChange={handleSearchAppointDepartment}
                    className="px-2 py-1 border border-gray-400 rounded"
                  />
                  <label>Start Date:</label>
                  <input

                    type="date" value={startDateAppointFilter} onChange={handleStartDateAppointChange}
                    className="px-2 py-1 border border-gray-400 rounded"
                  />
                  <label>End Date:</label>
                  <input

                    type="date" value={endDateAppointFilter} onChange={handleEndDateAppointChange}
                    className="px-2 py-1 border border-gray-400 rounded"
                  />
                </div>
              </div>





              <table className="border-collapse bg-gray-300 ">
                <thead>
                  <tr>
                    <th className=" text-center px-4 py-2">S.NO</th>
                    <th className=" text-center px-4 py-2">UHID</th>
                    <th className=" text-center px-4 py-2">Appointment Date</th>
                    <th className=" text-center px-4 py-2">Patient Name</th>
                    <th className=" text-center px-4 py-2">Department</th>
                    <th className=" text-center px-4 py-2">Doctor Name</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAppointments.map((appointment, index) => (
                    <tr key={index}>
                      <td className=" px-4 py-2 text-center">{index + 1}</td>
                      <td className=" px-4 py-2 text-center">{appointment.uhid}</td>
                      <td className=" px-4 py-2 text-center">
                        {indFormattedDate(appointment.date)}
                      </td>
                      <td className=" px-4 py-2 text-center">{appointment.patient_name}</td>
                      <td className=" px-4 py-2 text-center">{appointment.department_name}</td>
                      <td className=" px-4 py-2 text-center">
                        {appointment.consultation_doctor}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {isHistoryModalOpen && (
        <div className={`fixed inset-0 overflow-y-auto ${isHistoryModalOpen ? 'backdrop-filter backdrop-blur-sm' : ''} transition-all duration-300 ${isHistoryModalOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
          <div className="flex items-center justify-center h-screen">
            <div className="bg-white p-4 rounded shadow-lg transition-transform duration-300 transform scale-100 border-2 border-blue-500 max-h-full overflow-auto" style={{ top: '5%', height: '80%', width: '90%' }}>
              <div className="flex justify-end mb-3">
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={handleCloseHistoryModal}
                  className="text-red-500 cursor-pointer"
                  size="lg"
                />
              </div>

              <div className="flex justify-between mb-4">
                <div>
                  <h2 className="text-3xl font-bold text-blue-500">Outpatient History</h2>
                </div>
                <div className="flex items-center space-x-2">
                  <input
                    type="text"
                    placeholder="Search by UHID"
                    value={uhidHistorySearch}
                    onChange={(e) => setUhidHistorySearch(e.target.value)}
                    className="px-2 py-1 border border-gray-400 rounded"
                  />
                  <input
                    type="text"
                    placeholder="Search by Department"
                    value={departmentHistorySearch}
                    onChange={(e) => setDepartmentHistorySearch(e.target.value)}
                    className="px-2 py-1 border border-gray-400 rounded"
                  />
                  <label>Start Date:</label>
                  <input
                    type="date"
                    value={startHistoryDate.toISOString().split('T')[0]}
                    onChange={(e) => setStartHistoryDate(new Date(e.target.value))}
                    className="px-2 py-1 border border-gray-400 rounded"
                  />
                  <label>End Date:</label>
                  <input
                    type="date"
                    value={endHistoryDate.toISOString().split('T')[0]}
                    onChange={(e) => setEndHistoryDate(new Date(e.target.value))}
                    className="px-2 py-1 border border-gray-400 rounded"
                  />
                </div>
              </div>

              <table className="border-collapse bg-gray-300 ">
                <thead>
                  <tr>
                    <th className=" text-center px-4 py-2">S.NO</th>
                    <th className=" text-center px-4 py-2">UHID</th>
                    <th className=" text-center px-4 py-2">Date</th>
                    <th className=" text-center px-4 py-2">Patient Name</th>
                    <th className=" text-center px-4 py-2">Department</th>
                    <th className=" text-center px-4 py-2">Doctor Name</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredPatientHistory.map((patienthistory, index) => (
                    <tr key={index}>
                      <td className=" px-4 py-2 text-center">{index + 1}</td>
                      <td className=" px-4 py-2 text-center">{patienthistory.uhid}</td>
                      <td className=" px-4 py-2 text-center">
                        {indFormattedDate(patienthistory.date)}
                      </td>
                      <td className=" px-4 py-2 text-center">{patienthistory.patient_name}</td>
                      <td className=" px-4 py-2 text-center">{patienthistory.department}</td>
                      <td className=" px-4 py-2 text-center">
                        {patienthistory.doctor_name}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>



            </div>
          </div>
        </div>
      )}

      {isStaticsModalOpen && (
        <div className={`fixed inset-0 overflow-y-auto ${isStaticsModalOpen ? 'backdrop-filter backdrop-blur-sm' : ''} transition-all duration-300 ${isStaticsModalOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
          <div className="flex items-center justify-center h-screen">
            <div className="bg-white p-4 rounded shadow-lg transition-transform duration-300 transform scale-100 border-2 border-blue-500 max-h-full overflow-auto" style={{ top: '3%', height: '90%', width: '90%' }}>
              <div className="flex justify-between items-center mb-3">
                <h2 className="text-3xl font-bold text-blue-500">Statistics</h2>
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={handleCloseStaticsModal}
                  className="text-red-500 cursor-pointer"
                  size="lg"
                />
              </div>
              {renderChartWithDelay()}
              {delayedRender ? (<div>   <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-4 flex items-center"
                onClick={() => {
                  handleOpenReportModal();
                  fetchHistoryData();
                }}
              >
                <FontAwesomeIcon icon={faChartBar} className="mr-2" />
                <span>Day Reports</span>
              </button> <Opbarchart data={patientHistory} /></div>) : (

                <LoadingSpinner />

              )
              }

            </div>
          </div>
        </div>
      )}

      {isReport && (
        <div className={`fixed inset-0 overflow-y-auto ${isStaticsModalOpen ? 'backdrop-filter backdrop-blur-sm' : ''} transition-all duration-300 ${isStaticsModalOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
          <div className="flex items-center justify-center h-screen">
            <div className="bg-white p-4 rounded shadow-lg transition-transform duration-300 transform scale-100 border-2 border-blue-500 max-h-full overflow-auto" style={{ top: '3%', height: '80%', width: '80%' }}>
              <div className="flex justify-between items-center mb-3">
                <h2 className="text-3xl font-bold text-blue-500">Daily Reports</h2>
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={handleCloseReportModal}
                  className="text-red-500 cursor-pointer"
                  size="lg"
                />
              </div>
              {renderChartWithDelay()}
              {delayedRender ? (<div> <OpfilterDayBarchart data={patientHistory} userDepartment={parsedUserData.user.user_department} /></div>) : (

                <LoadingSpinner />

              )
              }


            </div>
          </div>
        </div>)}

    </div>
  );
};

export default Outpatient;