import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from './Components/login';
import HomePage from './Components/HomeComponent/homepage';
import Dashboard from './Components/HomeComponent/dashboard';
import Registration from './Components/FrontofficeComponent/registration';
import AdmissionForm from './Components/FrontofficeComponent/admission';
import ConsultantForm from './Components/FrontofficeComponent/consultation';
import BillingModule from './Components/BillingComponent/Billing';
import RegistrationBilling from './Components/BillingComponent/registrationbill';
import FrontOffice from './Components/FrontofficeComponent/frontoffice';
import RadiologyBilling from './Components/BillingComponent/radiologybill';
import LabBilling from './Components/BillingComponent/labbilling';
import OutpatientModule from './Components/NurseStation/opmodule';
import Outpatient from './Components/NurseStation/opregitration';
import DoctorModule from './Components/DoctorComponent/doctormodule';
import OutpatientsList from './Components/DoctorComponent/oppatientlist';
import HRManagement from './Components/HRComponent/hrmodules';
import RegistrationForm from './Components/HRComponent/registrationform';
import DoctorRegistrationForm from './Components/HRComponent/doctorregister';
import StaffRegistrationForm from './Components/HRComponent/staffregister';
import PharmacyModule from './Components/PharmacyComponent/pharmacymodule';
import StockDetailsPage from './Components/PharmacyComponent/stock';
import Billing from './Components/PharmacyComponent/pharmacybilling';
import BillingHis from './Components/PharmacyComponent/billinghistory';
import Purchase from './Components/PurchaseComponent/purchasehistory';
import PurchaseModule from './Components/PurchaseComponent/purchasemodule';
import PurchaseForm from './Components/PurchaseComponent/purchaseform'
import BillingHistory from './Components/BillingComponent/opbillhistory';
import LabManagement from './Components/LabComponent/labmodule';
import AvailableTests from './Components/LabComponent/availabletests';
import CategoryForm from './Components/LabComponent/testcategory';
import DoctorSuggestions from "./Components/NurseStation/doctorsuggestion"
import CardiologyBilling from './Components/BillingComponent/cardiology';
import PaidLabList from './Components/LabComponent/labtestlist';

// Function to check if the user is authenticated
const isAuthenticated = () => {
  return JSON.parse(localStorage.getItem('user')) !== null;
};

// Protected route component
const ProtectedRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/" replace />;
};

function App() {
  return (
    <div className="App">
      <Routes>
        {/* Login Route */}
        <Route path="/" element={<Login />} />

        {/* Dashboard Route */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <HomePage>
                <Dashboard />
              </HomePage>
            </ProtectedRoute>
          }
        />

        {/* Front Office Routes with Subpages */}
        <Route
          path="/front-office"
          element={
            <ProtectedRoute>
              <HomePage>
                <FrontOffice />
              </HomePage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/front-office/registration"
          element={
            <ProtectedRoute>
              <HomePage>
                <Registration />
              </HomePage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/front-office/admission"
          element={
            <ProtectedRoute>
              <HomePage>
                <AdmissionForm />
              </HomePage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/front-office/consultation"
          element={
            <ProtectedRoute>
              <HomePage>
                <ConsultantForm />
              </HomePage>
            </ProtectedRoute>
          }
        />

        <Route
          path="/billing"
          element={
            <ProtectedRoute>
              <HomePage>
                <BillingModule />
              </HomePage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/billing/registration"
          element={
            <ProtectedRoute>
              <HomePage>
                <RegistrationBilling />
              </HomePage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/billing/LabBilling"
          element={
            <ProtectedRoute>
              <HomePage>
                <LabBilling />
              </HomePage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/billing/radiology"
          element={
            <ProtectedRoute>
              <HomePage>
                <RadiologyBilling />
              </HomePage>
            </ProtectedRoute>
          }
        />
          <Route
          path="/billing/cardiology"
          element={
            <ProtectedRoute>
              <HomePage>
                <CardiologyBilling />
              </HomePage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/billing/billhistory"
          element={
            <ProtectedRoute>
              <HomePage>
                <BillingHistory />
              </HomePage>
            </ProtectedRoute>
          }
        />

        <Route
          path="/nurse-station"
          element={
            <ProtectedRoute>
              <HomePage>
                <OutpatientModule />
              </HomePage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/nurse-station/details"
          element={
            <ProtectedRoute>
              <HomePage>
                <Outpatient />
              </HomePage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/nurse-station/doctorsuggestions"
          element={
            <ProtectedRoute>
              <HomePage>
                <DoctorSuggestions/>
              </HomePage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor"
          element={
            <ProtectedRoute>
              <HomePage>
                <DoctorModule />
              </HomePage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor/oplist"
          element={
            <ProtectedRoute>
              <HomePage>
                <OutpatientsList />
              </HomePage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/hr"
          element={
            <ProtectedRoute>
              <HomePage>
                <HRManagement />
              </HomePage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/hr/registrationForm"
          element={
            <ProtectedRoute>
              <HomePage>
                <RegistrationForm />
              </HomePage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/hr/staffregister"
          element={
            <ProtectedRoute>
              <HomePage>
                <StaffRegistrationForm />
              </HomePage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/hr/doctorregister"
          element={
            <ProtectedRoute>
              <HomePage>
                <DoctorRegistrationForm />
              </HomePage>
            </ProtectedRoute>
          }
        />

        <Route
          path="/pharmacy"
          element={
            <ProtectedRoute>
              <HomePage>
                <PharmacyModule />
              </HomePage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/pharmacy/stock"
          element={
            <ProtectedRoute>
              <HomePage>
                <StockDetailsPage />
              </HomePage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/pharmacy/bill"
          element={
            <ProtectedRoute>
              <HomePage>
                <Billing />
              </HomePage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/pharmacy/billhistory"
          element={
            <ProtectedRoute>
              <HomePage>
                <BillingHis />
              </HomePage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/purchase"
          element={
            <ProtectedRoute>
              <HomePage>
                <PurchaseModule />
              </HomePage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/purchase/purchaseform"
          element={
            <ProtectedRoute>
              <HomePage>
                <PurchaseForm />
              </HomePage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/purchase/history"
          element={
            <ProtectedRoute>
              <HomePage>
                <Purchase />
              </HomePage>
            </ProtectedRoute>
          }
        />

        <Route
          path="/lab"
          element={
            <ProtectedRoute>
              <HomePage>
                <LabManagement />
              </HomePage>
            </ProtectedRoute>
          }
        />

        {/* <Route
          path="/lab/inventory"
          element={
            <ProtectedRoute>
              <HomePage>
                <LabInventory />
              </HomePage>
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/lab/category"
          element={
            <ProtectedRoute>
              <HomePage>
                <CategoryForm />
              </HomePage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/lab/availabletests"
          element={
            <ProtectedRoute>
              <HomePage>
                <AvailableTests />
              </HomePage>
            </ProtectedRoute>
          }
        />
         <Route
          path="/lab/labtestlist"
          element={
            <ProtectedRoute>
              <HomePage>
                <PaidLabList />
              </HomePage>
            </ProtectedRoute>
          }
        />
        {/* Catch-all Route */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}

export default App;
