import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch, faSync, faEnvelope, faInbox, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { useReactToPrint } from "react-to-print";
import config from "../../config";
import { useNavigate } from "react-router-dom";

function Billing() {
  const [medicineRows, setMedicineRows] = useState(
    Array.from({ length: 3 }, (_, index) => ({ id: index + 1 }))
  );
  const navigate = useNavigate();
  const [submittedData, setSubmittedData] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const inputRefs = useRef([]);
  const [loader, setLoader] = useState(false);
  const [cashGiven, setCashGiven] = useState("0");
  const [balance, setBalance] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [suggestions, setSuggestions] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [buttonText, setButtonText] = useState("Add More Medicine");
  const [patientName, setPatientName] = useState("");
  const [requestedQuantities, setRequestedQuantities] = useState({});
  const [availableQuantities, setAvailableQuantities] = useState({});
  const [isAlertActive, setAlertActive] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isViewed, setisViewed] = useState(false);
  const [medicineName, setMedicineName] = useState("");
  const [brandName, setBrandName] = useState([]);
  const [frequency, setFrequency] = useState("");
  const [backendData, setBackendData] = useState("");
  const [calculatedQuantity, setCalculatedQuantity] = useState(0);
  const [mrp, setMrp] = useState("");
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPage = 13;
  const [instructions, setInstructions] = useState("");
  const [optMrg, setOptMrng] = useState("");
  const [optAft, setOptAft] = useState("");
  const [optEve, setOptEve] = useState("");
  const [optNyt, setOptNyt] = useState("");
  const [total, setTotal] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [discount, setDiscountTotal] = useState(0);
  const [grandtotal, setGrandTotal] = useState(0);
  const componentRef = useRef(null);
  const rowsPerPage = 13;
  const [errorMessages, setErrorMessages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showErrorButton, setShowErrorButton] = useState(false);
  const [uhid, setUhid] = useState("");
  const [age, setAge] = useState("");
  const [doctorname, setDoctorName] = useState("");
  const [editedQuantity, setEditedQuantity] = useState([]);
  const editedQuantitiesRef = useRef([]);
  const [brandSuggestions, setBrandSuggestions] = useState([]);
  const [medicineNameSelected, setMedicineNameSelected] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState('');

  const totalPages = Math.ceil(submittedData.length / rowsPerPage);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const fetchpharmacyData = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/doctor/getPrescription`
      );
      setPrescriptionData(response.data);
    } catch (error) {
      console.log("Error fetching data");
    }
  };

  useEffect(() => {
    fetchpharmacyData();
    fetchSuggestions();

  }, []);

  const fetchSuggestions = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/pharmacy/suggestions`);
      const fetchedSuggestions = response.data;
      setSuggestions(fetchedSuggestions);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const filteredData = prescriptionData.filter(
    (item) =>
      item.uhid &&
      item.uhid.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const dataOnCurrentPage = filteredData.slice(startIndex, endIndex);
  const showAlert = (message, type, duration = 3000) => {
    console.log("Showing alert:", message, type);

    setAlert({ message, type });

    setTimeout(() => {
      console.log("Hiding alert");
      setAlert({ message: "", type: "" });
    }, duration);
  };

  useEffect(() => {
    const handleResize = () => {
      setButtonText(
        window.innerWidth <= 767 ? "Add More" : "Add More Medicine"
      );
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setMedicineRows((prevRows) => {
      const newRows = prevRows.map((row) => ({
        ...row,
        refs: Array.from(
          { length: 4 },
          (_, i) => inputRefs.current[row.id]?.[i] || null
        ),
      }));
      return newRows;
    });
  }, []);

  useEffect(() => {
    if (cashGiven !== "" && grandtotal !== "") {
      const newCashGiven = parseFloat(cashGiven) || "";
      const newBalance = (newCashGiven - grandtotal).toFixed(2);
      setBalance(newBalance);
    }
  }, [cashGiven, grandtotal]);

  const afterSubmit = () => {
    setIsLoading(true);

    setTimeout(() => {
      showAlert("Form submitted successfully!", "success");
      setIsButtonDisabled(true);
      setIsLoading(false);
    }, 2000);
  };

  const currentDateFormatted = new Date().toLocaleDateString("en-GB", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });

  const handleQuantityChange = (e, rowIndex) => {
    const newEditedQuantity = [...editedQuantity];
    newEditedQuantity[rowIndex] = e.target.value;
    setEditedQuantity(newEditedQuantity);
    handleTotal()
  };

  const handleQuantity = async (event, rowIndex, colIndex, id, editedQuantity) => {
    const input = inputRefs.current[id]?.[8];
    const totalInput = inputRefs.current[id]?.[10];

    const qtyValue = editedQuantity !== undefined ? editedQuantity : input.value.trim();

    input.value = qtyValue.replace(/\D/g, "");
    const qty = Math.floor(parseFloat(qtyValue)) || 0;
    const qtyprice = parseFloat(inputRefs.current[id]?.[9].value) || 0;
    console.log('medicine', medicineRows);

    const total = qty * qtyprice;
    setTotal(total);
    console.log('tot', total);



    if (totalInput) {
      totalInput.value = total.toFixed(2);

    }

    editedQuantitiesRef.current = [...(editedQuantitiesRef.current || []), total];

    const tabletname = inputRefs.current[id]?.[1].value || "";
    const { medicinename, dosage } = extractMedicineInfo(tabletname);
    const brand = selectedBrand;

    try {
      const response = await axios.get(
        `${config.apiUrl}/pharmacy/quantity?medicinename=${medicinename}&dosage=${dosage}&brandname=${brand}`
      );
      const availableQuantity = response.data.availableQuantity;

      const requestedQuantityForMedicine = requestedQuantities[tabletname] || 0;

      const qtyInput = inputRefs.current[id]?.[1];
      const prevQty = parseInt(qtyInput.dataset.prevValue) || 0;
      const newQty = qty;

      const diffQty = newQty - prevQty;

      if (diffQty + requestedQuantityForMedicine > availableQuantity) {
        const remainingQuantity =
          availableQuantity - requestedQuantityForMedicine;
        showAlert(
          `Available Quantity for ${tabletname} is ${remainingQuantity + prevQty
          }`
        );

        qtyInput.value = "";
        totalInput.value = "";
        setAlertActive(true);

        updateAvailableQuantities(tabletname, prevQty);
      } else {
        setRequestedQuantities((prevQuantities) => ({
          ...prevQuantities,
          [tabletname]: (prevQuantities[tabletname] || 0) + diffQty,
        }));

        qtyInput.dataset.prevValue = newQty;
        setAlertActive(false);
      }
      handleTotal();
    } catch (error) {
      console.error("Error fetching available quantity:", error);
    }

    setMedicineRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, total } : row))
    );
  };

  function handlePatientNameChange(event) {
    const newName = event.target.value;
    const regex = /^[a-zA-Z ]+$/;
    setPatientName(newName);
    if (!regex.test(newName)) {
      event.target.value = newName.slice(0, -1);
    }
  }

  const handleTotal = () => {
    if (isAlertActive) {
      return;
    }

    // Calculate the subtotal of the new row
    const newSubtotal = parseFloat(medicineRows.reduce((acc, row) => acc + (row.total || 0), 0).toFixed(2));
    console.log("medicineRows total", medicineRows);

    // Calculate the subtotal of all rows including the new row
    console.log("newSubtotal", newSubtotal);

    setSubtotal(newSubtotal.toFixed(2));

    // Recalculate the grand total
    const newGrandTotal = (newSubtotal - discount).toFixed(2);
    setGrandTotal(newGrandTotal);
  };


  const extractMedicineInfo = (tabletname) => {
    const lastSpaceIndex = tabletname.lastIndexOf(" ");

    if (lastSpaceIndex !== -1) {
      const dosage = tabletname.substring(lastSpaceIndex + 1);
      const medicinename = tabletname.substring(0, lastSpaceIndex);
      return { medicinename, dosage };
    } else {
      return { medicinename: "", dosage: "" };
    }
  };

  const handleSuggestionSelect = async (slectedmedicinename, selectedSuggestion, id, rowIndex) => {
    try {

      const { medicinename, dosage } = extractMedicineInfo(slectedmedicinename);

      const brand = selectedBrand; // Use the selected brand from state
      const mrpResponse = await axios.get(
        `${config.apiUrl}/pharmacy/getMRP?medicinename=${medicinename}&dosage=${dosage}&brandname=${brand}`
      );

      const mrp = mrpResponse.data.mrp;

      if (mrp !== undefined) {
        const qtyPriceInput = inputRefs.current[id]?.[9];
        if (qtyPriceInput) {
          qtyPriceInput.value = mrp || "";
        }
      }
    } catch (error) {
      console.error("Error fetching MRP:", error);
    }
  };


  const handleMedicineSuggestionSelect = async (selectedMedicine, selectedDosage, id) => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/pharmacy/stock/${selectedMedicine}/${selectedDosage}`
      );
      const fetchedBrandSuggestions = response.data;
      setBrandSuggestions(fetchedBrandSuggestions);
      setSelectedBrand('');
    } catch (error) {
      console.error("Error fetching brand suggestions:", error);
    }
  };

  const handleMedicineInputChange = (event, id) => {
    const selectedValue = event.target.value;

    const { medicinename, dosage } = extractMedicineInfo(selectedValue);
    setMedicineName(prevMedicineName => {
      const updatedMedicineName = [...prevMedicineName, selectedValue];
      return updatedMedicineName;
    });

    handleMedicineSuggestionSelect(medicinename, dosage, id);
  };

  const handleBrandNameChange = (event, rowIndex) => {
    const { value } = event.target;
    setBrandName((prevState) => {
      const newState = [...prevState];
      newState[rowIndex] = value;
      return newState;
    });
    setSelectedBrand(value); // Update selected brand
  };

  const handleKeyPress = async (event, rowIndex, colIndex, id) => {
    const medicineNameInput = inputRefs.current[id]?.[1];
    const qtyPriceInput = inputRefs.current[id]?.[9];
    const empty = medicineNameInput?.value || "";

    if (empty.trim() === "") {
      return;
    }

    if (event.target.tagName.toLowerCase() === "input") {
      event.preventDefault();
      if (colIndex === 0 || colIndex === 1 || colIndex === 2) {
        const tabletname = inputRefs.current[id]?.[1].value || "";
        const { medicinename, dosage } = extractMedicineInfo(tabletname);
        const brandname = selectedBrand
        if (event.target.id === `medicinename${id}`) {
          try {
            const response = await axios.get(
              `${config.apiUrl}/pharmacy/allstock?medicinename=${medicinename}&dosage=${dosage}&brandname=${brandname}`
            );
            const expired = response.data.expired;

            if (expired) {
              const expiredDate = new Date(expired);
              const expiredDateString = expiredDate.toISOString().split("T")[0];
              showAlert(
                `${medicinename} ${dosage} expired on ${expiredDateString} !`
              );
              clearRow(id);
            }
          } catch (error) {
            if (event.target.id !== "") {
              showAlert(`"${tabletname}" Medicine not available.`);
              clearRow(id);
            }
          }
        }
      }
    }
  };

  const clearRow = (id) => {
    const medicineNameInput = inputRefs.current[id]?.[1];
    const qtyInput = inputRefs.current[id]?.[8];
    const qtyPriceInput = inputRefs.current[id]?.[9];
    const totalInput = inputRefs.current[id]?.[10];

    if (medicineNameInput) {
      medicineNameInput.value = "";
    }
    if (qtyInput) {
      qtyInput.value = "";
    }
    if (qtyPriceInput) {
      qtyPriceInput.value = "";
    }
    if (totalInput) {
      totalInput.value = "";
    }
  };

  const handleAddMedicine = () => {
    const newId = medicineRows.length > 0 ? medicineRows[medicineRows.length - 1].id + 1 : 1;
    inputRefs.current[newId] = Array.from({ length: 11 }, (_, i) => null); // Update the length as needed

    const newRow = { id: newId, refs: inputRefs.current[newId] };

    // Check if the data property is defined
    if (newRow.data) {
      const { data } = newRow;

      newRow.refs[10].input.value = total
      console.log("newRow.refs[10].input.value", newRow.refs[10].input.value);
      // Update subtotal and grand total
      const newSubtotal = subtotal + total;
      setSubtotal(newSubtotal);

      const newGrandTotal = newSubtotal - discount;
      setGrandTotal(newGrandTotal);
    } else {
      console.error("Data is undefined for the new row:", newRow);
    }

    // Update the state with the new row
    setMedicineRows((prevRows) => [...prevRows, newRow]);

    const nextInput = inputRefs.current[newId][0];
    if (nextInput) {
      nextInput.focus();
    }
  };

  const handleCashGivenChange = (event) => {
    const newCashGiven = event.target.value.replace(/[^\d]/g, "");
    setCashGiven(newCashGiven);
  };

  const handleCashGivenBlur = () => {
    const formattedValue = cashGiven.replace(/[^\d.]/g, "").replace(/^0+/, "");
    setCashGiven(formattedValue === "" ? "0" : formattedValue);
  };

  const handleDiscountBlur = () => {
    const discountValue = parseFloat(discount);
    if (isNaN(discountValue)) {
      setDiscountTotal(0);
    } else {
      const formattedValue = discountValue.toFixed(2);
      setDiscountTotal(formattedValue);
    }
  };

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
  };

  const handleDiscountChange = (event) => {
    const newDiscountTotal = parseInt(event.target.value, 10) || 0;
    setDiscountTotal(newDiscountTotal);

    const newGrandTotal = subtotal - newDiscountTotal;
    setGrandTotal(newGrandTotal);
  };

  const updateAvailableQuantities = (medicinename, quantity) => {
    setAvailableQuantities((prevQuantities) => {
      const updatedQuantities = { ...prevQuantities };
      updatedQuantities[medicinename] =
        (prevQuantities[medicinename] || 0) + quantity;

      return updatedQuantities;
    });
  };

  const handleRemoveMedicine = (id) => {
    setMedicineRows((prevRows) => {
      const removedMedicine = prevRows.find((row) => row.id === id);

      if (removedMedicine && removedMedicine.refs && removedMedicine.refs[0]) {
        const medicinename = removedMedicine.refs[0].value;
        const qty = removedMedicine.refs[8].value;

        updateAvailableQuantities(medicinename, +qty);
        const updatedQuantities = { ...requestedQuantities };
        if (updatedQuantities[medicinename] !== undefined) {
          updatedQuantities[medicinename] -= qty;
        } else {
          updatedQuantities[medicinename] = -qty;
        }
        setRequestedQuantities(updatedQuantities);
      }

      const updatedRows = prevRows.filter((row) => row.id !== id);
      console.log("updatedRows", updatedRows);
      setMedicineName((prevNames) => prevNames.filter((name, index) => index !== id - 1));
      setInstructions((prevInstructions) => prevInstructions.filter((instruction, index) => index !== id - 1));
      setFrequency((prevFrequency) => prevFrequency.filter((freq, index) => index !== id - 1));
      setOptMrng((prevOptMrng) => prevOptMrng.filter((mrng, index) => index !== id - 1));
      setOptAft((prevOptAft) => prevOptAft.filter((aft, index) => index !== id - 1));
      setOptEve((prevOptEve) => prevOptEve.filter((eve, index) => index !== id - 1));
      setOptNyt((prevOptNyt) => prevOptNyt.filter((nyt, index) => index !== id - 1));
      setCalculatedQuantity((prevQuantity) => prevQuantity.filter((quantity, index) => index !== id - 1));
      setMrp((prevMrp) => prevMrp.filter((mrp, index) => index !== id - 1));
      setTotal((prevTotal) => prevTotal.filter((total, index) => index !== id - 1));

      const updatedSubtotal = updatedRows.reduce(
        (acc, current) => acc + current.mrp * calculatedQuantity[current.id - 1],
        0
      );
      const updatedGrandTotal = updatedSubtotal - discount;

      setSubtotal(updatedSubtotal);
      setGrandTotal(updatedGrandTotal);
      return updatedRows;
    });
  };

  const handlePdf = async () => {
    setLoader(true);
    const html2canvasOptions = {
      scale: 2,
      logging: false,
      allowTaint: true,
    };

    const jsPDFOptions = {
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    };

    const doc = new jsPDF(jsPDFOptions);

    const pages = document.querySelectorAll(".bill");

    for (let i = 0; i < pages.length; i++) {
      const capture = pages[i];

      const canvas = await html2canvas(capture, html2canvasOptions);
      const imgData = canvas.toDataURL("image/png");

      const imageWidth = 180;
      const imageHeight = (canvas.height * imageWidth) / canvas.width;

      const marginLeft = (doc.internal.pageSize.width - imageWidth) / 2;
      const marginTop = (doc.internal.pageSize.height - imageHeight) / 2;

      if (i > 0) {
        doc.addPage();
      }

      doc.addImage(
        imgData,
        "PNG",
        marginLeft,
        marginTop,
        imageWidth,
        imageHeight
      );
    }

    setLoader(false);
    doc.save("bill.pdf");
  };

  const handleWhatsApp = () => {
    const phoneNumber = `${countryCode}${mobileNo}`;
    let message = `Hello ${patientName}! Your bill details:\n`;

    message += `Grand Total: ${grandtotal}\n\nPurchased Tablets:\n`;

    message += "S.No | Medicine Name | Qty | Price | Total\n";
    message += "--------------------------------------------\n";

    submittedData.forEach((data, index) => {
      const { medicinename, qty, qtyprice, total } = data;
      message += `${index + 1
        } | ${medicinename} | ${qty} | ${qtyprice} | ${total}\n`;
    });

    message += `Subtotal: ${subtotal}\n`;
    message += `Discount: ${discount}\n`;

    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    window.open(whatsappLink, "_blank");
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "billing-data",
  });

  const handleCancel = () => {
    setSubtotal("");
    setGrandTotal("");
    setMobileNo("");
    setCashGiven("");
    setBalance("");
    setCountryCode("+91");
    setDiscountTotal("");

    Object.values(inputRefs.current).forEach((refs) => {
      refs.forEach((ref) => {
        if (ref) {
          ref.value = "";
        }
      });
    });
    setIsSubmitted(false);
    window.location.reload();
  };

  const checkMedicineAvailability = async (medicinename, dosage, brandname, calculatedQuantity, setErrorMessages) => {


    console.log("333333333333333333", brandname, medicinename, dosage);

    try {
      const responseQuantity = await axios.get(
        `${config.apiUrl}/pharmacy/quantity?medicinename=${medicinename}&dosage=${dosage}&brandname=${brandname}`
      );
      setShowErrorButton(true);

      const availableQuantity = responseQuantity.data.availableQuantity;
      const expired = responseQuantity.data.expired;

      if (availableQuantity <= calculatedQuantity) {
        const errorMsg = `${medicinename} is not available. Available quantity is ${availableQuantity}`;
        setErrorMessages((prevMessages) => prevMessages.concat([errorMsg]));
        openModal();

        return false;
      }

      if (expired) {
        const errorMsg = `Medicine ${medicinename} has expired. Expiry date: ${expired}`;
        setErrorMessages((prevMessages) => prevMessages.concat([errorMsg]));
        openModal();

        return false;
      }
      return true;
    } catch (error) {
      const errorMsg = `Error checking medicine availability: ${error}`;
      setErrorMessages((prevMessages) => prevMessages.concat([errorMsg]));
      return false;
    }
  };

  const [viewData, setViewData] = useState([]);

  const View = async (data) => {
    setisViewed(true);
    setViewData(data);

    const firstPrescription = data;
    const medicinesString = firstPrescription.medicines;

    setPatientName(firstPrescription.patient_name);
    setUhid(firstPrescription.uhid)
    setAge(firstPrescription.age)
    setMobileNo(firstPrescription.Mobile_number)
    setDoctorName(firstPrescription.doctor_name);

    try {
      const medicinesArray = JSON.parse(medicinesString);
      setBackendData(medicinesArray);
      setBrandName(medicinesArray.map((medicine) => medicine.brandname));
      setMedicineName(medicinesArray.map((medicine) => medicine.name));
      setInstructions(medicinesArray.map((medicine) => medicine.instruction));
      setFrequency(medicinesArray.map((medicine) => medicine.frequency));
      setOptMrng(medicinesArray.map((medicine) => medicine.morning));
      setOptAft(medicinesArray.map((medicine) => medicine.afternoon));
      setOptEve(medicinesArray.map((medicine) => medicine.evening));
      setOptNyt(medicinesArray.map((medicine) => medicine.night));

      const mrpPromises = medicinesArray.map(async (medicine) => {
        const { medicinename, dosage } = extractMedicineInfo(medicine.name);
        const brandname = medicine.brandname;
        try {
          const responseMRP = await axios.get(
            `${config.apiUrl}/pharmacy/getMRP?medicinename=${medicinename}&dosage=${dosage}&brandname=${brandname}`
          );
          const mrp = responseMRP.data.mrp;
          return mrp;
        } catch (error) {
          console.error(`Error fetching MRP for ${medicinename} ${dosage}:`, error);
          return null;
        }
      });
      const mrps = await Promise.all(mrpPromises);
      const rows = medicinesArray.map((medicine, index) => ({
        id: index + 1,
        data: medicine,
        mrp: mrps[index],
      }));
      setMedicineRows(rows);

      const updatedRows = rows.map((row) => {
        const { data, mrp } = row;
        const total = data.frequency * (
          (data.night ? 1 : 0) +
          (data.evening ? 1 : 0) +
          (data.morning ? 1 : 0) +
          (data.afternoon ? 1 : 0)
        ) * mrp;
        return { ...row, total };
      });

      setMedicineRows(updatedRows);
      const initialMrp = rows.map((row) => {
        const medicinemrp = row.mrp;
        return medicinemrp;
      });
      setMrp(initialMrp);
      const initialQuantity = rows.map((row) => {
        const medicine = row.data;
        return medicine.frequency * (
          (medicine.night ? 1 : 0) +
          (medicine.evening ? 1 : 0) +
          (medicine.morning ? 1 : 0) +
          (medicine.afternoon ? 1 : 0)
        );
      });
      setCalculatedQuantity(initialQuantity);
      const calculatedTotal = initialQuantity.map((quantity, index) => quantity * initialMrp[index]);
      setTotal(calculatedTotal);
      const isMedicineAvailable = await Promise.all(
        medicinesArray.map(async (medicine, index) => {
          const { medicinename, dosage } = extractMedicineInfo(medicine.name);
          const brandName = medicine.brandname;
          console.log("brandName viewwwwwwwww", brandName);

          return await checkMedicineAvailability(medicinename, dosage, brandName, initialQuantity[index], setErrorMessages);
        })
      );
    } catch (error) {
      console.error('Error parsing medicines JSON:', error);
    }
    if (errorMessages.length > 0) {
      setErrorMessages((prevMessages) => [...prevMessages, ...errorMessages]);
      return;
    }
  };

  const handleShowErrorButtonClick = () => {
    setShowErrorButton(true);
    openModal();
  };

  const handleFrequencyChange = (e, rowIndex) => {
    const newFrequency = e.target.value;
    setFrequency((prevFrequency) => {
      const newFrequencyArray = [...prevFrequency];
      newFrequencyArray[rowIndex] = newFrequency;
      return newFrequencyArray;
    });

    const newQuantity = newFrequency * (
      (optNyt[rowIndex] ? 1 : 0) +
      (optEve[rowIndex] ? 1 : 0) +
      (optMrg[rowIndex] ? 1 : 0) +
      (optAft[rowIndex] ? 1 : 0)
    );

    const newTotal = newQuantity * mrp[rowIndex];

    setCalculatedQuantity((prevQuantity) => {
      const newQuantityArray = [...prevQuantity];
      newQuantityArray[rowIndex] = newQuantity;
      return newQuantityArray;
    });

    setTotal((prevTotal) => {
      const newTotalArray = [...prevTotal];
      newTotalArray[rowIndex] = newTotal;
      return newTotalArray;
    });
  };

  const StyledButton = ({ onClick, disabled, children }) => {
    return (
      <button
        type="button"
        className="btn me-2 p-1 rounded-lg bg-blue-800 text-white"
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    );
  };
  
  const handleSubmit = async () => {

    const isAnyFieldFilled = medicineRows.some((row) => {
      const hasFilledInput = inputRefs.current[row.id].some(
        (input) => !!input.value.trim()
      );
      return hasFilledInput;
    });

    if (isSubmitted) {
      showAlert("Form submitted successfully!", "success");
      return;
    }

    if (!isAnyFieldFilled) {
      showAlert("Please fill in at least one input field", "error");
      return;
    }

    let hasIncompleteRow = false;

    console.log("medicineRows", medicineRows);

    const updatedMedicineRows = medicineRows
      .map((row) => {
        const medicinename = inputRefs.current[row.id][1].value;
        const brandname = inputRefs.current[row.id][0].value;

        const qty = parseFloat(inputRefs.current[row.id][8].value) || "";
        const qtyprice = parseFloat(inputRefs.current[row.id][9].value) || "";
        const total = parseFloat(inputRefs.current[row.id][10].value) || "";

        if (
          (medicinename || qty || qtyprice) &&
          !(medicinename && qty && qtyprice)
        ) {
          showAlert("Please fill in all fields", "error");
          hasIncompleteRow = true;

          return null;
        }
        inputRefs.current[row.id][9].value = total;

        return {
          id: row.id,
          medicinename: medicinename,
          qty: qty.toString(),
          qtyprice: qtyprice.toString(),
          total: total,
          brandname: brandname
        };
      })
      .filter(
        (row) => row && row.medicinename && row.medicinename.trim() !== ""
      );

    if (hasIncompleteRow) {
      return;
    }

    for (let index = 0; index < updatedMedicineRows.length; index++) {
      const medicine = updatedMedicineRows[index];
      console.log("medicine 1111111111", medicine);

      const { medicinename, dosage } = extractMedicineInfo(medicine.medicinename);

      const isAvailable = await checkMedicineAvailability(
        medicinename,
        dosage,
        medicine.brandname,
        parseFloat(medicine.qty),
        setErrorMessages
      );

      if (!isAvailable) {
        showAlert(`Medicine ${medicinename} is not available or has expired. Please adjust quantity or remove it.`, "error");
        return;
      }
    }

    function highlightInvalidField(fieldId, placeholderText) {
      const field = document.getElementById(fieldId);
      if (field) {
        field.classList.add("highlight-input");
        field.placeholder = placeholderText;
      }
    }

    const patientName = document.getElementById("patientname").value.trim();
    if (!patientName) {
      highlightInvalidField("patientname", "Fill the mandatory field");
      return;
    } else if (!/^[a-zA-Z\s]+$/.test(patientName)) {
      highlightInvalidField("patientname", "Fill the mandatory field");
      return;
    } else if (/\d/.test(patientName)) {
      highlightInvalidField("patientname", "Fill the mandatory field");
      return;
    } else {
      document
        .getElementById("patientname")
        .classList.remove("highlight-input");
    }

    const mobileno = document.getElementById("mobileno").value.trim();
    if (!mobileno || mobileno.length < 10) {
      highlightInvalidField("mobileno", "Fill the mandatory field");
      return;
    } else {
      document.getElementById("mobileno").classList.remove("highlight-input");
    }

    const cashgiven = document.getElementById("cashgiven").value.trim();
    if (!cashgiven) {
      highlightInvalidField("cashgiven", "Fill the mandatory field");
      return;
    } else {
      document.getElementById("cashgiven").classList.remove("highlight-input");
    }

    setSubmittedData(updatedMedicineRows);
    setIsButtonDisabled(true);

    afterSubmit();

    const billingData = {
      medicineRows: updatedMedicineRows,
      subtotal: document.getElementById("subtotal").value || "",
      discount: discount,
      grandtotal: document.getElementById("grandtotal").value || "",
      patientname: document.getElementById("patientname").value || "",
      doctorname: document.getElementById("doctorname").value || "",
      mobileno: document.getElementById("mobileno").value || "",
      cashgiven: cashgiven,
      balance: balance,
      medicinename: updatedMedicineRows.map((row) => row.medicinename),
    };

    try {
      const response = await axios.post(
        `${config.apiUrl}/pharmacy/billing`, billingData
      );
      const generatedInvoiceNumber = response.data.invoicenumber;

      setIsSubmitted(true);

      setInvoiceNumber(generatedInvoiceNumber);
    } catch (error) {
      console.error("Error submitting billing data:", error);
    }

    await axios.put(
      `${config.apiUrl}/doctor/update-billingstatus/${uhid}`
    );

  };

  useEffect(() => {
    if (Array.isArray(total) && total.length > 0) {
      const calculatedSubtotal = total.reduce((acc, current) => acc + current, 0);
      console.log('suuub', calculatedSubtotal);
      setSubtotal(calculatedSubtotal);
    }
  }, [total]);

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    const totalPages = Math.ceil(prescriptionData.length / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    if (!isNaN(subtotal) && !isNaN(discount)) {
      const calculatedGrandtotal = subtotal - discount;
      setGrandTotal(calculatedGrandtotal);
    }
  }, [subtotal, discount]);

  return (
    <>
      <style>
        {`
      @media print  {
        body {
          margin: 10px;
        }  
      }

      input.error {
        border: 1px solid red;
      }

      .highlight-input {
        border: 1px solid red;
      }

    `}
      </style>
      <div>
      
        {!isViewed ? (
          <div className="p-8 flex flex-col w-full">
              <div className="flex items-center justify-between w-full pb-4">
          <button
            className="flex items-center gap-1 p-1 bg-blue-800 text-white rounded hover:bg-blue-600"
            onClick={() => navigate('/pharmacy')}
          >
            <FontAwesomeIcon icon={faArrowCircleLeft} />
          </button>
          <h1 className="text-2xl font-bold text-green-400 flex-grow text-center">
            Pharmacy Billing
          </h1>
        </div>
            <div className="container mx-auto mb-6">
              <div className="flex items-center space-x-2">
                <FontAwesomeIcon
                  icon={faSearch}
                  className="text-gray-500 h-5 w-5"
                />
                <input
                  type="text"
                  placeholder="Search by UHID or Patient Name"
                  className="p-3 border border-gray-300 rounded-md shadow-sm w-1/3 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>

            <div className="overflow-auto rounded-lg shadow-lg">
              {dataOnCurrentPage.length === 0 ? (
                <p className="text-center text-gray-500 py-8">
                  No search results found
                </p>
              ) : (
                <table className="min-w-full table-auto border-collapse border border-gray-300">
                  <thead className="bg-blue-500 text-white">
                    <tr>
                      {["S.NO", "Date", "UHID", "Patient Name", "Doctor Name", "Department", "Actions"].map((header, index) => (
                        <th key={index} className="px-4 py-3 text-center font-semibold text-sm">
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {dataOnCurrentPage
                      .filter((patient) => {
                        const searchTerm = searchQuery.toLowerCase();
                        return (
                          patient.uhid.toLowerCase().includes(searchTerm) ||
                          patient.patient_name.toLowerCase().includes(searchTerm)
                        );
                      })
                      .map((patient, index) => (
                        <tr key={index} className="hover:bg-gray-100">
                          <td className="px-4 py-2 text-center">{index + 1}</td>
                          <td className="px-4 py-2 text-center">
                            {patient.timestamp
                              ? new Date(patient.timestamp).toLocaleString()
                              : "N/A"}
                          </td>
                          <td className="px-4 py-2 text-center">{patient.uhid || "N/A"}</td>
                          <td className="px-4 py-2 text-center">{patient.patient_name || "N/A"}</td>
                          <td className="px-4 py-2 text-center">{patient.doctor_name || "N/A"}</td>
                          <td className="px-4 py-2 text-center">{patient.department || "N/A"}</td>
                          <td className="px-4 py-2 text-center">
                            <button
                              className="bg-green-500 hover:bg-green-700 text-white py-1 px-4 rounded"
                              onClick={() => View(patient)}
                            >
                              Pay
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>

            <div className="flex justify-center items-center space-x-4 mt-6">
              <button
                onClick={handlePrevious}
                disabled={currentPage === 1}
                className={`py-2 px-4 rounded ${currentPage === 1
                  ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-700 text-white"
                  }`}
              >
                Previous
              </button>
              <span className="text-gray-700">
                Page {currentPage} of {Math.ceil(filteredData.length / itemsPerPage)}
              </span>
              <button
                onClick={handleNext}
                disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)}
                className={`py-2 px-4 rounded ${currentPage === Math.ceil(filteredData.length / itemsPerPage)
                  ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-700 text-white"
                  }`}
              >
                Next
              </button>
            </div>
          </div>
        ) : (
          <div className=" p-8 flex flex-col h-auto w-100">
            {alert.message && (
              <div className="fixed bottom-4 right-4 bg-red-500 text-white p-4 rounded shadow">
                {alert.message}
              </div>
            )}
            <div
              className={`z-20 fixed inset-0 ${isModalOpen ? 'backdrop-filter backdrop-blur-sm' : ''
                } overflow-y-auto transition-all duration-300 ${isModalOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
            >
              <div className="flex items-center justify-center h-screen">
                <div className={`bg-white p-4 w-2/4 md:w-2/4 lg:w-2/4 rounded shadow-lg transition-transform duration-300 transform ${isModalOpen ? 'scale-100' : 'scale-0'} border-2 border-blue-500`}>
                  {errorMessages.length > 0 && (
                    <div>
                      <div className="flex justify-end ">
                        <FontAwesomeIcon
                          icon={faTimes}
                          onClick={() => {
                            closeModal();
                          }}
                          className="text-red-500 cursor-pointer"
                          size="lg"
                        />
                      </div>
                      {errorMessages.map((errorMessage, index) => (
                        <div key={index}>{errorMessage}</div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {!isSubmitted ? (
              <div className="row">
                <div className="container">
                <div className="flex items-center justify-between w-full pb-4">
          <button
            className="flex items-center gap-1 p-1 bg-blue-800 text-white rounded hover:bg-blue-600"
            onClick={handleCancel}
          >
            <FontAwesomeIcon icon={faArrowCircleLeft} />
          </button>
          <h1 className="text-2xl font-bold text-green-400 flex-grow text-center">
            Pharmacy Billing
          </h1>
        </div>
                  <div className="flex justify-between items-center">
                    {showErrorButton && errorMessages.length > 0 && (
                      <div className="relative">
                        <span className="bg-red-500 text-white rounded-full p-1 text-xs absolute top-0 right-0">
                        </span>
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className="text-gray-500 cursor-pointer"
                          size="2x"
                          onClick={handleShowErrorButtonClick}
                        />
                      </div>
                    )}

                  </div>
                  <div className="bg-white  p-4 max-w-screen-xl mx-auto">
                    <div className="flex justify-center items-center ">
                      <table className="table w-100 border-collapse border border-darkBlue-500">
                        <thead>
                          <tr className="bg-darkBlue-500 text-black">
                            <th className="w-60">
                              <h5 className="text-center font-bold">Medicine Name</h5>
                            </th>
                            <th className="w-28">
                              <h5 className="text-center font-bold">Brand Name</h5>
                            </th>

                            <th>
                              <h5 className="text-center font-bold">Instruction</h5>
                            </th>
                            <th colSpan="4">
                              <h5 className="text-center font-bold" >Time Schedule</h5>
                            </th>
                            <th className="w-2">
                              <h5 className="text-center font-bold">No. of Days</h5>
                            </th>
                            <th className="w-6">
                              <h5 className="text-center font-bold">Qty</h5>
                            </th>
                            <th>
                              <h5 className="text-center font-bold">Price</h5>
                            </th>
                            <th>
                              <h5 className="text-center font-bold">Total</h5>
                            </th>
                            <th>
                              <h5 className="text-center font-bold">Actions</h5>
                            </th>
                          </tr>
                          <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th className='text-center w-6'>Morning</th>
                            <th className='text-center w-6'>Afternoon</th>
                            <th className='text-center w-6'>Evening</th>
                            <th className='text-center w-6'>Night</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>

                          </tr>
                        </thead>
                        <tbody className="text-center">
                          {medicineRows.map(({ id, refs }, rowIndex) => (

                            <tr key={id} 
                            // className={rowIndex % 2 === 0 ? 'bg-blue-100' : 'bg-blue-200'}
                            >


                              <td className="flex items-center justify-center">
                                <input
                                  id={`medicinename${id}`}
                                  type="text"
                                  className="p-1 border rounded w-3/3 mt-1"
                                  placeholder="Enter Name"
                                  onChange={(e) => {
                                    handleMedicineInputChange(e, id);
                                  }}
                                  value={medicineName[rowIndex]}

                                  ref={(el) => ((inputRefs.current[id] ||= [])[1] = el)}
                                  //onBlur={(e) => handleKeyPress(e, rowIndex, 1, id)}
                                  list="medicineSuggestions"
                                  onSelect={(e) => {
                                    const uppercaseValue = e.target.value.toUpperCase();
                                    e.target.value = uppercaseValue;
                                    setMedicineNameSelected(true);
                                  }}
                                />
                                {suggestions.length > 0 && (
                                  <datalist id="medicineSuggestions">
                                    {suggestions.map((suggestion, index) => (
                                      <option
                                        key={index}
                                        value={`${suggestion.medicine_info}`}
                                      />
                                    ))}
                                  </datalist>
                                )}
                              </td>
                              <td>
                                <input
                                  id={`brandname${id}`}
                                  type="text"
                                  className="p-1 border rounded w-4/5 mt-1"
                                  placeholder="Enter Name"
                                  value={brandName[rowIndex]}
                                  ref={(el) => ((inputRefs.current[id] ||= [])[0] = el)}
                                  // onBlur={(e) => handleKeyPress(e, rowIndex, 0, id)}
                                  list="brandSuggestions"
                                  onChange={(e) => handleBrandNameChange(e, rowIndex)}
                                  onSelect={(e) => handleSuggestionSelect(medicineName[rowIndex], e.target.value, id, rowIndex)}
                                />
                                {brandSuggestions.length > 0 && (
                                  <datalist id="brandSuggestions">
                                    {brandSuggestions.map((suggestion, index) => (
                                      <option
                                        key={index}
                                        value={`${suggestion} `}
                                      />
                                    ))}
                                  </datalist>
                                )}
                              </td>
                              <td>
                                <input
                                  id={`instruction${id}`}
                                  type="text"
                                  value={instructions[rowIndex]}
                                  className="p-1 border rounded w-4/5 mt-1 text-center"
                                  ref={(el) =>
                                    ((inputRefs.current[id] ||= [])[2] = el)
                                  }

                                  style={{
                                    WebkitAppearance: "none",
                                    MozAppearance: "textfield",
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  id={`mrng${id}`}
                                  type="boolean"
                                  value={optMrg[rowIndex] ? '1' : '0'}
                                  className="p-1 border rounded w-4/5 mt-1 text-center"
                                  ref={(el) =>
                                    ((inputRefs.current[id] ||= [])[3] = el)
                                  }

                                  style={{
                                    WebkitAppearance: "none",
                                    MozAppearance: "textfield",
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  id={`aft${id}`}
                                  type="boolean"
                                  value={optAft[rowIndex] ? '1' : '0'}
                                  className="p-1 border rounded w-2/3 mt-1 text-center"
                                  ref={(el) =>
                                    ((inputRefs.current[id] ||= [])[4] = el)
                                  }

                                  style={{
                                    WebkitAppearance: "none",
                                    MozAppearance: "textfield",
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  id={`eve${id}`}
                                  type="boolean"
                                  value={optEve[rowIndex] ? '1' : '0'}
                                  className="p-1 border rounded w-4/5 mt-1 text-center"
                                  ref={(el) =>
                                    ((inputRefs.current[id] ||= [])[5] = el)
                                  }
                                  readOnly
                                  style={{
                                    WebkitAppearance: "none",
                                    MozAppearance: "textfield",
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  id={`nyt${id}`}
                                  type="boolean"
                                  value={optNyt[rowIndex] ? '1' : '0'}
                                  className="p-1 border rounded w-10 mt-1 text-center"
                                  ref={(el) =>
                                    ((inputRefs.current[id] ||= [])[6] = el)
                                  }
                                  style={{
                                    WebkitAppearance: "none",
                                    MozAppearance: "textfield",
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  id={`frequency${id}`}
                                  type="text"
                                  className="p-1 border rounded w-4/5 mt-1 text-center"
                                  value={frequency[rowIndex]}
                                  onBlur={(e) => handleKeyPress(e, rowIndex, 7, id)}
                                  onChange={(e) => handleFrequencyChange(e, rowIndex)}
                                />
                              </td>
                              <td>
                                <input
                                  id={`qty${id}`}
                                  type="number"
                                  className="p-1 border rounded w-[40px] mt-1 text-center"
                                  ref={(el) => ((inputRefs.current[id] ||= [])[8] = el)}
                                  value={editedQuantity[rowIndex] !== undefined ? editedQuantity[rowIndex] : calculatedQuantity[rowIndex]}
                                  onBlur={(e) => handleQuantity(e, rowIndex, 8, id)}
                                  onKeyDown={(e) => {
                                    if (e.key === "-" || e.key === "e" || e.key === ".") {
                                      e.preventDefault();
                                    }
                                  }}
                                  onInput={(e) => {
                                    const value = e.target.value;
                                    if (parseFloat(value) < 1) {
                                      e.target.value = '';
                                    }
                                    handleQuantityChange(e, rowIndex);

                                  }}
                                  style={{
                                    WebkitAppearance: "none",
                                    MozAppearance: "textfield",
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  id={`qtyprice${id}`}
                                  type="number"
                                  value={mrp[rowIndex]}
                                  className="p-1 border rounded w-[40px] mt-1 text-center"
                                  ref={(el) =>
                                    ((inputRefs.current[id] ||= [])[9] = el)
                                  }
                                  readOnly
                                  style={{
                                    WebkitAppearance: "none",
                                    MozAppearance: "textfield",
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  id={`total${id}`}
                                  type="text"
                                  className="form-control p-1 border rounded w-4/5 mt-1 text-center"
                                  value={total[rowIndex]}
                                  readOnly
                                  ref={(el) =>
                                    ((inputRefs.current[id] ||= [])[10] = el)
                                  }
                                />
                              </td>
                              <td
                                className="cursor-pointer text-center"
                                onClick={() => handleRemoveMedicine(id)}
                              >
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  style={{
                                    color: "red",
                                    fontSize: "1.2rem",
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div>
                      <div className="row mt-4 ml-12">
                        <div className="col-12 col-md-6">
                          <button
                            type="button"
                            className="btn ms-md-3 btn-lg px-6 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105"
                            style={{
                              backgroundColor: "lightblue",
                              color: "black",
                              WebkitAppearance: "none",
                              MozAppearance: "textfield",
                            }}
                            onClick={handleAddMedicine}
                          >
                            {buttonText}
                          </button>
                        </div>

                        <div className="max-w-md mx-auto bg-white p-6 flex justify-between">
                          <div className=" mr-4">
                            <b><label className="me-4">Sub Total</label></b>
                            <input
                              id="subtotal"
                              type="number"
                              className="p-1 border rounded w-48 mt-1"
                              style={{
                                background: "none",
                                WebkitAppearance: "none",
                                MozAppearance: "textfield",
                              }}
                              value={subtotal}
                              readOnly
                            />
                          </div>

                          <div className=" mr-4">
                            <b><label className="me-4">Discount</label></b>
                            <input
                              id="discount"
                              className="p-1 border rounded w-48 mt-1"
                              type="text"
                              value={discount}
                              onChange={handleDiscountChange}
                              onBlur={handleDiscountBlur}
                              style={{
                                background: "none",
                                WebkitAppearance: "none",
                                MozAppearance: "textfield",
                              }}
                            />
                          </div>

                          <div>
                            <b><label className="me-4">Grand Total</label></b>
                            <input
                              className="p-1 border rounded w-48 mt-1"
                              style={{
                                WebkitAppearance: "none",
                                MozAppearance: "textfield",
                              }}
                              id="grandtotal"
                              type="number"
                              value={grandtotal}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="max-w-md bg-white p-1 flex justify-start">
                        <div className=" ml-10 mr-20">
                          <b>
                            <label>Patient Name</label>
                          </b>
                          <div>
                            <input
                              type="text"
                              id="patientname"
                              onBlur={(e) => handleKeyPress(e, 0, 0, "patientname")}
                              onChange={handlePatientNameChange}
                              value={patientName}
                              className="p-1 border rounded w-48 mt-1"
                            />
                          </div>
                          <br />
                        </div>

                        <div className="ml-10 mr-20">
                          <b>
                            <label>Doctor Name</label>
                          </b>
                          <div>
                            <input
                              type="text"
                              id="doctorname"
                              value={doctorname}
                              className="p-1 border rounded w-48 mt-1"
                              onBlur={(e) => handleKeyPress(e, 0, 0, "doctorname")}
                            />
                          </div>
                          <br />
                        </div>

                        <div className=" ml-10 ">
                          <b>
                            <label htmlFor="mobileno">
                              <b>Mobile No</b>
                            </label>
                          </b>
                          <div className="flex item-center">
                            <select
                              id="countryCode"
                              value={countryCode}
                              onChange={handleCountryCodeChange}
                              className="p-1 border rounded w-20 mt-1"
                            >
                              <option value="+91">+91 (India)</option>
                              <option value="+1">+1 (US)</option>
                              <option value="+44">+44 (UK)</option>
                            </select>
                            <input
                              type="tel"
                              id="mobileno"
                              value={mobileNo}
                              //onChange={handleInputChange}
                              className="p-1 border rounded w-48 mt-1 ml-2"
                            />
                          </div>

                        </div>
                      </div>

                      <div className="max-w-md bg-white p-1 flex justify-start">
                        <div className="ml-10 mr-20">
                          <b>
                            <label>Invoice Date</label>
                          </b>
                          <input
                            type="text"
                            className="p-1 border rounded w-48 mt-1"
                            defaultValue={currentDateFormatted}
                            readOnly
                          />
                          <br />
                        </div>
                        <div className="ml-10 mr-20">
                          <b>
                            <label>Cash Given</label>
                          </b>
                          <input
                            type="text"
                            id="cashgiven"
                            value={cashGiven}
                            onChange={handleCashGivenChange}
                            onBlur={handleCashGivenBlur}
                            className="p-1 border rounded w-48 mt-1"
                          />
                        </div>
                        <div className="ml-10 mr-20">
                          <b>
                            <label>Balance</label>
                          </b>
                          <div>
                            <input
                              type="text"
                              id="balance"
                              value={balance}
                              readOnly
                              className="p-1 border rounded w-48 mt-1"
                            />
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-end mt-2">
                        <button
                          type="button"
                          className="btn mr-4 ms-md-3 btn-lg px-6 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105"
                          style={{
                            backgroundColor: "lightblue",
                            color: "black",
                            WebkitAppearance: "none",
                            MozAppearance: "textfield",
                          }}
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>

                        <button
                          type="button"
                          className={`btn ${isButtonDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-teal-500'} ms-md-3 btn-lg px-6 py-2 rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
                          style={{
                            backgroundColor: "lightblue",
                            color: "black",
                            WebkitAppearance: "none",
                            MozAppearance: "textfield",
                          }}
                          onClick={handleSubmit}
                          disabled={isButtonDisabled}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-12 text-center ">
                    <StyledButton onClick={handleWhatsApp}>
                      WhatsApp
                    </StyledButton>

                    <StyledButton onClick={handlePdf} disabled={loader === true}>
                      Download PDF
                    </StyledButton>

                    <StyledButton onClick={handlePrint}>
                      Print
                    </StyledButton>

                    <StyledButton onClick={handleCancel}>
                      Cancel
                    </StyledButton>
                  </div>

                </div>

                <div className="row m-4 overflow-auto">
                  <div className="col-md-10 col-lg-8">
                  <div ref={componentRef}>
                  {Array.from({ length: totalPages }, (_, page) => {
                        const startIndex = page * rowsPerPage;
                        const endIndex = startIndex + rowsPerPage;
                        const isLastPage = page === totalPages - 1;

                        return (
                          <div key={page} className="bill">
                            <div className="border border-black-300 p-6 rounded-lg">
                              <div
                                className="registration-content p-4"
                                style={{
                                  //backgroundImage: `url(${billbg})`,
                                  backgroundSize: 'contain',
                                  backgroundPosition: 'center',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundColor: 'white',
                                }}
                                ref={componentRef}
                              >
                                <div className="text-center">
                                  <h2 className="text-lg font-semibold mb-2 text-center">HMS MULTI SPECIALITY HOSPITAL AND MANAGEMENT</h2>
                                  <p className="text-lg font-semibold mb-2 text-center">585 Anna Nagar, Madurai - 625020 </p>
                                  <p className="text-lg font-semibold mb-4 text-center">Phno : +91 9632587410, 0452-25252525 E-mail: hmshospital@gmail.com </p>
                                  <p className="text-lg font-semibold mb-4 text-center">INVOICE</p>

                                </div>
                                <div className="flex gap-12">
                                  <div className="w-3/4">

                                    <div className="grid grid-cols-3 gap-4 pl-8">
                                      <div className="col-span-2">
                                        <p><strong>Name :</strong> {patientName}</p>
                                        <p><strong>UHID :</strong> {uhid}</p>
                                        <p><strong>Mobile Number :</strong> {uhid}</p>
                                        <p><strong>Invoice Number :</strong> {invoiceNumber}</p>
                                      </div>

                                      <div className="col-span-1 pl-52 grid grid-col">
                                        <p><strong>Age :</strong> {age}</p>
                                        <p><strong>Date :</strong> {currentDateFormatted}</p>

                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="table-responsive border-black mt-1 me-5 ms-5">
                                  <table className="w-full mt-8 border border-black">
                                    <thead>
                                      <tr>
                                        <th className="pl-8 text-center">
                                          S.No
                                        </th>
                                        <th className="pl-8 text-center">
                                          Medicine Name
                                        </th>
                                        <th className="pl-8 text-center">
                                          Price
                                        </th>
                                        <th className="pl-8 text-center">
                                          Qty
                                        </th>
                                        <th className="pl-8 text-center">
                                          Total
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Array.isArray(submittedData) &&
                                        submittedData
                                          .slice(startIndex, endIndex)
                                          .map((data, index) => (
                                            <tr key={data.id}>
                                              <td className="pl-8 text-center">
                                                {startIndex + index + 1}
                                              </td>
                                              <td className="pl-8 text-center">
                                                {data.medicinename}
                                              </td>
                                              <td className="pl-8 text-center">
                                                {data.qtyprice}
                                              </td>
                                              <td className="pl-8 text-center">
                                                {data.qty}
                                              </td>
                                              <td className="pl-8 text-center">
                                                {data.total}
                                              </td>

                                            </tr>
                                          ))}
                                    </tbody>
                                  </table>
                                </div>
                                {isLastPage && (
                                  <div className="d-flex justify-content-between mt-2">
                                    <div className="text-start ms-10 mt-5">
                                      <p><strong>Cash Given :</strong> {cashGiven}</p>
                                      <p><strong>Balance :</strong> {balance}</p>
                                    </div>
                                    <div className="text-end me-5">
                                      <p><strong>Subtotal :</strong> {subtotal}</p>
                                      <p><strong>Discount :</strong> {discount}</p>
                                      <p><strong>Grand Total :</strong> {grandtotal}</p>

                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default Billing;