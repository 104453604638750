import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSync, faSearch,faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import config from "../../config";

import { useNavigate } from 'react-router-dom';

const AdmissionForm = () => {
  const [patients, setPatients] = useState([]);
  const navigate = useNavigate();  
  const inputRef = useRef(null);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
   const [searchQuery, setSearchQuery] = useState("");
  const storedUserData = localStorage.getItem("user");
  const parsedUserData = storedUserData ? JSON.parse(storedUserData) : null;

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = () => {
    if (parsedUserData.user.user_role === "Admin") {
      fetch(`${config.apiUrl}/doctor/getAdmissionsData`)
        .then((response) => response.json())
        .then((data) => setPatients(data))
        .catch((error) => console.error("Error fetching patient data:", error));
    } else {
      fetch(
        `${config.apiUrl}/doctor/patients/${parsedUserData.user.user_department}/${parsedUserData.user.user_first_name}.${parsedUserData.user.user_last_name}`
      )
        .then((response) => response.json())
        .then((data) => setPatients(data))
        .catch((error) => console.error("Error fetching patient data:", error));
    }
  };

  useEffect(() => {
    fetch(`${config.apiUrl}/op/departments`)
      .then((response) => response.json())
      .then((data) => {
        setDepartments(data);
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });
  }, []);


  return (
    <div>
      <div className="flex items-center justify-between w-full">
          {/* Button on the left */}
          <button
            className="flex items-center gap-2 p-2 bg-blue-800 text-white rounded hover:bg-blue-600"
            onClick={() => navigate('/front-office')}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>

          {/* Heading centered */}
          <h1 className="text-2xl font-bold text-green-400 flex-grow text-center">
            Patient Admission Form
          </h1>
        </div>

      <div className="flex items-center mt-4 mb-2">
        <div className="relative mr-4">
          <FontAwesomeIcon
            icon={faSearch}
            className="absolute text-black-500 h-4 w-4 left-3 top-3"
          />
          <input
            type="text"
            placeholder="Search by UHID or Patient Name"
            className="pl-8 pr-2 py-2 border rounded w-[300px]"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        {parsedUserData.user.user_role === "Admin" ? (
          <div>
            <label htmlFor="departmentsList">Department</label>
            <input
              ref={inputRef}
              list="departmentsList"
              className="p-2 border rounded ml-4"
              placeholder="Select Department"
              onChange={(e) => {
                const selectedValue = e.target.value;
                const isValid = departments.some(
                  (department) => department.department === selectedValue
                );
                if (isValid || !selectedValue) {
                  setSelectedDepartment(selectedValue);
                } else {
                  setSelectedDepartment("");
                }
              }}
              onBlur={() => {
                if (inputRef.current && !inputRef.current.value) {
                  setSelectedDepartment("");
                }
              }}
            />
            <datalist id="departmentsList">
              {departments.map((department) => (
                <option key={department.id} value={department.department} />
              ))}
            </datalist>
          </div>
        ) : (
          ""
        )}

      </div>

      <div className="flex" >
        <div className="w-full p-2 bg-white">
          {showSuccessPopUp && (
            <div className="fixed bottom-4 right-4 bg-green-500 text-white py-2 px-4 rounded shadow">
              Patient visit ✅
            </div>
          )}
          <div className="flex items-center" style={{ marginBottom: "-25px" }}>
            <h2 className="text-xl mb-2 mr-2">RECENT PATIENT LIST</h2>
            <FontAwesomeIcon
              icon={faSync}
              className="w-6 h-4 text-black-600 cursor-pointer ml-2 "
              onClick={fetchInitialData}
            />
          </div>
          <table className="w-full mt-8 mb-2"  style={{ overflowY: 'scroll' }}>
            <thead className=" text-white">
              <tr className="bg-blue-800">
                <th className="bg-blue-800">S.No</th>
                <th className="bg-blue-800">Date</th>
                <th className="bg-blue-800">UHID</th>
                <th className="bg-blue-800">Patient Name</th>
                <th className="bg-blue-800">Age</th>
                <th className="bg-blue-800">Consultation Doctor</th>
                <th className="bg-blue-800">Department</th>
                <th className="bg-blue-800">Diagnosis</th>
                <th className="bg-blue-800">Procedure</th>
                <th className="bg-blue-800">  Willing for Admission <br/>
                (Inpatient Rooms Allocate)</th>
              </tr>
            </thead>
            <tbody  style={{ maxHeight: "200px", lineHeight: '40px' }}>
              {Array.isArray(patients) && patients.length > 0 ? (
                patients

                  .filter((patient) => {
                    const searchTerm = searchQuery.toLowerCase();
                    return (
                      patient.uhid.toLowerCase().includes(searchTerm) ||
                      patient.patient_name.toLowerCase().includes(searchTerm)
                    );
                  })
                  .filter(
                    (patient) =>
                      !selectedDepartment ||
                      patient.department === selectedDepartment
                  )
                  .map((patient, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{patient.date}</td>
                      <td>{patient.uhid}</td>
                      <td>{patient.patient_name}</td>
                      <td>{patient.age}</td>
                      <td>{patient.doctor_name}</td>
                      <td>{patient.department}</td>
                      <td>{patient.diagnosis}</td>
                      <td>{patient.treatment_procedure}</td>
                      <td>
                        <button
                          className=" bg-green-500 text-white py-3 px-4 rounded-md mr-10"
                        >
                        Yes
                        </button>
                        <button
                          className=" bg-red-500 text-white py-3 px-4 rounded-md"
                        >
                        No
                        </button>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr className="text-center ">
                  <td colSpan="10">No patients available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

 
    </div>
  );
};

export default AdmissionForm;