import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logoImage from "../Assets/newlogo.png";
import bg from "../Assets/bgimage2.jpeg"
import config from "../config";

const Login = () => {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [loginData, setLoginData] = useState({
    loginIdentifier: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${config.apiUrl}/user/login`,
        loginData
      );

      console.log(response.data);

      if (response.data.status === 200) {
        setAlertMessage("Login Successful!");
        setTimeout(() => {
          setAlertMessage(null);
          localStorage.setItem("user", JSON.stringify(response.data.data));
          const userRole = response.data.data.user.user_role;
          const redirectPath = getRedirectPathBasedOnRole(userRole);
          navigate(redirectPath);
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setLoginError(error.response.data.message);
        setTimeout(() => setLoginError(null), 2000);
      } else {
        setAlertMessage("An unexpected error occurred. Please try again.");
        setTimeout(() => setAlertMessage(null), 2000);
      }
    }
  };

  const getRedirectPathBasedOnRole = (userRole) => {
    switch (userRole) {
      case "Billing Staff":
        return "/billing";
      case "PatientCare":
        return "/frontoffice";
      case "Purchase Staff":
        return "/purchasing";
      case "Pharmacy Staff":
        return "/pharmacy";
      case "Admin":
        return "/dashboard";
      case "Doctor":
        return "/doctor";
      case "OP Nurse":
        return "/dashboard";
      default:
        return "/homeapp";
    }
  };

  return (
   
          <div className="min-h-screen flex items-center justify-center">
            <div
              className="flex flex-row w-full border rounded-lg shadow-lg bg-cover bg-center bg-no-repeat"
              style={{ backgroundImage: `url(${bg})` }}
            >
              <div className="flex-1 p-4">
                <div className="flex items-start justify-start">
                  <div className="p-6 w-[500px] h-[95%] mt-5 mx-24 bg-white rounded-xl shadow-lg relative">
                    <div className="text-center mb-4">
                      <img src={logoImage} alt="Profile" className="w-44 h-auto mx-auto" />
                      <h1 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-blue-500">
                        Doctor Connect
                      </h1>
                      <p className="mt-5 text-lg font-light">Welcome back! Please enter your details</p>

                      <form onSubmit={handleLogin} className="mt-6">
                        <div className="mb-4">
                          <input
                            type="text"
                            id="loginIdentifier"
                            name="loginIdentifier"
                            placeholder="Email or Mobile Number"
                            className="w-full px-4 py-2 border-b-2 border-blue-400 bg-transparent outline-none focus:border-blue-500 text-center"
                            value={loginData.loginIdentifier}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="mb-4">
                          <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Password"
                            className="w-full px-4 py-2 border-b-2 border-blue-400 bg-transparent outline-none focus:border-blue-500 text-center"
                            value={loginData.password}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <button
                          type="submit"
                          className="w-full bg-gradient-to-r from-cyan-400 to-blue-500 text-white py-2 rounded-md shadow-md hover:from-cyan-500 hover:to-blue-600 transition-colors"
                        >
                          Login
                        </button>
                      </form>

                      {/* Message Container */}
                      <div className="mt-2 h-6">
                        {loginError && <p className="text-red-500">{loginError}</p>}
                        {alertMessage && <p className="text-green-500">{alertMessage}</p>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

      
  );
};

export default Login;
