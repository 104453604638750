import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const InvoiceModal = ({ isOpen, onClose, invoiceData }) => {
    
  const handlePrint = () => {
    // Create a new window
    const printWindow = window.open('', '_blank');

    // Write the HTML content to the new window
    printWindow.document.write(`
      <html>
        <head>
          <title>Invoice</title>
          <style>
            body {
              font-family: Arial, sans-serif;
            }
            .text-center { text-align: center; }
            .flex { display: flex; justify-content: space-between; }
            .border-collapse { border-collapse: collapse; width: 100%; }
            .border { border: 1px solid #ccc; }
            th, td { padding: 8px; text-align: left; }
          </style>
        </head>
        <body>
          <h2 class="text-center">HMS MULTI SPECIALITY HOSPITAL AND MANAGEMENT</h2>
          <p class="text-center">585 Anna Nagar, Madurai - 625020</p>
          <p class="text-center">Phno: +91 9632587410, 0452-25252525</p>
          <p class="text-center">E-mail: hmshospital@gmail.com</p>

          <div class="flex">
            <div>
              <p><strong>Name:</strong> ${invoiceData?.patient_name}</p>
              <p><strong>UHID:</strong> ${invoiceData?.uhid}</p>
              <p><strong>Mobile Number:</strong> ${invoiceData?.Mobile_number}</p>
              <p><strong>Invoice Number:</strong> ${invoiceData?.invoice_number}</p>
            </div>
            <div>
              <p><strong>Age:</strong> ${invoiceData?.age}</p>
              <p><strong>Doctor Name:</strong> ${invoiceData?.doctor_name}</p>
              <p><strong>Department:</strong> ${invoiceData?.department}</p>
            </div>
          </div>

          <table class="border-collapse mt-4">
            <thead>
              <tr>
                <th class="border">S.No</th>
                <th class="border">Content</th>
                <th class="border">Fees</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">1</td>
                <td class="border">${invoiceData?.procedure_name}</td>
                <td class="border">${invoiceData?.price || '0.00'}</td>
              </tr>
              <tr>
                <td colspan="2" class="border">Total</td>
                <td class="border">${invoiceData?.price || '0.00'}</td>
              </tr>
            </tbody>
          </table>
        </body>
      </html>
    `);

    printWindow.document.close(); // Close the document to apply the styles
    printWindow.focus(); // Focus on the new window
    printWindow.print(); // Trigger the print dialog
    printWindow.close(); // Close the window after printing
  };

  return (
    <div
      className={`fixed inset-0 overflow-y-auto ${isOpen ? 'backdrop-filter backdrop-blur-sm' : ''} transition-all duration-300 ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
    >
      <div className="flex items-center justify-center h-screen">
        <div
          className={`bg-white p-4 w-1/2 md:w-1/2 lg:w-3/4 rounded shadow-lg transition-transform duration-300 transform ${isOpen ? 'scale-100' : 'scale-0'} border-2 border-blue-500 max-h-full overflow-auto`}
          style={{
            scrollbarWidth: 'thin',
            scrollbarColor: 'rgba(0, 0, 0, 0.5) transparent',
          }}
        >
          {/* Close Button */}
          <div className="flex justify-end mt-0">
            <FontAwesomeIcon
              icon={faTimes}
              onClick={onClose}
              className="text-red-500 cursor-pointer"
            />
          </div>

          {/* Title */}
          <h2 className="text-xl mb-4 mt-0">Registration Billing</h2>

          {/* Hospital Information */}
          <div className="text-center text-blue-800 mb-6">
            <h2 className="text-lg font-semibold">HMS MULTI SPECIALITY HOSPITAL AND MANAGEMENT</h2>
            <p className="text-lg">585 Anna Nagar, Madurai - 625020</p>
            <p className="text-lg">Phno: +91 9632587410, 0452-25252525</p>
            <p className="text-lg">E-mail: hmshospital@gmail.com</p>
          </div>

          {/* Patient Details */}
          <div className="flex justify-between mb-8">
            <div>
              <p><strong>Name:</strong> {invoiceData?.patient_name}</p>
              <p><strong>UHID:</strong> {invoiceData?.uhid}</p>
              <p><strong>Mobile Number:</strong> {invoiceData?.Mobile_number}</p>
              <p><strong>Invoice Number:</strong> {invoiceData?.invoice_number}</p>
            </div>
            <div>
              <p><strong>Age:</strong> {invoiceData?.age}</p>
              <p><strong>Doctor Name:</strong> {invoiceData?.doctor_name}</p>
              <p><strong>Department:</strong> {invoiceData?.department}</p>
            </div>
          </div>

          {/* Invoice Table */}
          <table className="w-full mt-4 border-collapse">
            <thead>
              <tr>
                <th className="text-lg font-semibold text-left">S.No</th>
                <th className="text-lg font-semibold text-left">Content</th>
                <th className="text-lg font-semibold text-left">Fees</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="">1</td>
                <td className="text-start">{invoiceData?.procedure_name}</td>
                <td className="text-start">{invoiceData?.price || '0.00'}</td>
              </tr>
              <tr>
                <td colSpan="2" className="text-center font-semibold">Total</td>
                <td className="font-semibold">{invoiceData?.price || '0.00'}</td>
              </tr>
            </tbody>
          </table>

          {/* Action Buttons */}
          <div className="flex justify-between mt-4">
            <button
              onClick={handlePrint}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Print
            </button>

            {/* Uncomment this button when implementing PDF download */}
            {/* <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Download PDF
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceModal;
