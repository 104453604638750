import React from 'react';
import { useNavigate } from 'react-router-dom';
import bill from '../../Assets/bill.jpg';
import stock from '../../Assets/stock.jpg';
import bh from '../../Assets/bill-history.jpg';
import '../../Styles/card.css';
import {Button} from "../HomeComponent/button"

const HRManagement = () => {
  const navigate = useNavigate();
  const [hovered, setHovered] = React.useState(null);

  const handleMouseEnter = (index) => setHovered(index);
  const handleMouseLeave = () => setHovered(null);

  const cardData = [
    {
      title: 'Stock History',
      content: 'View the history of stock changes.',
      path: '/pharmacy/stock',
      image: stock,
    },
    {
      title: 'Pharmacy Bill',
      content: 'Generate pharmacy bills easily.',
      path: '/pharmacy/bill',
      image: bill,
    },
    {
      title: 'Pharmacy Bill History',
      content: 'Track the history of all pharmacy bills.',
      path: '/pharmacy/billhistory',
      image: bh,
    },
  ];
  
  

  const handleCardClick = (path) => {
    navigate(path);
  };

  return (
    <div className="container mx-auto px-4">
    {/* Page Title */}
    <h1 className="text-2xl font-bold text-green-400 flex-grow text-center">
    Pharmacy Management</h1>

    <div className='flex justify-center items-center '>
      
 <div className="flex flex-wrap gap-6 mt-6 ">
      {cardData.map((card, index) => (
        <div
          key={index}
          className={`relative w-80 h-[400px] bg-base-100 border rounded-lg shadow-xl overflow-hidden cursor-pointer  
          transition-transform duration-200 ${hovered === index ? 'scale-105' : ''}`}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          <figure className="h-2/3">
            <img
              src={card.image}
              alt={card.title}
              className="w-full h-full object-cover"
            />
          </figure>
          <div className="card-body h-1/3 flex flex-col items-center justify-center bg-gray-200">
            <div className="mt-4">
              <Button onClick={() => handleCardClick(card.path)} title={card.title} />
            </div>
            <p className="text-gray-600 p-2">{card.content}</p>

          </div>
        </div>
      ))}
    </div>
    </div>
    </div>
   
  );
};

export default HRManagement;
