import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config';
import { Modal } from 'antd'; // Ant Design for the modal
import { PlusOutlined } from '@ant-design/icons'; // Ant Design icon
import LabInventory from './labInventory';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const AvailableTests = () => {
  const [labTests, setLabTests] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate =useNavigate();

  useEffect(() => {
    const fetchLabTests = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/lab/availablelabtest`);
        setLabTests(response.data);
      } catch (error) {
        console.error('Error fetching lab tests:', error);
      }
    };

    fetchLabTests();
  }, []);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Group lab tests by category
  const groupedTests = labTests.reduce((acc, test) => {
    acc[test.category] = acc[test.category] || [];
    acc[test.category].push(test);
    return acc;
  }, {});

  return (
    <div className="container mx-auto mt-10">

<div className="flex items-center justify-between w-full pb-4">
        <button
          className="flex items-center gap-2 p-2 bg-blue-800 text-white rounded hover:bg-blue-600"
          onClick={() => navigate('/lab')}
        >
          <FontAwesomeIcon icon={faArrowCircleLeft} />
        </button>

        <h1 className="text-2xl font-bold text-green-400 flex-grow text-center">
        Available Lab Tests Lists
        </h1>
      </div>

      <div className="flex justify-end items-center mb-8">
        {/* <h1 className="text-2xl font-bold">Available Lab Tests Lists</h1> */}
        <button
          onClick={openModal}
          className="bg-blue-600 text-white px-4 py-2 rounded-md flex items-center"
        >
          <PlusOutlined className="mr-2" />
          Add Test
        </button>
      </div>

      {/* Display tests grouped by category */}
      {Object.keys(groupedTests).map((category) => (
        <div key={category} className="mb-8">
          <h2 className="text-xl font-semibold text-blue-800 mb-4">{category}</h2>
          <table className="w-full  border-collapse border border-gray-300 mb-4">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 px-4 py-2">Test Name</th>
                <th className="border border-gray-300 px-4 py-2">Price</th>
              </tr>
            </thead>
            <tbody>
              {groupedTests[category].map((test) => (
                <tr key={test.id} className="hover:bg-gray-50">
                  <td className="border border-gray-300 px-4 py-2">{test.testName}</td>
                  <td className="border border-gray-300 px-4 py-2">${test.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}

      {/* Modal Popup */}
      <Modal open={isModalOpen} onCancel={closeModal} footer={null} centered>
        <LabInventory />
      </Modal>
    </div>
  );
};

export default AvailableTests;
