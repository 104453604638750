

import React, { useState, useEffect } from 'react';
import config from '../../config';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSync, faSearch } from '@fortawesome/free-solid-svg-icons';
import BillingModal from './billmodelscreen'; // Import the new BillingModal component
import InvoiceModal from './Invoicemodal';

const CardiologyBilling = () => {
  const [patientData, setPatientData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState({
    cash: false,
    online: false,
  });
  const [invoiceData, setInvoiceData] = useState(null); // State for invoice data
  const navigate = useNavigate();

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = () => {
    fetch(`${config.apiUrl}/bill/cardio-billing-status`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setPatientData(data);
        setErrorMessage('');
      })
      .catch((error) => {
        console.error('Error fetching initial data:', error);
        setErrorMessage('Error fetching initial data');
      });
  };

  const handleOpenModal = (patient) => {
    setSelectedPatient(patient);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedPatient(null);
    setIsModalOpen(false);
  };

  const handleSave = () => {
    const selectedPaymentMethod = paymentMethod.cash ? 'Cash' : paymentMethod.online ? 'Online' : null;

    if (!selectedPaymentMethod) {
      alert('Please select a payment method');
      return;
    }

    const billingData = {
      ...selectedPatient,
      paymentmethod: selectedPaymentMethod,
    };

    fetch(`${config.apiUrl}/bill/Cardiology-billing`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(billingData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to save billing data');
        }
        return response.json();
      })
      .then((data) => {
        alert('Billing data saved successfully!');
        setInvoiceData(data);    
        handleCloseModal();
        setIsInvoiceModalOpen(true);
        fetchInitialData();
      })
      .catch((error) => {
        console.error('Error saving billing data:', error);
        alert('Failed to save billing data');
      });
  };

  const handleCloseInvoiceModal = () => {
    setIsInvoiceModalOpen(false);
    setInvoiceData(null); // Clear the invoice data when closing the modal
  };

  return (
    <div>
      <div className="flex items-center justify-between w-full pb-4">
        <button
          className="flex items-center gap-2 p-2 bg-blue-800 text-white rounded hover:bg-blue-600"
          onClick={() => navigate('/billing')}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>

        <h1 className="text-2xl font-bold text-green-400 flex-grow text-center">
          Patient Radiology Test Bill
        </h1>
      </div>

      <div className="flex items-center">
        <h2 className="text-xl mb-2 mr-2">PATIENT LIST</h2>
        <FontAwesomeIcon
          icon={faSync}
          className="w-6 h-4 text-black-600 cursor-pointer ml-2 mb-2"
          onClick={fetchInitialData}
        />
      </div>

      <div className="flex items-center">
        <FontAwesomeIcon icon={faSearch} className="text-black-500 h-4 w-4 mr-2 mt-5" />
        <input
          type="text"
          placeholder="Search by UHID or Patient Name"
          className="p-2 border rounded w-1/5 mt-5"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {errorMessage && <p className="text-red-500">{errorMessage}</p>}

      <table className="border-collapse mt-4 w-full">
        <thead>
          <tr className="bg-blue-800">
            <th className="px-4 py-2 text-center">S.NO</th>
            <th className="px-4 py-2 text-center">UHID</th>
            <th className="px-4 py-2 text-center">Patient Name</th>
            <th className="px-4 py-2 text-center">Mobile Number</th>
            <th className="px-4 py-2 text-center">Procedure Name</th>
            <th className="px-4 py-2 text-center">Price</th>
            <th className="px-4 py-2 text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {patientData.filter((patient) => {
            if (!searchQuery) return true;
            const searchQueryLower = searchQuery.toLowerCase();
            return (
              patient.uhid.toLowerCase().includes(searchQueryLower) ||
              patient.patient_name.toLowerCase().includes(searchQueryLower)
            );
          }).map((patient, index) => (
            <tr key={index}>
              <td className="border px-4 py-2 text-center">{index + 1}</td>
              <td className="border px-4 py-2 text-center">{patient.uhid}</td>
              <td className="border px-4 py-2 text-center">{patient.patient_name}</td>
              <td className="border px-4 py-2 text-center">{patient.Mobile_number}</td>
              <td className="border px-4 py-2 text-center">{patient.procedure_name}</td>
              <td className="border px-4 py-2 text-center">{patient.price}</td>
              <td className="border px-4 py-2 text-center">
                <button
                  className="p-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                  onClick={() => handleOpenModal(patient)}
                >
                  Bill
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Use the BillingModal component */}
      <BillingModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        selectedPatient={selectedPatient}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        onSave={handleSave}
      />

      {/* Use the InvoiceModal component */}
      <InvoiceModal
        isOpen={isInvoiceModalOpen}
        onClose={handleCloseInvoiceModal}
        invoiceData={invoiceData?.invoice}
      />
    </div>
  );
};

export default CardiologyBilling;
