import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";
import config from "../../config";

const PrintScreen = ({ selectedPatient, onClose }) => {

  console.log("selectedPatient", selectedPatient);
  

    const genderText = selectedPatient.gender === 'M' ? 'Male' : 'Female';
    const componentRef = useRef(null);
    const printRef = useRef(null);
    const hasRegistrationFees = selectedPatient.registration_fees !== null && selectedPatient.registration_fees !== '';
    const hasRegistrationFeesPaid = selectedPatient.registration_payment_status === 1;
    const hasConsultationFees = selectedPatient.consultation_fees !== null && selectedPatient.consultation_fees !== '';
  
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
  
    const handleConsultationPrint = useReactToPrint({
      content: () => printRef.current,
    });
  
    const handleConsultationPrintClick = () => {
      handleConsultationPrint();
    };
  
    const handlePrintClick = () => {
      handlePrint();
    };
  
    const handleRegPdf = async () => {
      try {
        const html2canvasOptions = {
          scale: 2,
          logging: false,
          allowTaint: true,
        };
  
        const jsPDFOptions = {
          orientation: "portrait",
          unit: "mm",
          format: "a4",
        };
  
        const doc = new jsPDF(jsPDFOptions);
  
        const pages = document.querySelectorAll(".registration-content");
  
        for (let i = 0; i < pages.length; i++) {
          const capture = pages[i];
  
          const canvas = await html2canvas(capture, html2canvasOptions);
          const imgData = canvas.toDataURL("image/png");
  
          const imageWidth = 180;
          const imageHeight = (canvas.height * imageWidth) / canvas.width;
  
          const marginLeft = (doc.internal.pageSize.width - imageWidth) / 2;
          const marginTop = (doc.internal.pageSize.height - imageHeight) / 2;
  
          if (i > 0) {
            doc.addPage();
          }
  
          doc.addImage(
            imgData,
            "PNG",
            marginLeft,
            marginTop,
            imageWidth,
            imageHeight
          );
        }
  
        const formData = new FormData();
        formData.append('uhid', selectedPatient.uhid);
        formData.append('department', 'registration');
        formData.append('pdfFile', doc.output('blob'), 'RegistrationBill.pdf');
  
        const uploadResponse = await axios.post(`${config.apiUrl}/bill/upload-pdf`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  
        doc.save("RegistrationBill.pdf");
      } catch (error) {
        console.error('Error generating or uploading registration PDF:', error);
      }
    };
  
    const handleConsulPdf = async () => {
  
      try {
        const html2canvasOptions = {
          scale: 2,
          logging: false,
          allowTaint: true,
        };
  
        const jsPDFOptions = {
          orientation: "portrait",
          unit: "mm",
          format: "a4",
        };
  
        const doc = new jsPDF(jsPDFOptions);
  
        const pages = document.querySelectorAll(".consultation-content");
  
        for (let i = 0; i < pages.length; i++) {
          const capture = pages[i];
  
          const canvas = await html2canvas(capture, html2canvasOptions);
          const imgData = canvas.toDataURL("image/png");
  
          const imageWidth = 180;
          const imageHeight = (canvas.height * imageWidth) / canvas.width;
  
          const marginLeft = (doc.internal.pageSize.width - imageWidth) / 2;
          const marginTop = (doc.internal.pageSize.height - imageHeight) / 2;
  
          if (i > 0) {
            doc.addPage();
          }
  
          doc.addImage(
            imgData,
            "PNG",
            marginLeft,
            marginTop,
            imageWidth,
            imageHeight
          );
        }
  
        const formData = new FormData();
        formData.append('uhid', selectedPatient.uhid);
        formData.append('department', 'Consultation');
        formData.append('pdfFile', doc.output('blob'), 'ConsultationBill.pdf');
  
        const uploadResponse = await axios.post(`${config.apiUrl}/bill/upload-pdf`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  
        doc.save("ConsultationBill.pdf");
      } catch (error) {
        console.error('Error generating or uploading registration PDF:', error);
      }
    };
  
    return (
      <>
        {hasRegistrationFees && !hasConsultationFees ? (
          <>
            <div className="flex justify-end mt-0">
              <FontAwesomeIcon
                icon={faTimes}
                onClick={onClose}
                className="text-red-500 cursor-pointer"
              />
            </div>
  
            <h2 className="text-xl mb-4 mt-0">Registration Billing</h2>
  
            <div className="border border-gray-300 p-6 rounded-lg">
              <div
                className="registration-content p-4"
                style={{
                  //backgroundImage: `url(${billbg})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  // backgroundColor: 'rgba(255, 255, 255, 0.1)',
                }}
                ref={componentRef}
              >
                <div className="text-center text-blue-800">
                  <h2 className="text-lg font-semibold mb-2 text-center">HMS MULTI SPECIALITY HOSPITAL AND MANAGEMENT</h2>
                  <p className="text-lg font-semibold mb-2 text-center">585 Anna Nagar, Madurai - 625020 </p>
                  <p className="text-lg font-semibold mb-4 text-center">Phno : +91 9632587410, 0452-25252525 E-mail: hmshospital@gmail.com </p>
  
                </div>
                <div className="flex gap-8">
                  <div className="w-1/2">
                    <div className="flex items-center justify-center text-lg font-semibold pb-8 pl-80">
                      INVOICE
                    </div>
  
                    <div className="grid grid-cols-3 gap-4 pl-8">
                      <div className="col-span-2">
                        <p><strong>Name :</strong> {selectedPatient.Title + '' + selectedPatient.first_Name + '' + selectedPatient.last_Name}</p>
                        <p><strong>UHID :</strong> {selectedPatient.uhid}</p>
                        <p><strong>Mobile Number :</strong> {selectedPatient.Mobile_number}</p>
                        <p><strong>Invoice Number :</strong> {selectedPatient.invoice_number}</p>
                      </div>
  
                      <div className="col-span-1 pl-52 grid grid-col">
                        <p><strong>Age :</strong> {selectedPatient.age}</p>
                        <p><strong>Gender :</strong> {selectedPatient.gender}</p>
                        <p><strong>Aadhar Number :</strong> {selectedPatient.aadhar_number}</p>
                      </div>
                    </div>
                  </div>
                </div>
  
                <table className="w-full mt-8 border-none">
                  <thead>
                    <tr>
                      <th colSpan="3" className="text-lg font-semibold pb-4 pl-80 text-left">Billing Details</th>
                    </tr>
                    <tr>
                      <th className="pl-8 text-center">S.No</th>
                      <th className="pl-8 text-center">Content</th>
                      <th className="pl-8 text-center">Fees</th>
                    </tr>
                  </thead>
  
                  <tbody>
                    <tr>
                      <td className="pl-8 text-center">1</td>
                      <td className="pl-8 text-center">Registration</td>
                      <td className="pl-8 text-center">{selectedPatient.registration_fees || '0'}</td>
                    </tr>
  
                    <tr >
                      <td colSpan="2" className="pl-8 font-semibold text-center">Total</td>
                      <td className="pl-8 text-center">{(selectedPatient.registration_fees || 0)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex justify-between mt-4 pl-12">
  
              <button
                onClick={handlePrintClick}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-12"
              >
                Print
              </button>
  
              <button
                onClick={handleRegPdf}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-12"
              >
                Download PDF
              </button>
  
            </div>
          </>
        ) : hasConsultationFees && hasRegistrationFeesPaid ? (
          <>
            <div className="flex justify-end mt-0">
              <FontAwesomeIcon
                icon={faTimes}
                onClick={onClose}
                className="text-red-500 cursor-pointer"
              />
            </div>
            <h2 className="text-xl mb-4">Consultation Billing</h2>
  
            <div className="border border-gray-300 p-6 rounded-lg">
              <div
                className="consultation-content p-4"
                style={{
                  //backgroundImage: `url(${billbg})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  // backgroundColor: 'rgba(255, 255, 255, 0.1)',
                }}
                ref={printRef}
              >
                <div className="text-center">
                  <h2 className="text-lg font-semibold mb-2 text-center ">HMS MULTI SPECIALITY HOSPITAL AND MANAGEMENT</h2>
                  <p className="text-lg font-semibold mb-2 text-center">585 Anna Nagar, Madurai - 625020 </p>
                  <p className="text-lg font-semibold mb-4 text-center">Phno : +91 9632587410, 0452-25252525 E-mail: hmshospital@gmail.com </p>
  
                </div>
                <div className="flex gap-8">
                  <div className="w-1/2">
                    <div className="text-lg font-semibold pb-8 pl-8">
                      Patient Details
                    </div>
                    <div className="pl-8">
                      <p><strong>Name :</strong> {selectedPatient.Title + '' + selectedPatient.first_Name + '' + selectedPatient.last_Name}</p>
                      <p><strong>Age :</strong> {selectedPatient.age}</p>
                      <p><strong>Gender :</strong> {genderText}</p>
                      <p><strong>Mobile :</strong> {selectedPatient.Mobile_number}</p>
                      <p><strong>Aadhar :</strong> {selectedPatient.aadhar_number}</p>
                    </div>
                  </div>
  
                  <div className="w-1/2 mt-12">
                    <div>
                      <p><strong>UHID :</strong> {selectedPatient.uhid}</p>
                      <p><strong>Consultation Doctor :</strong> {selectedPatient.consultation_doctor}</p>
                      <p><strong>Time :</strong> {selectedPatient.time}</p>
                      <p><strong>Date :</strong>
                        {(() => {
                          const utcDate = new Date(selectedPatient.date);
                          const istDate = utcDate.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
                          const dateOnly = istDate.split(',')[0];
                          const [day, month, year] = dateOnly.split('/');
                          const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
                          return formattedDate;
                        })()}
                      </p>
                    </div>
                  </div>
                </div>
  
                <table className="w-full mt-8 border-none">
                  <thead>
                    <tr >
                      <th colSpan="3" className="text-lg font-semibold pb-4 pl-80 text-left bg-blue-800 text-white">Billing Details</th>
                    </tr>
                    <tr>
                      <th className="pl-8 text-center bg-blue-800 text-white">S.No</th>
                      <th className="pl-8 text-center bg-blue-800 text-white">Content</th>
                      <th className="pl-8 text-center bg-blue-800 text-white">Fees</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="pl-8 text-center">1</td>
                      <td className="pl-8 text-center">Consultation Fees</td>
                      <td className="pl-8 text-center">{selectedPatient.consultation_fees || '0'}</td>
                    </tr>
                    <tr >
                      <td colSpan="2" className="pl-8 font-semibold text-center">Total</td>
                      <td className="pl-8 text-center">{(selectedPatient.consultation_fees || 0)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex justify-between mt-4 pl-12">
  
              <button
                onClick={handleConsultationPrintClick}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-12"
              >
                Print
              </button>
  
              <button
                onClick={handleConsulPdf}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-12"
              >
                Download PDF
              </button>
            </div>
          </>
        ) : hasConsultationFees && hasRegistrationFees ? (
          <>
            <div className="flex justify-end mt-0">
              <FontAwesomeIcon
                icon={faTimes}
                onClick={onClose}
                className="text-red-500 cursor-pointer"
              />
            </div>
            <h2 className="text-xl mb-4">Registration Billing</h2>
  
            <div className="border border-gray-300 p-6 rounded-lg">
              <div
                className="registration-content p-4"
                style={{
                  //backgroundImage: `url(${billbg})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  // backgroundColor: 'rgba(255, 255, 255, 0.1)',
                }}
                ref={componentRef}
              >
                <div className="text-center">
                  <h2 className="text-lg font-semibold mb-2 text-center">HMS MULTI SPECIALITY HOSPITAL AND MANAGEMENT</h2>
                  <p className="text-lg font-semibold mb-2 text-center">585 Anna Nagar, Madurai - 625020 </p>
                  <p className="text-lg font-semibold mb-4 text-center">Phno : +91 9632587410, 0452-25252525 E-mail: hmshospital@gmail.com </p>
  
                </div>
                <div className="flex gap-8">
                  <div className="w-1/2">
                    <div className="flex items-center justify-center text-lg font-semibold pb-8 pl-80">
                      INVOICE
                    </div>
  
                    <div className="grid grid-cols-3 gap-4 pl-8">
                      <div className="col-span-2">
                        <p><strong>Name :</strong> {selectedPatient.Title + '' + selectedPatient.first_Name + '' + selectedPatient.last_Name}</p>
                        <p><strong>UHID :</strong> {selectedPatient.uhid}</p>
                        <p><strong>Mobile Number :</strong> {selectedPatient.Mobile_number}</p>
                        <p><strong>Invoice Number :</strong> {selectedPatient.invoice_number}</p>
  
                      </div>
  
                      <div className="col-span-1 pl-52 grid grid-col">
                        <p><strong>Age :</strong> {selectedPatient.age}</p>
                        <p><strong>Gender :</strong> {selectedPatient.gender}</p>
                        <p><strong>Aadhar Number :</strong> {selectedPatient.aadhar_number}</p>
                      </div>
                    </div>
                  </div>
                </div>
  
                <table className="w-full mt-8 border-none">
                  <thead>
                    <tr>
                      <th colSpan="3" className="text-lg font-semibold pb-4 pl-80 text-left">Billing Details</th>
                    </tr>
                    <tr>
                      <th className="pl-8 text-center">S.No</th>
                      <th className="pl-8 text-center">Content</th>
                      <th className="pl-8 text-center">Fees</th>
                    </tr>
                  </thead>
  
                  <tbody>
                    <tr>
                      <td className="pl-8 text-center">1</td>
                      <td className="pl-8 text-center">Registration</td>
                      <td className="pl-8 text-center">{selectedPatient.registration_fees || '0'}</td>
                    </tr>
  
                    <tr >
                      <td colSpan="2" className="pl-8 font-semibold text-center">Total</td>
                      <td className="pl-8 text-center">{(selectedPatient.registration_fees || 0)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex justify-between mt-4 pl-12">
  
              <button
                onClick={handleConsultationPrintClick}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-12"
              >
                Print
              </button>
  
              <button
                onClick={handleRegPdf}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-12"
              >
                Download PDF
              </button>
            </div>
  
            <br />
  
            <div className="flex justify-end mt-0">
              <FontAwesomeIcon
                icon={faTimes}
                onClick={onClose}
                className="text-red-500 cursor-pointer"
              />
            </div>
            <h2 className="text-xl mb-4">Consultation Billing</h2>
  
            <div className="border border-gray-300 p-6 rounded-lg">
              <div
                className="consultation-content p-4"
                style={{
                  //backgroundImage: `url(${billbg})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  // backgroundColor: 'rgba(255, 255, 255, 0.1)',
                }}
                ref={printRef}
              >
                <div className="text-center">
                  <h2 className="text-lg font-semibold mb-2 text-center">HMS MULTI SPECIALITY HOSPITAL AND MANAGEMENT</h2>
                  <p className="text-lg font-semibold mb-2 text-center">585 Anna Nagar, Madurai - 625020 </p>
                  <p className="text-lg font-semibold mb-4 text-center">Phno : +91 9632587410, 0452-25252525 E-mail: hmshospital@gmail.com </p>
  
                </div>
                <div className="flex gap-8">
                  <div className="w-1/2">
                    <div className="text-lg font-semibold pb-8 pl-8">
                      Patient Details
                    </div>
                    <div className="pl-8">
                      <p><strong>Name :</strong> {selectedPatient.Title + '' + selectedPatient.first_Name + '' + selectedPatient.last_Name}</p>
                      <p><strong>Age :</strong> {selectedPatient.age}</p>
                      <p><strong>Gender :</strong> {genderText}</p>
                      <p><strong>Mobile :</strong> {selectedPatient.Mobile_number}</p>
                      <p><strong>Aadhar :</strong> {selectedPatient.aadhar_number}</p>
                    </div>
                  </div>
  
                  <div className="w-1/2 mt-12">
                    <div>
                      <p><strong>UHID :</strong> {selectedPatient.uhid}</p>
                      <p><strong>Consultation Doctor :</strong> {selectedPatient.consultation_doctor}</p>
                      <p><strong>Time : </strong> {selectedPatient.time}</p>
                      <p><strong>Date : </strong>
                        {(() => {
                          const utcDate = new Date(selectedPatient.date);
                          const istDate = utcDate.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
                          const dateOnly = istDate.split(',')[0];
                          const [day, month, year] = dateOnly.split('/');
                          const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
                          return formattedDate;
                        })()}
                      </p>
                    </div>
                  </div>
                </div>
  
                <table className="w-full mt-8 border-none">
                  <thead>
                    <tr>
                      <th colSpan="3" className="text-lg font-semibold pb-4 pl-80 text-left">Billing Details</th>
                    </tr>
                    <tr>
                      <th className="pl-8 text-center">S.No</th>
                      <th className="pl-8 text-center">Content</th>
                      <th className="pl-8 text-center">Fees</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="pl-8 text-center">1</td>
                      <td className="pl-8 text-center">Consultation Fees</td>
                      <td className="pl-8 text-center">{selectedPatient.consultation_fees || '0'}</td>
                    </tr>
                    <tr >
                      <td colSpan="2" className="pl-8 font-semibold text-center">Total</td>
                      <td className="pl-8 text-center">{(selectedPatient.consultation_fees || 0)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex justify-between mt-4 pl-12">
              <button
                onClick={handleConsultationPrintClick}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-12"
              >
                Print
              </button>
  
              <button
                onClick={handleConsulPdf}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-12"
              >
                Download PDF
              </button>
            </div>
            <br />
          </>
        ) : (
          <p></p>
        )}
  
      </>
    );
  }

export default PrintScreen;
